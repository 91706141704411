import React from 'react';
import styled from 'styled-components';
import our_factory from '../../assets/img/our-factory.png';
import { useRTL } from '../../LanguageContext';

const HeroBar = () => {
  const { isRTL } = useRTL();

  return (
    <SectionWrapper isRTL={isRTL}>
      <div className='title'>
      {isRTL?   'لكي نكون عظماء ، نحتاج إلى أفراد موهوبين يشاركوننا شغفنا ليكونوا الأفضل':  'To be great, we need talented individuals who share our passion to be the best'  
      }
      </div>
    </SectionWrapper>
  );
};

export default HeroBar;

const SectionWrapper = styled.section`
  direction: ${(prop) => (prop.isRTL ? 'rtl' : 'ltr')};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 72vh;
  background: url(${our_factory});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;

  @media (max-width: 1024px) {
    height: 40vh;
  }
  @media (max-width: 680px) {
    background: url(${our_factory});
    background-size: 270%;
    }



  > div.title {
    display: flex;
    text-align: ${(prop) => (prop.isRTL ? 'right' : 'left')};
    font-family: 'Cairo-Bold';
    color: white;
    width: 90%;
    font-size: 5rem;


    @media (max-width: 1800px) {
      font-size: 4rem;
    }

    @media (max-width: 1600px) {
      font-size: 3.5rem;
    }

    @media (max-width: 1500px) {
      font-size: 3rem;
    }

    @media (max-width: 1300px) {
      font-size: 2.8rem;
      margin: ${(prop) => (prop.isRTL ? '0rem 1rem 0 0rem ' : '0 0 0 1rem')};
    }

    @media (max-width: 680px) {
      font-size: 1.8rem;
      width: 90%;

      /* border: 1px solid white; */
    }
  }
`;
