import doc_placeholder from '../../assets/img/placeholder-doc.png';
import research from '../../assets/svg/research.svg';
import product_logo from '../../assets/svg/products-logo.svg';

export const WHAT_IS_ACP = {
  en: {
    title: 'What is Aluminium Composite Panel?',
    text:` Aluminum composite panel is a composite panel consisting of two
    aluminum cover sheets and a plastic core. The superb properties of
    this material boost one's inspiration and offer architecture a whole
    new range of solutions whether your project is a residential or
    public building, a corporat headquarter, an office building, a
    trading or industrial complex-or if your organisation wants to
    create new image-building Corporate Design-whether for petrol
    stations, car showrooms, banks or supermarkets.`
  },
  ar:{
    title: 'ماهي ألواح الألمنيوم المركبة؟',
    text:`لوح الألمنيوم المركب عبارة عن لوح مركب يتكون من لوحين من الألمنيوم ونواة بلاستيكية. الخصائص الرائعة لهذه المواد تعزز إلهام الفرد وتقدم للهندسة المعمارية مجموعة جديدة كاملة من الحلول سواء كان مشروعك مبنى سكني أو عام، مقر شركة، مبنى مكاتب، مجمع تجاري أو صناعي - أو إذا كانت مؤسستك تريد إعطاء انطباع لصورة تصميمية جديدة لمبنى الشركة سواء لمحطات البنزين أو معارض السيارات أو البنوك أو محلات السوبر ماركت
    `

  }
}

export const PRODUCT_DATA = {
  en:{
  img: product_logo,
  title: 'Products',
  card: [
    {
      title: 'AL DANA BOND B1 SERIES',
      text: `When we speak about Fire rated Aluminium composite panels (ACP's) we can really narrow it down to four core materials. B1 fire resistance panels, A2 non-combustible panel, Alucore or honey comb core panel and pure aluminium sheet.`,
      doc: doc_placeholder,
     // link: 'Know more',
    },
    {
      title: 'AL DANA BOND A2 SERIES',
      text: `A2 Aluminium core panels refers to the class of fire rating it will achieve, non-combustible. this mean in the event of a fire the panels will not ignite or provide the flame with fuel to spread the fire. A2 panels will not release toxic fumes in the event of a fire as smoke inhalation is the largest killing factor in fire hazards`,
      doc: doc_placeholder,
      //link: 'Know more',
    },
  ],
},
ar:{
  img: product_logo,
  title: 'المنتجات',
  card: [
    {
      title: 'سلسلة الدانة بوند B1',
      text: `عندما نتحدث عن ألواح الألومنيوم المركبة المقاومة للحريق (ACP)، يمكننا حقاً حصرها في أربع مواد أساسية. الألواح المقاومة للحريق B1، لوح غير قابل للاحتراق A2، لوح الألوكور أو لوح نواة قرص العسل، وصفيحة الألمنيوم النقي.
      `,
      doc: doc_placeholder,
     // link: 'Know more',
    },
    {
      title: 'سلسلة الدانة بوند A2',
      text: `يحتوي على 88% - 90% من مثبطات اللهب، والمكونات الرئيسية هي هيدروكسيد الألومنيوم (Al (OH) 3) وهيدروكسيد المغنيسيوم (Mg (OH) 2)
      تشير الألواح الأساسية المصنوعة من الألومنيوم A2 إلى فئة مقاومة الحريق الذي ستحققه، وهي غير قابلة للاحتراق. وهذا يعني في حالة نشوب حريق أن الألواح لن تشتعل أو تزود اللهب بالوقود لنشر النار. لن تطلق ألواح A2 أبخرة سامة في حالة نشوب حريق حيث أن استنشاق الدخان هو أكبر عامل قتل في مخاطر الحريق.
      `,
      doc: doc_placeholder,
      //link: 'Know more',
    },
  ],

}
};

export const BOND_COATINGS = {
  en:{
    title: 'Aldana bond coatings',
    img: research,
    block1: [
      {
        title: 'PE(Polyester)Coating',
        text:
          'PE coating, with high molecular polymer as monomer and addition of al kydresin, has an excellent ­performance on colors. It can be classified to matt and glossy according to gloss levels. Due to its compactmolecule structure, the paint surface is luster and smooth, makes the surface suitable for digital printing. This coating is a perfect choice for interior decoration and signs industry. Warranty could be up to 10 years for interior decoration.',
      },
      {
        title: 'PVDF(Fluorine-carbon) Coating',
        text:
          'PVDF coating, made of fluorine carbon resin, is solidified to dry film with super weather resistance. We have normal PVDF and NANO PVDF coatings. NormaI PVDF coating, certified as KYNIAR 500, is made of 2-3 time coating and baking, has good properties of anti-acid, Anti-alkali, is durable in atrocious weather and environment. Warranty could reach to 15 years for exterior use. NANO PVDF coating, with self-cleaning nanometer paint on the normal PVDF coating, is used to protect the surface from pollution, dust or dirty rains. Warranty could be up to 15 years for exterior use.',
      },
      {
        title: 'FEVE Coating',
        text:
          'FEVE coating, with polyurethane as base and fluoride polymer as top coating, has good feature of weather resistance of 10 years for exteriors use. Different from PVDF coating, it guarantees fresher, vivid color andhigher gloss level, provide more choice for designer. It is available in colors that are not feasible with other coating system. Spectra colors coating, add pearltalc into PVDF paint(>70%PVDF), depending upon the pigment type and viewing angle, different wavelengths of light are reflected, refracted and diffracted to the audience resulting in an ever-changing color gradient with iridescent highlights. It is widely used for public internal and external, multiple shops, exhibitions etc.',
      },
    ],
    block2: {
      title: 'Special Surface',
      items: [
        {
          boldText: 'Brushed Surface:',
          text:
            'which is made by some special process on natural aluminum, presents the nature and beautiful appearance together with moder.',
        },
        {
          boldText: 'Granite and Wood surface:',
          text:
            'which can be processed either by film-sticking or painting. The former means stick one layer on film with granite or wood pattern, the latter is painted and roaster for 3 or 4 times with a better Weather resistance.',
        },
        {
          boldText: 'Mirror finish:',
          text:
            'which needs anodic oxidation finishing on aluminum surface, the finishing makes the surface like mirror, it is more safely using and process to all shapes.',
        },
        {
          boldText: 'Dual or tripe color surface:',
          text:
            'which has two or three colors in the same surface. We renew the coating system and technology to break the national single color into two or three different colors in the same surface, this offers more imagine and creativity to the designers.',
        },
      ],
    },

  },
  ar:{

    title: 'سلسلة طلاء الدانا لاختيارك',
  img: research,
  block1: [
    {
      title: 'طلاء PE  (بوليستر)',
      text:
        ' مع البوليمر الجزيئي العالي كمونومر وإضافة الكيدريسين، له أداء ممتاز على الألوان. يمكن تصنيفها إلى غير لامع ولامع وفقاً لمستويات اللمعان. نظراً لتركيبه الجزيئي المضغوط، فإن سطح الطلاء يكون لامعاً وسلساً، مما يجعل السطح مناسباً للطباعة الرقمية. هذا الطلاء هو الخيار الأمثل لصناعة الديكور الداخلي واللافتات. يمكن أن يصل الضمان إلى 10 سنوات للديكور الداخلي PE،طلاء',
    },
    {
      title: ' طلاء PVDF (فلور - كربون)',
      text:
    'طلاء PVDF، المصنوع من راتينج الكربون الفلور، يتم تصليبه لتجفيف الفيلم لمقاومة فائقة لعوامل لطقس. لدينا طلاءات PVDF العادية و PVDF النانوية. طلاء PVDF العادي، حاصل على شهادة KYNIAR 500، مطلي و مشوي 2-3 مرات، وله خصائص جيدة لمقاومة الأحماض و القلويات، ويتحمل الطقس والبيئة القاسية. يمكن أن يصل الضمان إلى 15 عامًا للاستخدام الخارجي. يستخدم طلاء  PVDFالنانوي، مع طلاء نانومتر ذاتي التنظيف فوق طلاء PVDF العادي لحماية السطح من التلوث والغبار أو الأمطار االملوثة. يمكن أن يصل الضمان إلى 15 عاماً للاستخدام الخارجي.'},
    {
      title: 'طلاء FEVE',
      text:
       'يتميز طلاء FEVE، مع البولي يوريثين كقاعدة وبوليمر الفلورايد كطلاء علوي، بميزة جيدة لمقاومة الطقس لمدة 10 سنوات للاستخدام الخارجي. يختلف عن طلاء PVDF، فهو يضمن لوناً أكثر نضارة وحيوية ومستوى لمعان أعلى، ويوفر المزيد من الخيارات للمصمم. وهو متوفر بألوان غير متوفرة في أنظمة الطلاء الأخرى.'
    },
  ],
  block2: {
    title: ' سطح خاص',
    items: [
      {
        boldText: 'السطح المصقول:',
        text:
         'الذي يتم تصنيعه من خلال بعض العمليات الخاصة على الألومنيوم الطبيعي، يعرض الطبيعة والمظهر الجميل جنبًا إلى جنب مع الحداثة. سطح الجرانيت والخشب: يمكن معالجته إما عن طريق لصق الفيلم أو بالطلاء. الأول يعني لصق طبقة واحدة بنمط الجرانيت أو الخشب على الفيلم، والأخير مطلية ومشوية لمدة 3 أو 4 مرات مع مقاومة أفضل لعوامل الطقس.',
      },
      {
        boldText: 'سطح الجرانيت والخشب:',
        text:
         'والتي يمكن معالجتها إما عن طريق لصق الفيلم أو الرسم. الأول يعني لصق طبقة واحدة على الفيلم بنمط الجرانيت أو الخشب ، والأخير مطلي ومحمص لمدة 3 أو 4 مرات مع مقاومة أفضل للطقس.',
      },
      {
        boldText: 'تشطيب المرآة:',
        text:
          ' الذي يحتاج إلى تشطيب أكسدة أنوديكية على سطح الألمنيوم، ممل يجعل تشطيب السطح كالمرآة، وهو أكثر أمانًا في الاستخدام والمعالجة لجميع الأشكال.السطح ثنائي و ثلاثي اللون: له لونان أو ثلاثة ألوان في نفس السطح. نقوم بتجديد نظام وتقنية الطلاء لتقسيم اللون الوطني الأحادي إلى لونين أو ثلاثة ألوان مختلفة في نفس السطح، وهذا يوفر المزيد من الخيال والإبداع للمصممين.',
      },
      {
        boldText: 'السطح ثنائي و ثلاثي اللون:',
        text:
         'له لونان أو ثلاثة ألوان في نفس السطح. نقوم بتجديد نظام وتقنية الطلاء لتقسيم اللون الوطني الأحادي إلى لونين أو ثلاثة ألوان مختلفة في نفس السطح، وهذا يوفر المزيد من الخيال والإبداع للمصممين.',
      },
    ],
  },

  },
  
};
