import React from 'react';
import _ from 'underscore';
import styled from 'styled-components';
import LetsGo from '../../assets/svg/lets-go.svg';
import NormalButton from '../shared/buttons/NormalButton';
import { aboutUsFeatures, buttonText } from './data';
import { useRTL } from '../../LanguageContext';
import CustomList from '../shared/CustomList';

const AboutFeatures = () => {
  const { isRTL, language } = useRTL();

  const features = _.find(aboutUsFeatures, (value, key) => key === language);

  return (
    <SectionContainer isRTL={isRTL}>
      <div>
        <RightContainer>
          <InnerBlocks
            isRTL={isRTL}
            style={{ background: '#1E1E30', color: 'white' }}
          >
            <div className='title'>{features.block1.title}</div>{' '}
            <div className='descr'>{features.block1.descr}</div>
          </InnerBlocks>

          <InnerBlocks style={{ justifyContent: 'center' }}>
            <img src={features.block2.img} alt='process-techniques' />
          </InnerBlocks>

          <InnerBlocks
            start='start'
            isrtl={isRTL}
            style={{ background: '#F6F6F6' }}
          >
            <div className='flex'>
              <img src={features.block3.img} alt='bubble' />
              <div className='title'>{features.block3.title}</div>
            </div>

            {features.block3.items.map((value, key) => (
              <CustomList key={key} text={value.item} isRTL={isRTL} />
            ))}

            {/* {features.block3.items.map((value, key) => (
              <div className='list' key={key}>
                {value.item}
              </div>
            ))} */}
          </InnerBlocks>
          <InnerBlocks start='start' isrtl={isRTL}>
            <div className='flex'>
              <img src={features.block4.img} alt='graph' />
              <div className='title'>{features.block4.title}</div>
            </div>
            <img src={features.block4.image} alt='process-techniques' />
          </InnerBlocks>
        </RightContainer>
      </div>
    </SectionContainer>
  );
};

export default AboutFeatures;

const SectionContainer = styled.section`
  direction: ${(prop) => (prop.isRTL ? 'rtl' : 'ltr')};
`;

const RightContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 1080px) {
    flex-flow: column wrap;
  }
`;

const InnerBlocks = styled.div`
  cursor: pointer;
  width: 50%;
  display: flex;
  //align-items: ${(prop) => (prop.start === 'start' ? '' : 'center')};
  flex-flow: column;
  border: none;
  background-color: white;
  outline: 1px solid #dcdcdc;

  @media (max-width: 1024px) {
    width: 100%;
  }

  > button {
    margin: 0 0 1rem;
  }

  > button > span {
    margin: ${(prop) => (prop.isRTL ? '0 5px 0 0' : '0 0 0 5px')};
  }

  > button > span > img {
    transform: ${(prop) => (prop.isRTL ? 'scaleX(-1)' : '')};
    vertical-align: middle;
  }

  > div.title {
    font-family: 'Cairo-Bold';
    font-size: 3rem;
    padding: 3rem 6rem 0rem;

    @media (max-width: 1800px) {
      font-size: 2.8rem;
    }

    @media (max-width: 1600px) {
      font-size: 2.5rem;
    }

    @media (max-width: 1400px) {
      font-size: 2.2rem;
      padding: 3rem 2rem 0rem;
    }

    @media (max-width: 1300px) {
      font-size: 1.9rem;
    }

    @media (max-width: 580px) {
      font-size: 1.4rem;
    }
  }

  > div.list {
    font-family: 'Cairo-SemiBold';
    padding: 15px 2rem 0.5rem;
    font-size: 1.25rem;
    margin: ${(prop) => (prop.isrtl ? '0 3rem 0 0 ' : '0 0 0 3rem')};

    @media (max-width: 1800px) {
      font-size: 1.1rem;
    }

    @media (max-width: 1600px) {
      font-size: 1rem;
    }

    @media (max-width: 1400px) {
      font-size: 1rem;
    }

    @media (max-width: 1300px) {
      font-size: 1rem;
    }

    @media (max-width: 580px) {
      font-family: 'Cairo-Bold';
      font-size: 1rem;
      padding: 1rem 0rem 0.5rem;
      margin-right: 1.5rem;
      //width: 99%;
      margin: ${(prop) => (prop.isrtl ? '0 1.8rem 0 0 ' : '0 0 0 1.8rem')};
    }

    @media (max-width: 380px) {
      font-size: 1rem;
    }

    &:before {
      content: '';
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: var(--dana-color);
      display: inline-block;
      margin: 0 1rem 0 1rem;
    }
  }
  > div.bullet {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--dana-color);
    display: inline-block;
    margin: 0 1rem 0 1rem;
  }

  > div.descr {
    font-family: 'Cairo-Regular';
    font-size: 1.25rem;
    padding: 2rem 6rem;
    line-height: 2;
    max-width: 80%;
    text-align: justify;

    @media (max-width: 1800px) {
      font-size: 1.1rem;
    }

    @media (max-width: 1600px) {
      font-size: 1rem;
    }

    @media (max-width: 1400px) {
      font-size: 0.9rem;
      padding: 2rem 2rem;
    }

    @media (max-width: 1300px) {
      font-size: 0.9rem;
    }

    @media (max-width: 580px) {
      font-size: 0.9rem;
    }
  }

  > div.flex {
    display: flex;
    justify-content: flex-start;
    padding: 3rem 4rem 0rem;

    @media (max-width: 580px) {
      padding: 3rem 2rem 0rem;
    }

    > div.title {
      font-family: 'Cairo-Bold';
      font-size: 3rem;
      padding: ${(prop) => (prop.isrtl ? '0 1rem 0 0 ' : '0 0 0 1rem')};
      white-space: nowrap;

      @media (max-width: 1800px) {
        font-size: 2.8rem;
      }

      @media (max-width: 1600px) {
        font-size: 2.5rem;
      }

      @media (max-width: 1400px) {
        font-size: 2.2rem;
      }

      @media (max-width: 1300px) {
        font-size: 1.9rem;
      }

      @media (max-width: 580px) {
        font-size: 1.4rem;
        white-space: normal;
      }
    }

    > img {
      width: 8%;

      @media (max-width: 580px) {
        width: 15%;
      }
    }
  }

  > img {
    width: 65%;
    height: 50%;
    padding: 2rem;

    @media (max-width: 580px) {
      width: 80%;
      height: 100%;
    }
  }
`;
