import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import NormalButton from '../shared/buttons/NormalButton';
import { Approvals_data, Certifications_data,why_us } from './data';
import LetsGo from '../../assets/svg/lets-go.svg';
import aldana_unique_bg from '../../assets/img/aldana-unique-bg.png';
//import Dot from '../shared/Dot';
import _ from 'underscore';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import { useRTL } from '../../LanguageContext';
//import DownloadFile from '../shared/DownloadFile';

const Content = () => {
  const { isRTL,language } = useRTL();
  const [model, setModel] = useState('card1');
  const Certifications = _.find(Certifications_data, (value, key) => key === language);
  const modelData = _.find(Certifications, (value) => value.no === model);
  const Approvals = _.find(Approvals_data, (value, key) => key === language);
  const approvalData = _.find(Approvals, (value) => value.no === model);
  const whyUsData = _.find(why_us, (value, key) => key === language);

  const [change, setChange] = useState(false);

  const [toggle, setToggle] = useState(false);

  return (
    <ContentWrapper toggle={toggle} isRTL={isRTL}>
      <div className='intro'>
        <div className='title'>{whyUsData.title}</div>
        <div className='text'>
          <p>
           {whyUsData.text}
          </p>
        </div>
      </div>

      <div className='certifications'>
        <div className='title'>{isRTL? 'الشهادات':'Certifications'}</div>
        <div className='cards'>
          {Certifications.map((item, key) => (
            <div className='card' key={key}>
              <img src={item.img} alt={item.title} />
              <div className='name'>{item.title}</div>
              <NormalButton
                onClick={() => {
                  setChange(false);
                  setModel(item.no);
                  setToggle(true);
                }}
              >
                {isRTL? 'رؤية الشهادات':'View Certification'}
                <span>
                  <img src={LetsGo} alt='lets go' width='20' height='20' />
                </span>
              </NormalButton>
            </div>
          ))}

          <div
            className={toggle ? 'model' : 'model no-model'}
            onClick={() => setToggle(false)}
          >
            <div className='model-body'>
              <div className='title'>
                {change ? approvalData.title : modelData.title}
              </div>
              {/* <div className='download-section'>
                <Dot />
                <p
                  className={change ? approvalData.name : modelData.name}
                  onClick={(e) => DownloadFile(e.target.className)}
                >
                  Download the {change ? approvalData.title : modelData.title}
                </p>
              </div> */}
              <img
                src={change ? approvalData.doc : modelData.doc}
                alt={change ? approvalData.title : modelData.title}
              />
              <CancelIcon fontSize='large' onClick={() => setToggle(false)} />
            </div>
          </div>
        </div>
      </div>

      <div className='approvals'>
        <div className='title'>{isRTL? ' التصاريح':'Approvals'}</div>
        <div className='cards'>
          {Approvals.map((item, key) => (
            <div className='card' key={key}>
              <img src={item.img} alt={item.title} />
              <div className='name'>{item.title}</div>
              <div
                className='document'
                onClick={() => {
                  setChange(true);
                  setModel(item.no);
                  setToggle(true);
                  console.log(model);
                  console.log(toggle);
                }}
              >
               {isRTL? 'رؤية التصريح':'View Certification'}
              </div>
            </div>
          ))}
        </div>
      </div>
    </ContentWrapper>
  );
};

export default Content;

const animateOpen = keyframes`
from { opacity:0}
  to { opacity:1}
`;

const animateClose = keyframes`
from { opacity:1}
  to { opacity:0}
`;

const ContentWrapper = styled.div`
  direction: ${(prop) => (prop.isRTL ? 'rtl' : 'ltr')};
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 0 5rem;
  background: url(${aldana_unique_bg});

  > div {
    width: 90%;
    padding: 0 0 3rem;
  }

  > div > div.title {
    font-family: 'Cairo-Bold';
    font-size: 3rem;
    padding: 0.5rem 0 1rem;

    @media (max-width: 1800px) {
      font-size: 2.8rem;
    }

    @media (max-width: 1600px) {
      font-size: 2.6rem;
    }

    @media (max-width: 1400px) {
      font-size: 2.4rem;
    }

    @media (max-width: 1300px) {
      font-size: 2.2rem;
      margin: ${(prop) => (prop.isRTL ? '0rem 0.8rem 0 0rem ' : '0 0 0 0.8rem')};
    }

    @media (max-width: 680px) {
      font-size: 1.8rem;
    }
  }

  > div > div.text {
    font-family: 'Cairo-Medium';
    font-size: 1.25rem;
    text-align: justify;
    line-height: 2;

    @media (max-width: 1800px) {
      font-size: 1.15rem;
    }

    @media (max-width: 1600px) {
      font-size: 1rem;
    }

    @media (max-width: 1400px) {
      font-size: 0.9rem;
    }

    @media (max-width: 1300px) {
      font-size: 0.9rem;
      margin: ${(prop) => (prop.isRTL ? '0rem 0.8rem 0 0rem ' : '0 0 0 0.8rem')};
    }

    > span {
      display: flex;
      justify-content: center;
      align-items: center;

      > img {
        width: 100%;
      }
    }
  }

  > div.certifications > div.cards {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 0 2rem;
    transition: all 0.5s;

    @media (max-width: 1024px) {
      flex-flow: column;
      justify-content: center;
    }

    > div.no-model {
      animation: ${animateClose} 0.5s ease-in-out;
    }

    > div.model {
      display: ${(prop) => (prop.toggle ? 'block' : 'none')};
      position: fixed;
      z-index: 1;
      /* padding: rem; */
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      background-color: rgb(0, 0, 0);
      background-color: rgba(0, 0, 0, 0.4);
      

      > div.model-body {
        height: 80%;
        display: flex;
        flex-flow: column;
        align-items: center;
        background: white;
        border-radius: 2rem;
        margin: 3rem;
        overflow-y: scroll;
        animation: ${animateOpen} 0.5s ease-in-out;

        &::-webkit-scrollbar {
          display: none;
        }

        @media (max-width: 1024px) {
          height: 50%;
        }

        @media (max-width: 680px) {
          height: 80%;
          margin: 1rem;
        }

        > img {
          width: 40%;
          padding: 2rem;

          @media (max-width: 680px) {
            width: 80%;
          }
        }

        > div.title {
          font-family: 'Cairo-Bold';
          color: #1d2d5b;
          font-size: 3rem;
          padding: 1rem 0 1rem;

          @media (max-width: 1800px) {
            font-size: 2.7rem;
          }

          @media (max-width: 1600px) {
            font-size: 2.5rem;
          }

          @media (max-width: 1400px) {
            font-size: 2.3rem;
          }

          @media (max-width: 1300px) {
            font-size: 2.1rem;
          }

          @media (max-width: 680px) {
            font-size: 1.5rem;
          }
        }

        > div.download-section {
          display: flex;
          align-items: center;
          font-size: 1.25rem;
          color: #1e1e30;
          font-family: 'Cairo-SemiBold';

          @media (max-width: 1800px) {
            font-size: 1.1rem;
          }

          @media (max-width: 1600px) {
            font-size: 1rem;
          }

          @media (max-width: 1400px) {
            font-size: 0.9rem;
          }

          @media (max-width: 1300px) {
            font-size: 0.9rem;
          }

          @media (max-width: 680px) {
            max-width: 100%;
            /* border: 1px solid black; */
          }

          &:hover {
            opacity: 0.6;
            cursor: pointer;
          }

          > div {
            margin: 0 1rem 0;
            background-color: #f5f5f5;
            width: 2.5rem;
            height: 2.5rem;

            @media (max-width: 680px) {
              margin: 0 5px 0 0;
              width: 1.8rem;
              height: 1.8rem;
            }
          }
        }
      }
    }

    > div.card {
      display: flex;
      flex-flow: column;
      text-align: center;
      align-items: center;
      background: white;
      padding: 2rem 1.5rem 0;
      border: 1px solid #e9e8e8;
      border-radius: 70px;
      /* box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
        0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
        0 0px 17.9px rgba(0, 0, 0, 0.072), 0 0.8px 0px rgba(0, 0, 0, 0.086),
        0 0px 0px rgba(0, 0, 0, 0.12); */
      width: 50vh;

      @media (max-width: 1024px) {
        width: 40vh;
        margin: 1rem 0 1rem;
      }

      @media (max-width: 680px) {
        width: 40vh;
      }

      > img {
        width: 50%;

        @media (max-width: 1024px) {
          width: 30%;
        }
      }

      > div.name {
        color: #1d2d5b;
        font-family: 'Cairo-Bold';
        font-size: 2rem;
        padding: 1rem 0 2rem;

        @media (max-width: 1800px) {
          font-size: 1.8rem;
        }

        @media (max-width: 1600px) {
          font-size: 1.6rem;
        }

        @media (max-width: 1500px) {
          font-size: 1.4rem;
        }

        @media (max-width: 1300px) {
          font-size: 1.2rem;
        }
      }

      > button {
        margin: 0 0 2rem;
        font-size: 1.125rem;

        @media (max-width: 1800px) {
          font-size: 1rem;
        }

        @media (max-width: 1600px) {
          font-size: 0.9rem;
        }

        @media (max-width: 1500px) {
          font-size: 0.9rem;
        }

        @media (max-width: 1300px) {
          font-size: 0.9rem;
        }

        > span > img {
          padding: 0 5px 0;
        }
      }
    }
  }

  > div.approvals > div.cards {
    padding: 1rem 0 0;

    > div.card {
      background: white;
      display: flex;
      align-items: center;
      padding: 1rem 0 1rem;
      font-family: 'Cairo-Bold';
      margin: 0 0 1rem;
      border: 1px solid #e9e8e8;
      border-radius: 70px;
      /* box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
        0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
        0 0px 17.9px rgba(0, 0, 0, 0.072), 0 0.8px 0px rgba(0, 0, 0, 0.086),
        0 0px 0px rgba(0, 0, 0, 0.12); */

      > img {
        width: 10%;
        margin: 2rem;

        @media (max-width: 680px) {
          width: 20%;
          margin: 0 10px 0;
        }
      }

      > div.name {
        font-size: 2rem;
        color: #1d2d5b;
        padding: 0 2rem 0;

        @media (max-width: 1800px) {
          font-size: 1.8rem;
        }

        @media (max-width: 1600px) {
          font-size: 1.6rem;
        }

        @media (max-width: 1500px) {
          font-size: 1.4rem;
        }

        @media (max-width: 1300px) {
          font-size: 1.2rem;
        }

        @media (max-width: 1300px) {
          font-size: 0.9rem;
          max-width: 40%;
          padding: 0 5px 0;
        }
      }

      > div.document {
        position: absolute;
        left: ${(prop) => (prop.isRTL ? '10%' : '')};
        right: ${(prop) => (prop.isRTL ? '' : '10%')};
        border: none;
        border-bottom: 1px solid #9e0a34;
        color: #9e0a34;
        font-size: 1.5rem;

        @media (max-width: 1800px) {
          font-size: 1.4rem;
        }

        @media (max-width: 1600px) {
          font-size: 1.3rem;
        }

        @media (max-width: 1400px) {
          font-size: 1.2rem;
        }

        @media (max-width: 1300px) {
          font-size: 1.1rem;
        }

        @media (max-width: 1300px) {
          font-size: 0.6rem;
          max-width: 40%;
          margin: 0 5px 0;
        }

        &:hover {
          opacity: 0.7;
          cursor: pointer;
        }
      }
    }
  }
`;

const CancelIcon = styled(CancelRoundedIcon)`
  && {
    color: #9e0a34;
    position: absolute;
    top: 10%;
    right: 5%;
    transition: all 0.5s;

    @media (max-width: 1024px) {
      display: none;
    }
  }

  &:hover {
    cursor: pointer;
    transform: scale(1.3);
    transition: all 0.5s;
  }
`;
