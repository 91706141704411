export const WhoAreWe = {
    en: {
        title:"Who are we?",
        block1:
        {
            descr: `AL DANA BOND welcomes you to the world of Aluminium Composite Panels (ACP) where phenomenal quality meets incomparable durability. We take utmost pride in introducing ourselves as the leading manufacturers of ACP sheets in State of Qatar. 
            Since inception, AL DANA BOND has grown by leaps and bounds and is the front runner of the market. Products are feast for your eyes and will dissipate you in your wishful dream décor spaces. 
            The Motto of AL DANA BOND is to attain perfection, therefore with a view to achieve the desired goal AL DANA BOND have set up a world class innovative manufacturing unit, with state-of-the art fully computerized and digitized with advanced level of super wide, continuous and high-speed production lines.
            There is no gainsaying the fact that our Vision is to deliver the AL DANA BOND ACP which is the symbol of excellence in terms of utility and appearance as to maintain cordial relations with clients and staff, thus adhering to work ethics.
            Our Mission is to provide up-to-date and innovative product range and earn trust of our customers with our services, timely delivery of high-quality products blended with high aesthetic appeal. Our ultimate aim is to earn a long and strong trust to build brand image and maintain our fame. We are known for our integrity. ALDANA BOND team are reputed to use clean and eco-friendly technology free from any detrimental effect to air, water and environment.`
        },
        block2:{
            descr: `The Motto of AL DANA BOND is to attain perfection, therefore with a view to achieve the desired goal AL DANA BOND have set up a world class innovative manufacturing unit, with state-of-the art fully computerized and digitized with advanced level of super wide, continuous and high-speed production lines.
            There is no gainsaying the fact that our Vision is to deliver the AL DANA BOND ACP which is the symbol of excellence in terms of utility and appearance as to maintain cordial relations with clients and staff, thus adhering to work ethics.
            Our Mission is to provide up-to-date and innovative product range and earn trust of our customers with our services, timely delivery of high-quality products blended with high aesthetic appeal. Our ultimate aim is to earn a long and strong trust to build brand image and maintain our fame. We are known for our integrity. ALDANA BOND team are reputed to use clean and eco-friendly technology free from any detrimental effect to air, water and environment.`

        },
        block3:{
            descr: `AL DANA BOND fabricates exclusive products under close monitoring by experienced and qualified specialists and engineers with the latest infrastructure and paraphernalia being our determinative factor. AL DANA BOND ACP sheets are one of the most popular sustainable materials that are used widely throughout the world. They are sandwich panels comprising of two aluminium sheets bonded to an organic mineral filled inner core material which could be of high density or low density. The light weight, aesthetics, good acoustic properties, fire rated properties easy installation and very low wastage make it the material of today.
            AL DANA BOND ACP sheets are highly resistant to acids, alkalis, pollution and has very good sound and heat insulation properties. It also helps in energy conservation by the building as per the ECBC code hence contributing towards environment. 
            Give your space that vivacity, express it with ALDANA BOND.`

        },
        block4:{
            descr: ` ALDANA is a sovereign, entrepreneurial and persistent manufacturing specialized in the ACP Sheets both FIRE RATED&NON-FIRE RATED.
            We are mainly focusing on accomplishing our Clients goals. We believe that our tactics should be mainly based on a healthy blend of research & creativity.
            We are equipped with a gang of Intelligent, forward thinking, fun loving team, which will be looking forward to accomplish the customer needs. Our customers success certifies our success. Our motto is mainly based on the hard work, commitment, maintaining quality & mainly sharing the success and exceeding the potentials of everyone 
    ` }
        
    },
    ar: {    
        title:"من نحن؟",
        block1:
        {
            descr: `ترحب الدانة بوند بكم في عالم ألواح الألمنيوم المركبة (ACP) حيث تلتقي الجودة الهائلة بمتانة لا تضاهى. نحن نفخر بتقديم أنفسنا كشركة رائدة في صناعة لألواح ألواح الألمنيوم المركبة (ACP)  في دولة قطر.
            منذ إنشائها، نمت شركة الدانة بوند على قدم وساق وهي المتصدرة الأولى للسوق. المنتجات هي بهجة لناظريك في رحاب مساحات الجمال التي تحلم بها.
            يتمثل شعار الدانة بوند في الوصول إلى الكمال، لذلك بهدف تحقيق الهدف المنشود، أنشأت الدانة بوند وحدة تصنيع مبتكرة على مستوى عالمي، بأحدث التقنيات المحوسبة بالكامل ورقمنة بمستوى متقدم و فائق السعة لخطوط إنتاج مستمرة وعالية السرعة.
            ` },
        block2:{
            descr: `لا تخفى حقيقة أن رؤيتنا تتمثل في تقديم ألمنيوم الدانة للألواح المركبة (ACP) الذي يعد رمزًا للتميز من حيث الفائدة والمظهر، والحفاظ على علاقات ودية بين العملاء والموظفين، وبالتالي الالتزام بأخلاقيات العمل.
            مهمتنا هي توفير مجموعة منتجات حديثة ومبتكرة وكسب ثقة عملائنا من خلال خدماتنا، والتسليم في الوقت المناسب لمنتجات عالية الجودة ممزوجة بجاذبية جمالية عالية. هدفنا النهائي هو كسب ثقة قوية و طويلة المدى لبناء صورة العلامة التجارية والحفاظ على شهرتنا. نحن معروفون بنزاهتنا. حيث يشتهر فريق الدانة بوند باستخدام تقنية نظيفة وصديقة للبيئة خالية من أي تأثير ضار على الهواء والماء والبيئة.
            `
        },
        block3:{
            descr: `تقوم الدانة بوند بتصنيع منتجات حصرية تحت المراقبة الدقيقة من قبل متخصصين ومهندسين من ذوي الخبرة و التأهيل مع البنية التحتية والأدوات الحديثة لتكون العامل الحاسم لدينا. تعتبر ألواح الدانة بوند ACP واحدة من أكثر المواد المستدامة و شيوعًا والتي يتم استخدامها على نطاق واسع في جميع أنحاء العالم. وهي عبارة عن ألواح ساندوتش (شطيرة) تتكون من صفيحتين من الألمنيوم ملتصقتين بمادة رابطة عضوية معدنية، و تكون المادة المالئة للنواة إما عالية أو منخفضة الكثافة. الوزن الخفيف والجمال والخصائص الصوتية الجيدة والخصائص المقاومة للحريق و سهولة التركيب والهدر المنخفض للغاية تجعلها مادة اليوم.
            تتميز ألواح الدانة بوند ACP بمقاومة عالية للأحماض والقلويات والتلوث ولها خصائص عزل صوتي وحراري جيدة جدًا. كما أنها تساعد في حفظ الطاقة في المبنى وفقًا للكود ECBC وبالتالي المساهمة في البيئة.
            امنح مساحتك الحيوية، عبّر عنها مع الدانة بوند.
            `
        },
        block4:{
            descr: `الدانة هي شركة صناعية ذات سيادة وريادة ومستمرة ومتخصصة في ألواح ACP المقاومة للحريق و غير المقاومة للحريق على حد سواء.
            نحن نركز بشكل أساسي على تحقيق أهداف عملائنا. و نعتقد أن تكتيكاتنا يجب أن تقوم أساساً على مزيج صحي من البحث والإبداع.
            نحن مجهزون بفريق ذكي محب للمرح، و التفكير المتقدم، والمتطلع إلى تلبية احتياجات العملاء. نجاح عملائنا يشهد على نجاحنا.
             يعتمد شعارنا بشكل أساسي على العمل الجاد والالتزام والحفاظ على الجودة ومشاركة النجاح وتجاوز إمكانات الجميع.
            `}
        
    }
    
  };