import React from 'react';
import styled from 'styled-components';
import { useRTL } from '../../LanguageContext';
const Location = () => {
  const { isRTL } = useRTL();
  return (
    <MapContainer>
      <div className='title'> {isRTL ? 'الموقع':'Location'}</div>
      <iframe
        title='aldana location'
        src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14446.120891016028!2d51.396231!3d25.1515685!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x9346ebbf744e66d2!2sAL%20DANA%20ALUMINUM%20PRODUCTS%2CQATAR%20(ALDANA%20BOND)!5e0!3m2!1sen!2sar!4v1618396836094!5m2!1sen!2sar'
        width='300'
        height='200'
        style={{
          border: 0,
          borderRadius: '10px',
          //   height: '100px',
          //   width: '250px',
        }}
        allowFullScreen=''
        loading='lazy'
      ></iframe>
    </MapContainer>
  );
};

export default Location;

const MapContainer = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 0rem 4rem 5rem;

  > div.title {
    font-family: 'Cairo-Bold';
    font-size: 4.5rem;
    padding: 2rem 0 2rem;

    @media (max-width: 1800px) {
      font-size: 4rem;
    }

    @media (max-width: 1600px) {
      font-size: 3.4rem;
    }

    @media (max-width: 1400px) {
      font-size: 3rem;
    }

    @media (max-width: 1300px) {
      font-size: 2.4rem;
    }

    @media (max-width: 680px) {
      font-size: 1.8rem;
    }
  }

  > iframe {
    width: 100%;
    height: 30vw;
    border: none;
    border-radius: 20px;
    /* box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
      0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
      0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
      0 100px 80px rgba(0, 0, 0, 0.12); */

    @media (max-width: 680px) {
      height: 60vw;
      border-radius: 10px;
    }
  } 
  }
`;
