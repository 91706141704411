import React from 'react';
import DemoBanner from './DemoBanner';
import PanelDisplayBanner from './PanelDisplayBanner';
import About from './About';
import Slider from '../shared/Slider';
import Video from './Video';
import ImgCarousel from '../shared/ImgCarousel';
import { BrowserRouter } from 'react-router-dom';
import WhatMakesUsUnique from './WhatMakesUsUnique';
import { Helmet } from 'react-helmet';
import AboutFeatures from './AboutFeatures';
import Quotation from '../shared/Quotation';

const Home = () => {
  return (
    <BrowserRouter>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Home Page</title>
        <link rel='canonical' href='http://mysite.com/example' />
      </Helmet>
      <Slider />
      <WhatMakesUsUnique />
      <About />
      <AboutFeatures />
      <ImgCarousel />
      <Video />
      <PanelDisplayBanner />
      <DemoBanner />
      <Quotation />
    </BrowserRouter>
  );
};

export default Home;
