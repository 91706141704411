import React from 'react';
import styled from 'styled-components';
import { useRTL } from '../../LanguageContext';
import _ from 'underscore';
import { aboutUsFeatures } from '../home/data';
import CustomList from '../shared/CustomList';
const Features = () => {
  const { isRTL, language } = useRTL();
  const features = _.find(aboutUsFeatures, (value, key) => key === language);

  return (
    <SectionWrapper isRTL={isRTL}>
      <div className='block1'>
        <div className='flex'>
          <img src={features.block3.img} alt={features.block3.title} />
          <div className='title'>{features.block3.title}</div>
        </div>

        <div>
          {features.block3.items.map((value, key) => (
            <CustomList key={key} text={value.item} isRTL={isRTL} />
          ))}
        </div>
      </div>

      <div className='block2'>
        <div className='flex'>
          <img src={features.block4.img} alt={features.block4.title} />
          <div className='title'>{features.block4.title}</div>
        </div>
        <img src={features.block4.image} alt='process-techniques' />
      </div>

      <div className='block3'>
        <div className='flex'>
          <img src={features.block5.img} alt={features.block5.title} />
          <div className='title'>{features.block5.title}</div>
        </div>
        <div className='row'>
          <div className='column1'>
            {features.block5.items1.map((row, key) => (
              <div className='content' key={key}>
                <p className='item'>{row.item} </p>
                <p className='subitem'>{row.subitem} </p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className='block4'>
        <div className='flex'>
          <img src={features.block6.img} alt={features.block6.title} />
          <div className='title'>{features.block6.title}</div>
        </div>
        <div className='row'>
          <div className='column1'>
            {features.block6.items1.map((row, key) => (
              <div className='content' key={key}>
                <p className='item'>{row.item} </p>
                <p className='subitem'>{row.subitem} </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </SectionWrapper>
  );
};

export default Features;

const SectionWrapper = styled.section`
  display: flex;
  flex-wrap: wrap;
  direction: ${(prop) => (prop.isRTL ? 'rtl' : 'ltr')};

  @media (max-width: 680px) {
    flex-flow: column;
  }

  > div {
    width: 50%;
    outline: 1px solid #dcdcdc;

    @media (max-width: 680px) {
      width: 100%;
    }

    > img {
      width: 100%;
      padding: ${(prop) =>
        prop.isRTL ? '0rem 3rem 0rem 1rem ' : '1rem 1rem 0rem 1rem'};

      @media (max-width: 680px) {
        width: 80%;
      }
    }

    > div.flex {
      display: flex;
      align-items: center;
      //padding: 2rem 1rem 0rem 4rem;
      padding: ${(prop) =>
        prop.isRTL ? '2rem 4rem 0rem 4rem ' : '2rem 1rem 0rem 4rem'};
      @media (max-width: 680px) {
        padding: ${(prop) =>
          prop.isRTL ? '1rem 1.5rem 0rem 0rem ' : '1rem 0rem 0rem 2rem'};
      }
      > img {
        vertical-align: middle;
        width: 9%;
        @media (max-width: 680px) {
          width: 15%;
        }
      }

      > div.title {
        font-family: 'Cairo-Bold';
        font-size: 3rem;
        padding: 0rem 2rem 0rem;
        align-items: center;
        display: flex;

        @media (max-width: 1800px) {
          font-size: 2.8rem;
        }

        @media (max-width: 1600px) {
          font-size: 2.5rem;
        }

        @media (max-width: 1400px) {
          font-size: 2.2rem;
        }

        @media (max-width: 1300px) {
          font-size: 1.9rem;
        }

        @media (max-width: 680px) {
          font-size: 1.4rem;
          padding: 0rem 5px 0rem;
        }
      }
    }
  }

  > div.block1,
  div.block3 {
    background-color: #f6f6f6;
  }

  > div > div.row {
    width: 100%;
    display: flex;
    font-family: 'Cairo-SemiBold';
    font-size: 1.125rem;
    white-space: wrap;

    @media (max-width: 1800px) {
      font-size: 1.1rem;
    }

    @media (max-width: 1600px) {
      font-size: 1rem;
    }

    @media (max-width: 1400px) {
      font-size: 0.9rem;
    }

    @media (max-width: 1300px) {
      font-size: 0.9rem;
    }

    > div.column1 {
      padding: 1rem 1rem 0 2rem;
      width: 100%;
      margin: ${(prop) => (prop.isRTL ? '0rem 2.5rem 0 0rem ' : '0 0 0 2rem')};

      /* @media (max-width: 680px) {
        padding: 0 0 0 0rem;
      } */

      > div.content {
        display: flex;

        > p.item {
          /* white-space: nowrap; */
          flex: 1 0 0;
          padding: 0 0 0 1rem;
        }

        > p.subitem {
          /* border: 1px solid black; */
          flex: 1 0 0;
          opacity: 0.6;
        }
      }
    }
  }
`;
