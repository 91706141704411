import React from 'react';
import styled from 'styled-components';
import why_us_bg from '../../assets/img/why-us-bg.jpg';
import { useRTL } from '../../LanguageContext';

const HeroBar = () => {
  const { isRTL } = useRTL();

  return (
    <SectionWrapper isRTL={isRTL}>
     
      <div className='title'>{isRTL? ' نظام إدارة الجودة المعتمد لدينا والتميز في التنفيذ هما ما يضمن نتائج الجودة':
      'Our certified quality management system and execution excellence are what ensures quality outcomes'}
      </div>
    </SectionWrapper>
  );
};

export default HeroBar;

const SectionWrapper = styled.section`
  direction: ${(prop) => (prop.isRTL ? 'rtl' : 'ltr')};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 72vh;
  background: linear-gradient(135deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 90%),
    url(${why_us_bg});
  //background: url(${why_us_bg});
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;

  @media (max-width: 1024px) {
    height: 40vh;
    background-size: contain;
    background-position: right top;
  }

  @media (max-width: 680px) {
    height: 50vh;
    //background-size: cover;
    background-position: center top;
    background: linear-gradient(135deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 70%),
    url(${why_us_bg});
    background-size: 270%;
  }

  > div.title {
    font-family: 'Cairo-Bold';
    color: white;
    width: 90%;
    font-size: 4.7rem;
    text-align: ${(prop) => (prop.isRTL ? 'right' : 'left')};

    @media (max-width: 1800px) {
      font-size: 4rem;
    }

    @media (max-width: 1600px) {
      font-size: 3.5rem;
    }

    @media (max-width: 1500px) {
      font-size: 3rem;
    }

    @media (max-width: 1300px) {
      font-size: 2.8rem;
    }

    @media (max-width: 1024px) {
      text-align: ${(prop) => (prop.isRTL ? 'right' : 'left')};
      margin: ${(prop) => (prop.isRTL ? '0rem 1rem 0 0rem ' : '0 0 0 1rem')};
      font-size: 1.9rem;
    }

    @media (max-width: 680px) {
      width: 90%;
    }
  }
`;
