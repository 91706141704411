import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import DemoBanner from '../home/DemoBanner';
import Title from './Title';
import Content from './Content';
import Quotation from '../shared/Quotation';
const PrivacyPolicy = () => {
  return (
    <BrowserRouter>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Privacy Policy</title>
        <link rel='canonical' href='http://mysite.com/example' />
      </Helmet>
      <Title />
      <Content />
      <DemoBanner />
      <Quotation />
    </BrowserRouter>
  );
};

export default PrivacyPolicy;
