export const privacyPolicy = {
    en: {
        p1:
        {
        title:"1.INTRODUCTION",
        description:`We collect, use, process and share personal data in accordance with privacy laws to make sure your data protection rights are implemented and enforced. We integrate various technical and organizational solutions to comply with applicable laws and rules related to personal data protection in countries where we operate. This Privacy Policy sets forth the basic rules and principles by which we process your personal data, and mentions our responsibilities while processing personal data.`},
        p2:
        {
          title:"2. TERMS WE USE AS LEGAL GUIDELINES OF THE PROCESSING",
          description:`
          There are some legal bases for the processing of your personal data
          and we count on them to process your personal data. We use the main
          three bases to process your personal data: consent, contract, and
          legitimate interest

          Consent - your clear agreement to the processing of your personal
          data for a specific purpose.

          Contract - the reason why the processing is necessary based on a
          contract you have with us, or because we have asked you to take
          specific steps before entering into that contract.

          Legitimate Interests - the reason why the processing your data is
          necessary which is based on the legitimate interests or the
          legitimate interests of a third party, provided those interests are
          not outweighed by your rights and interests.

          Some applicable laws may indicate other legal grounds for the
          processing and when applicable we will count on such grounds.
        `},
        p3:
          {
            title:"3. CONSENT RULE AND INTERRELATION WITH OTHER LEGAL GROUNDS",
            description:`If you have given consent to the processing of your data you can freely withdraw such consent at any time by contacting us.

            If you do withdraw your consent, and if we do not have another legal basis for the processing of your data, then we will stop the processing of the personal data.
            
            If we have another legal basis for the processing of your data, then we may continue to do so, subject to your legal interests and rights.`
         },
         p4:
         {
           title:"4. OUR RESPONSIBILITIES",
           description:`As we may have both roles as a data controller and data processor, we have obligations according to the applicable laws. We act as a data controller when we determine the purposes and means of the processing of your personal data. As a data processor, we process personal data on behalf of the controller.`
        },
        p5:
        {
          title:"5. RECOMMENDATIONS FOR YOU",
          description:`
          You should read this Privacy Policy carefully. We want to make sure that you understand all your rights. It is important for both of us that you maintain your personal data confidential and secure.

          If you provide us with personal data about other individuals, we will only employ that data for the special reason for which it was provided to us. By sending the data, you shall be sure that you have the right to dispose to process the personal data on your behalf in accordance with this Privacy Policy. In case if you submit third party’s personal data, be sure that you have a legal basis for the processing of such data.
          
          According to the applicable law, you may become a data controller/processor and it will impose on you additional obligations.
        `
       },
       p6:
       {
         title:"6. PROCESSED DATA",
         description:`
         
         We process personal data when you interact with our website ( Aldana) or any of our Google Play’ or Apple Store’s mobile applications (the “Platform”), especially:

         - you browse any page of the Platform;
         
         - you register on the Platform;
         
         - you log in;
         
         - you order any services available on the Platform;
         
         - you communicate with us;
         
         - you use our services, especially if you create a match, you find a match, you use matchmaking, and you build a team;
         
         - you receive notification from us;
         
         - we measure Platform’s traffic;
         
         - in cases which do not depend on you but we have a legal basis to collect such data.
         
         We collect the following types of data:
         
         - personal data which allows to register you such as name, username, email address, password, gender, date of birth, phone, profile picture;
         
         - your main position, secondary position, and third position in matches, created matches, and your team’s membership;
         
         - personal data which allows conducting payments via the Platform (this data may be collected by third parties and we just receive a confirmation that you have made payment);
         
         - history of your purchases;
         
         - data that identifies you such as your IP address, login information, browser type, and version, time zone setting, browser plug-in types, some location information about where you might be, operating system and version;
         
         - data on how you use the Platform such as your URL clickstreams (the path you take through the Platform), page response times, download errors, how long you stay on web pages, what you do on those pages, how often, and other actions;
         
         - other personal data you share with us or personal data which we may legally obtain for our legitimate interests.
         
         The recipients of the collected data are the highest management level of our company.
       `
      },
      p7:
      {
        title:"7. PURPOSES AND LEGAL BASIS FOR THE PROCESSING",
        description:`
        We process the data for:

        - Registering users. We need your personal data to register you and identify each time you access the Platform or use our services. Legal basis: Consent; Legitimate Interests.
        
        - Providing services. We need to provide services accessible via the Platform. Legal basis: Consent; Legitimate Interests.
        
        - Matching users. We collect your matches, positions, and team’s membership to match with other players. Legal basis: Contract, Legitimate Interests.
        
        - Providing offers and advertisement which may be interesting to you. Legal basis: Consent; Legitimate Interests.
        
        - Informing you about our services or news (for example, you may receive our newsletters). Legal basis: Consent; Legitimate Interests.
        
        - Keeping the Platform running (managing your requests, login, and authentication, remembering your settings, processing payments, hosting and back-end infrastructure). Legal basis: legitimate Interests.
        
        - Preventing frauds, illegal activity or any violation of the terms or Privacy Policy. We may disable access to the Platform, erase or correct personal data in some cases. Legal basis: legitimate Interests.
        
        - Improving the Platform (testing features, interacting with feedback platforms, managing landing pages, heat mapping the Platform, traffic optimization, and data analysis and research, including profiling and the use of machine learning and other techniques over your data and in some cases using third parties to do this). Legal basis: legitimate Interests.
        
        - Customer support (notifying you of any changes to the Platform, services, solving issues, any bug fixing). Legal basis: Contract; Legitimate Interests.
      `
      },
      p8:{
        title:"8. YOUR RIGHTS AS DATA SUBJECT",
        description:`
        You may ask us to refrain from using your data for marketing (when you opted-in). You can opt-out from marketing by contacting via the contact form.

        You can exercise the following rights by sending us by contacting via the contact form.
        
        You have the right to access information about you, especially:
        
        - the categories of data;
        
        - the purposes of data processing;
        
        - third parties to whom the data disclosed;
        
        - how long the data will be retained and the criteria used to determine that period;
        
        - other rights regarding the use of your data.
        
        You have the right to make us correct any inaccurate personal data about you.
        
        You can object to using your personal data for profiling you or making automated decisions about you. We may use your data to determine whether we should let you know information that might be relevant to you (for example, tailoring emails to you based on your behavior).
        
        You have the right to the data portability of your data to another service or website. We will give you a copy of your data in a readable format so that you can provide it to another service. If you ask us and it is technically possible, we will directly transfer the data to the other service for you.
        
        You have the right to be “forgotten”. You may ask erasing any personal data about you if it is no longer necessary for us to store the data for purposes of your use of the Platform.
        
        You have the right to lodge a complaint regarding the use of your data by us. You can address a complaint to your national regulator.
        
        In the context of the right to access information, we shall provide you with the information within one month of your request unless there is a justified requirement to provide such information faster. This term may be extended according to the applicable law.
      `

      },
      p9:
      {
        title:" 9. SECURITY",
        description:`
        We have security and organizational measures and procedures to secure the data collected and stored. You acknowledge that no data transmission is guaranteed to be 100% secure and there may be risks. You are responsible for your login information and password. You shall keep them confidential. In case if your privacy has been breached, please contact us immediately.`
      },
      p10:
      {
        title:" 10. LOCATION OF THE PROCESSING OF PERSONAL DATA AND THIRD-PARTY SERVICE PROVIDERS",
        description:`
        The personal data is collected by our company incorporated in Qatar.

        Our servers are provided by hosting provider, Amazon Web Services (AWS), based out of the United States of America which participates in and has certified their compliance with the EU-U.S. Privacy Shield Framework and the Swiss-U.S. Privacy Shield Framework. To learn more about the Privacy Shield Framework, visit the U.S. Department of Commerce’s Privacy Shield List.
        
        The Platform contains links to third-party sites and features. The Privacy Policy does not cover the privacy practices of such third parties. These third parties have their own privacy policies and we do not accept any responsibility or liability for their sites, features or policies. Please read their privacy policies before you submit any data to them. There are the following third parties.
        
        - Google Analytics to analyze data and improve our services and Platform;
        
        - Google AdWords for marketing purposes; and
        
        - Payment processors such as “QNBQatar - Payment Gateway” and other third-parties who offer payment services.
      `
      },
      p11:
      {
        title:" 11. RETENTION POLICY",
        description:`
        We store personal data as long as we need it and the retention practice depends on the type of data we collect, regulatory burden, and how we use the personal data. The retention period is based on criteria that include legally mandated retention periods, pending or potential litigation, intellectual property or ownership rights, contract requirements, operational directives or needs, and historical archiving.
`
      },

      p12:{
        title:" 12. COOKIE POLICY",
        description:`
        We collect certain types of information when you access or use the Platform, including cookies and similar tracking technologies.

        Cookies are small data files that are placed on your computer or mobile device when you visit the Platform. Cookies are used by the Platform in order to make the Platform work, or to work more efficiently, as well as to provide reporting information.
        
        You may turn off cookies in your browser via settings. You can block cookies on your browser to refuse cookies. You may delete cookies. If you turn off cookies, you can continue to use the Platform and browse its pages, but the Platform and certain services will not work properly.
        
        We collect essential cookies, functionality cookies, analytics and performance cookies.`

      },

      p13:{
        title:" 13. TRANSFER OF YOUR PERSONAL DATA",
        description:`
        In case if you reside in the EU/EEA and we offer our services or
        goods in your country, we would like to inform you that information
        we collect from you will be processed in Qatar which has not got a
        finding of “adequacy” from the European Union under Article 45 of
        the GDPR. We rely on derogations for specific situations as set
        forth in Article 49 of the GDPR. In particular, we collect and
        transfer to Qatar personal data only: with your consent; to perform
        a contract with you, or to fulfill a compelling legitimate interest
        in a manner that does not outweigh your rights and freedoms.


        We aim to apply appropriate safeguards to protect your privacy,
          security, your personal data and to use your personal data only
          consistent with your relationship with our company and the practices
          described in this Privacy Policy. We also enter into data processing
          agreements and model clauses with our vendors when applicable.
`
      },

      p14:{
        title:" 14. Contact information and Data protection officer",
        description:`
        We welcome your comments or questions about the Privacy Policy,
        Terms, services, Platform. You may contact us in writing at:
      `
    
    
    },

   
    

},
ar: {
    p1:
     {
       title:"المقدمة",
       description:`نقوم بجمع واستخدام ومعالجة ومشاركة البيانات الشخصية وفقاً لقوانين الخصوصية للتأكد من تنفيذ حقوق حماية البيانات الخاصة بك وإنفاذها. نقوم بدمج العديد من الحلول التقنية والتنظيمية للامتثال للقوانين والقواعد المعمول بها في البلدان التي نعمل فيها و المتعلقة بحماية البيانات الشخصية. تحدد سياسة الخصوصية هذه القواعد والمبادئ الأساسية التي نعالج من خلالها بياناتك الشخصية، وتوضح مسؤولياتنا أثناء معالجة البيانات الشخصية.`},
       p2:
       {
         title:"الشروط المطبقة كإرشادات قانونية لمعالجة البيانات",
         description:`
         هناك بعض الأسس القانونية التي نعتمد عليها لمعالجة بياناتك الشخصية. نستخدم الأسس الثلاثة الرئيسة التالية لمعالجة بياناتك الشخصية و هي: الموافقة، والعقد، والمصلحة المشروعة.

الموافقة - موافقتك الواضحة على معالجة بياناتك الشخصية لغرض محدد.
العقد - السبب في ضرورة المعالجة بناءً على عقد أبرمته معنا، أو لأننا طلبنا منك اتخاذ خطوات محددة قبل الدخول في هذا العقد.
المصالح المشروعة - السبب في ضرورة معالجة بياناتك استناداً إلى المصالح المشروعة أو المصالح المشروعة لطرف ثالث، شريطة ألا تتجاوز هذه المصالح حقوقك ومصالحك.
قد تشير بعض القوانين المعمول بها إلى أسس قانونية أخرى للمعالجة، وعند الاقتضاء، سوف نعتمد على هذه الأسس.

`},
       p3:
         {
           title:" قاعدة الموافقة والارتباط مع الأسس القانونية الأخرى",
           description:`إذا كنت قد وافقت على معالجة بياناتك، فيمكنك سحب هذه الموافقة بحرية في أي وقت عن طريق الاتصال بنا.

           إذا سحبت موافقتك، وإذا لم يكن لدينا أساس قانوني آخر لمعالجة بياناتك، فسنوقف معالجة البيانات الشخصية.
           
           و إذا كان لدينا أساس قانوني آخر لمعالجة بياناتك، فقد نواصل القيام بذلك، وفقاً لمصالحك وحقوقك القانونية.
           `
        },
        p4:
        {
          title:"مسؤولياتنا",
          description:`وبما أننا قد نؤدي دورين كمتحكم ومعالج بيانات، فإن لدينا التزامات وفقاً للقوانين المعمول بها. نحن نعمل كمتحكم بالبيانات عندما نحدد أغراض ووسائل معالجة بياناتك الشخصية. و بصفتنا معالج بيانات عندما نقوم بمعالجة البيانات الشخصية نيابة عن المتحكم.
          `
       },
       p5:
       {
         title:" توصيات لك",
         description:`
         يجب عليك قراءة سياسة الخصوصية هذه بعناية. نريد أن نتأكد من أنك تفهم جميع حقوقك. من المهم لكلينا الحفاظ على سرية وأمان بياناتك الشخصية.
         إذا قمت بتزويدنا ببيانات شخصية عن أفراد آخرين، فسنستخدم هذه البيانات فقط للسبب الخاص الذي تم توفيرها لنا من أجله. بإرسال البيانات، يجب أن تكون على يقين من أن لديك الحق في قرار معالجتنا تلك البيانات الشخصية بالنيابة عنك وفقاً لسياسة الخصوصية هذه. و ذلك في حالة إرسال بيانات شخصية لطرف ثالث، تأكد من أن لديك أساساً قانونياً لمعالجة هذه البيانات.
         وفقاً للقانون المعمول به، قد تصبح متحكماً أو معالجاً للبيانات وسيتم فرض التزامات إضافية عليك.
           `
      },
      p6:
      {
        title:" البيانات المعالجة ",
        description:`
        نقوم بمعالجة البيانات الشخصية من خلال التفاعل مع موقعنا الإلكتروني (الدانة) أو من خلال أي من تطبيقات الهاتف المحمول الخاصة بنا على جوجل بلاي أو متجر آبل ("المنصة")  وخاصة عندما:
        - تتصفح أي صفحة من صفحات المنصة؛
        - تقوم بالتسجيل في المنصة؛
        - تقوم بتسجيل الدخول؛
        - طلب أي خدمات متاحة على المنصة؛
        - أنت تتواصل معنا؛
        - أنت تستخدم خدماتنا، خاصة إذا قمت بإنشاء مباراة، ووجدت مباراة، واستخدمت إنشاء مباراة، وقمت ببناء فريق؛
        - تتلقى إخطاراً منا؛
        - نقيس حركة مرور المنصة؛
        - في الحالات التي لا تعتمد عليك ولكن لدينا أساس قانوني لجمع مثل هذه البيانات.
        نجمع الأنواع التالية من البيانات:
        - البيانات الشخصية التي تسمح بتسجيلك مثل الاسم واسم المستخدم وعنوان البريد الإلكتروني وكلمة السر والجنس وتاريخ الميلاد والهاتف والصورة الشخصية؛
        - المركز الرئيسي و الثانوي و الثالث في المباريات والمباريات التي تم إنشاؤها و
        
        عضوية فريقك؛
        
        - البيانات الشخصية التي تسمح بإجراء المدفوعات عبر المنصة (قد يتم جمع هذه البيانات من قبل أطراف ثالثة ونتلقى فقط تأكيداً بأنك قمت بالدفع)؛
        
        - تاريخ مشترياتك؛
        - البيانات التي تحدد هويتك مثل عنوان بروتوكول الانترنت IP الخاص بك ومعلومات تسجيل الدخول ونوع المتصفح والإصدار وإعداد المنطقة الزمنية وأنواع المكونات الإضافية للمتصفح وبعض معلومات الموقع حول مكانك ونظام التشغيل والإصدار؛
        - بيانات حول كيفية استخدامك للمنصة مثل مسارات URL الخاص بك (المسار الذي تسلكه عبر المنصة)، وأوقات استجابة الصفحة، وأخطاء التنزيل، ومدة بقائك على صفحات الويب، وماذا تفعل على تلك الصفحات، وعدد المرات، وغير ذلك من الإجراءات؛
        - البيانات الشخصية الأخرى التي تشاركها معنا أو البيانات الشخصية التي قد نحصل عليها قانونياً لمصالحنا المشروعة.
        متلقو البيانات المجمعة هم أعلى مستوى إداري في شركتنا.
        `
     },
     p7:
     {
       title:" الأغراض والأسس القانونية للمعالجة",
       description:`
       نعالج البيانات من أجل:
- تسجيل المستخدمين. نحتاج إلى بياناتك الشخصية لتسجيلك وتحديد هويتك في كل مرة تدخل فيها إلى المنصة أو تستخدم خدماتنا. الأساس القانوني: الموافقة؛ المصالح المشروعة.
- تقديم خدمات. نحن بحاجة إلى توفير خدمات يمكن الوصول إليها عبر المنصة. الأساس القانوني: الموافقة؛ المصالح المشروعة.
- مطابقة المستخدمين. نقوم بتجميع مبارياتكم ومواقعكم وعضوية الفريق للمطابقة مع اللاعبين الآخرين. الأساس القانوني: العقد، المصالح المشروعة.
- تقديم العروض والإعلانات التي قد تهمك. الأساس القانوني: الموافقة؛ المصالح المشروعة.
- إخطارك بخدماتنا أو أخبارنا (على سبيل المثال، قد تتلقى رسائلنا الإخبارية). الأساس القانوني: الموافقة؛ المصالح المشروعة.
- الحفاظ على تشغيل المنصة (إدارة طلباتك، وتسجيل الدخول، والمصادقة، وتذكر الإعدادات الخاصة بك، ومعالجة المدفوعات، والاستضافة والبنية التحتية الخلفية). الأساس القانوني: المصالح المشروعة.
- منع عمليات الاحتيال أو النشاط غير القانوني أو أي انتهاك للشروط أو سياسة الخصوصية. يجوز لنا تعطيل الوصول إلى النظام الأساسي، أو مسح البيانات الشخصية أو تصحيحها في بعض الحالات. الأساس القانوني: المصالح المشروعة.
- تحسين المنصة (اختبار السمات، والتفاعل مع منصات التعليقات، وإدارة الصفحات المقصودة، ورسم الخرائط الحرارية للمنصة، وتحسين حركة المرور، وتحليل البيانات والبحث، بما في ذلك التنميط واستخدام التعلم الآلي والتقنيات الأخرى على

 بياناتك وفي بعض الحالات باستخدام أطراف ثالثة للقيام بذلك). الأساس القانوني: المصالح المشروعة.

- دعم العملاء (لإعلامك بأي تغييرات في المنصة والخدمات وحل المشكلات وأي إصلاح للأخطاء). الأساس القانوني: العقد؛ المصالح المشروعة.

       `
     },
     p8:{
       title:" حقوقك بصفتك مالك البيانات",
       description:`
       يمكنك أن تطلب منا الامتناع عن استخدام بياناتك للتسويق (عند الاشتراك). يمكنك الانسحاب من التسويق عن طريق الاتصال عبر نموذج الاتصال.
       يمكنك ممارسة الحقوق التالية عن طريق مراسلتنا عبر نموذج الاتصال.
       لديك الحق في الوصول إلى المعلومات عنك، وخاصة:
       - فئات البيانات؛
       - أغراض معالجة البيانات؛
       - الأطراف الثالثة الذين تم الكشف عن بياناتهم؛
       - الفترة التي سيتم الاحتفاظ خلالها بالبيانات والمعايير المستخدمة لتحديد تلك الفترة؛
       - حقوق أخرى تتعلق باستخدام بياناتك.
       
       لديك الحق لتجعلنا نصحح أي بيانات شخصية غير دقيقة عنك.
       
       يمكنك الاعتراض على استخدام بياناتك الشخصية لتصنيفك أو اتخاذ قرارات آلية بشأنك. قد نستخدم بياناتك لتحديد ما إذا كان ينبغي لنا إعلامك بمعلومات التي قد تكون ذات صلة بك (على سبيل المثال، تخصيص رسائل بريدك الإلكتروني بناءً على سلوكك).
       
       لديك الحق في إمكانية نقل بياناتك إلى خدمة أو موقع ويب آخر. سنعطيك نسخة من بياناتك بتنسيق مقروء لتتمكن من توفيرها لخدمة أخرى. إذا طلبت منا وكان ذلك ممكناً تقنياً، فسننقل بياناتك مباشرة إلى الخدمة المطلوبة.
       
       لديك الحق في أن "تُنسى". يمكنك أن تطلب محو أي بيانات شخصية عنك إذا لم يعد من الضروري لنا تخزينها لأغراض استخدامك للمنصة.
       
       لديك الحق في تقديم شكوى بخصوص استخدامنا لبياناتك. يمكنك توجيه شكوى إلى المنظم الوطني الخاص بك.
       
       في سياق الحق في الوصول إلى المعلومات، سنزودك بالمعلومات في غضون شهر واحد من طلبك ما لم يكن هناك شرط مبرر لتقديم هذه المعلومات بشكل أسرع. يجوز تمديد هذه المدة وفقاً للقانون المعمول به.
       
       
       `

     },
     p9:
     {
       title:" الأمن ",
       description:`  لدينا تدابير وإجراءات أمنية وتنظيمية لتأمين البيانات التي تم جمعها وتخزينها. أنت تقر بأنه لا يوجد نقل بيانات آمن بنسبة 100% وقد تكون هناك مخاطر. أنت مسؤول عن معلومات تسجيل الدخول وكلمة السر. يجب عليك الحفاظ على سريتهما. في حال تم اختراق خصوصيتك، يرجى الاتصال بنا على الفور.
       `},
     p10:
     {
       title:" تحديد موقع معالجة البيانات الشخصية ومقدمي الخدمات من الأطراف الثالثة",
       description:`
       يتم جمع البيانات الشخصية من قبل شركتنا المؤسسة في قطر.

       يتم توفير خوادمنا من خلال مزود الاستضافة، Amazon Web Services (AWS)، ومقره خارج الولايات المتحدة الأمريكية والذي يشارك في إطار عمل درع الخصوصية بين الاتحاد الأوروبي والولايات المتحدة، و بين سويسرا والولايات المتحدة ومصادق على امتثاله لمتطلباتهما. لمعرفة المزيد حول إطار عمل درع الخصوصية، تفضل بزيارة قائمة درع الخصوصية بوزارة التجارة الأمريكية.
       
       تحتوي المنصة على روابط لمواقع وميزات الطرف الثالث. لا تشمل سياسة الخصوصية ممارسات الخصوصية لهذه الأطراف الثالثة. هذه الأطراف الثالثة لديها سياسات الخصوصية الخاصة بها ولا نتحمل أي مسؤولية أو التزام تجاه مواقعهم أو ميزاتهم أو سياساتهم. يرجى قراءة سياسات الخصوصية الخاصة بهم قبل إرسال أي بيانات إليهم. هناك الأطراف الثالثة التالية.
       
       - تحليلات جوجل لتحليل البيانات وتحسين خدماتنا ومنصتنا؛
       - Google AdWords لأغراض التسويق؛ و
       - معالجات الدفع مثل " بوابة الدفع - QNBQatar " و غيرها من الأطراف الثالثة التي تقدم خدمات الدفع.
         `
     },
     p11:
     {
       title:"  سياسة الاحتفاظ ",
       description:`
       نقوم بتخزين البيانات الشخصية طالما أننا في حاجة إليها وتعتمد ممارسة الاحتفاظ على نوع البيانات التي نجمعها والعبء التنظيمي وكيفية استخدامنا للبيانات الشخصية. تعتمد فترة الاحتفاظ على المعايير التي تشمل فترات الاحتفاظ الملزمة قانوناً، و الدعاوى المعلقة أو المحتملة، وحقوق الملكية أو الملكية الفكرية، ومتطلبات العقد، والتوجيهات أو الاحتياجات التشغيلية، والأرشفة التاريخية.
       `
     },

     p12:{
       title:"  سياسة ملفات تعريف الارتباط",
       description:`
       نقوم بجمع أنواع معينة من المعلومات عند الوصول إلى المنصة أو استخدامها، بما في ذلك ملفات تعريف الارتباط وتقنيات التتبع المماثلة.

       ملفات تعريف الارتباط هي ملفات بيانات صغيرة يتم وضعها على جهاز الكمبيوتر أو الجهاز المحمول الخاص بك عند زيارة المنصة. تستخدم المنصة ملفات تعريف الارتباط من أجل عمل المنصة، أو لعملها بشكل أكثر كفاءة، فضلاً عن توفير معلومات الإبلاغ.
       
       يمكنك إيقاف تشغيل ملفات تعريف الارتباط في متصفحك عبر الإعدادات. يمكنك حظر ملفات تعريف الارتباط على متصفحك لرفض ملفات تعريف الارتباط. يمكنك حذف ملفات تعريف الارتباط. إذا قمت بإيقاف تشغيل ملفات تعريف الارتباط، يمكنك الاستمرار في استخدام المنصة وتصفح صفحاتها، لكن المنصة وبعض الخدمات لن تعمل بشكل صحيح.
       نقوم بتجميع ملفات تعريف الارتباط الأساسية وملفات تعريف الارتباط الوظيفية والتحليلات وملفات تعريف الارتباط الخاصة بالأداء.
       `

     },

     p13:{
       title:" نقل بياناتك الشخصية",
       description:`في حال كنت مقيماً في الاتحاد الأوروبي أو المنطقة الاقتصادية الأوروبية ونقدم خدماتنا أو سلعنا في بلدك، نود أن نعلمك أن المعلومات التي نجمعها منك ستتم معالجتها في قطر والتي لم تحصل بعد على نتيجة "الكفاية" من الاتحاد الأوروبي بموجب المادة 45 من القانون العام لحماية البيانات (GDPR). نحن نعتمد على الاستثناءات في حالات محددة على النحو المنصوص عليه في المادة 49 من القانون العام لحماية البيانات (GDPR)
       على وجه الخصوص، نقوم بجمع البيانات الشخصية ونقلها إلى قطر فقط: بموافقتك؛ لتنفيذ عقد معك، أو لتحقيق مصلحة مشروعة بطريقة لا تؤثر على حقوقك وحرياتك.
       
       نهدف إلى تطبيق الضمانات المناسبة لحماية خصوصيتك وأمنك وبياناتك الشخصية، واستخدام بياناتك الشخصية بما يتوافق فقط مع علاقتك بشركتنا والممارسات الموضحة في سياسة الخصوصية هذه. كما ندخل أيضاً في اتفاقيات معالجة البيانات وأحكام نموذجية مع الموردين عند الاقتضاء.
       
`
     },

     p14:{
       title:"  مسؤول معلومات الاتصال وحماية البيانات",
       description:`
       نرحب بتعليقاتك أو أسئلتك حول سياسة الخصوصية، الشروط، الخدمات، المنصة. يمكنك الاتصال بنا كتابياً على:

     `
   
   
   },

  
   

}
}