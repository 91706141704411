import React from 'react';
import _ from 'underscore';
import styled from 'styled-components';
import { useRTL } from '../../LanguageContext';

const content = {
  en: { title: 'Our Projects and Clients' },
  ar: { title: 'مشاريعنا وعملائنا' },
};
const Section = () => {
  const { isRTL, language } = useRTL();

  return (
    <Heading isRTL={isRTL}>
      {_.find(content, (value, key) => key === language).title}
    </Heading>
  );
};

export default Section;

const Heading = styled.div`
  direction: ${(prop) => (prop.isRTL ? 'rtl' : 'ltr')};
  font-family: 'Cairo-Bold';
  background: white;
  text-align: center;
  font-size: 4.5rem;
  padding: 9rem 0rem 4rem;

  @media (max-width: 1800px) {
    font-size: 4rem;
    padding: 8rem 0rem 3rem;
  }

  @media (max-width: 1600px) {
    font-size: 3.4rem;
    padding: 7rem 0rem 3rem;
  }

  @media (max-width: 1400px) {
    font-size: 2.8rem;
    padding: 6rem 0rem 3rem;
  }

  @media (max-width: 1300px) {
    font-size: 2.4rem;
    padding: 5rem 0rem 3rem;
  }

  @media (max-width: 1024px) {
    font-size: 1.8rem;
    padding: 4rem 0rem 2rem;
  }
`;
