import React from 'react';
import styled from 'styled-components';
import { WHAT_IS_ACP } from './data';
import _ from 'underscore';
import { useRTL } from '../../LanguageContext';
const Content = () => {
  const { language, isRTL } = useRTL();
  const whatIs = _.find(WHAT_IS_ACP, (value, key) => key === language);
  return (
    <ContentWrapper isRTL={isRTL}>
      <div className='aldana'>
        <div className='title'>{whatIs.title}</div>
        <div className='text'>
          <p>{whatIs.text}</p>
        </div>
      </div>
    </ContentWrapper>
  );
};

export default Content;

const ContentWrapper = styled.section`
  padding: 2rem 4rem 0 3rem;
  color: black;
  margin-bottom: 2rem;

  @media (max-width: 680px) {
    padding: 0 1rem 0 1rem;
  }

  > div > div.title {
    font-size: 3rem;
    font-family: 'Cairo-Bold';
    padding: 3rem 2.5rem 1rem;
    text-align: ${(prop) => (prop.isRTL ? 'right' : 'left')};

    @media (max-width: 1800px) {
      font-size: 2.8rem;
    }

    @media (max-width: 1600px) {
      font-size: 2.5rem;
    }

    @media (max-width: 1400px) {
      font-size: 2.2rem;
    }

    @media (max-width: 1300px) {
      font-size: 1.9rem;
      padding: 1rem 1.5rem 1rem;
    }

    @media (max-width: 680px) {
      font-size: 1.8rem;
    }
  }

  > div > div.text {
    font-size: 1.125rem;
    font-family: 'Cairo-Medium';
    line-height: 2;
    padding: 0rem 2.5rem 0rem;
    text-align: ${(prop) => (prop.isRTL ? 'right' : 'left')};
    @media (max-width: 1800px) {
      font-size: 1rem;
    }

    @media (max-width: 1600px) {
      font-size: 0.9rem;
    }

    @media (max-width: 1500px) {
      font-size: 0.9rem;
    }

    @media (max-width: 1300px) {
      font-size: 0.9rem;
      padding: 0rem 1.5rem 1rem;
    }

    @media (max-width: 680px) {
      text-align: justify;
      word-wrap: break-word;
      font-size: 0.9rem;
    }
  }

  > div > button {
    margin: 1rem 0 2rem;
    font-size: 1.125rem;
    font-family: 'Cairo-SemiBold';

    @media (max-width: 1800px) {
      font-size: 1.1rem;
    }

    @media (max-width: 1600px) {
      font-size: 1rem;
    }

    @media (max-width: 1400px) {
      font-size: 0.9rem;
    }

    @media (max-width: 1300px) {
      font-size: 0.9rem;
    }
  }
`;
