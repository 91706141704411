import React from 'react';
import Carousel from 'react-multi-carousel';
import styled, { keyframes } from 'styled-components';
import 'react-multi-carousel/lib/styles.css';
import { CAROUSEL_IMGS } from '../shared/data';
import clients from '../../assets/img/client-banner.png';
import { useRTL } from '../../LanguageContext';
const HeroSlider = () => {
  const { isRTL } = useRTL();
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1025 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1025, min: 464 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 680, min: 0 },
      items: 1.5,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <Wrapper>
      <Carousel
        partialVisible={true}
        style={{ backgroundColor: 'white' }}
        swipeable={true}
        draggable={true}
        showDots={false}
        responsive={responsive}
        ssr={true}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={3000}
        keyBoardControl={true}
        customTransition='transform 1000ms ease-in-out'
        transitionDuration={1000}
        containerClass='carousel-container'
        // containerClass='react-multi-carousel-list'
        removeArrowOnDeviceType={['tablet', 'mobile']}
        dotListClass='custom-dot-list-style'
        // itemClass='carousel-item-padding-40-px'
        renderButtonGroupOutside={false}
      >
        {CAROUSEL_IMGS.map((item, key) => (
          <div
            class='card'
            key={key}
            style={{
              // border: '1px solid black',

              display: 'flex',
              flexFlow: 'column',
              //   justifyContent: 'space-between',
              margin: '0 auto',
              padding: '0',
              alignItems: 'center',
            }}
          >
            <div
              className='flex'
              style={{
                // border: '1px solid black',
                display: 'flex',
                flexFlow: 'row',
                // justifyContent: 'space-between',
              }}
            >
              <Img
                src={item.image}
                alt={item.alt}
                style={{ margin: '0 auto' }}
              />
              
            </div>
            <div
              className='text'
              style={{
                fontFamily: 'Gilory-Bold',
                display: 'flex',
                justifyContent: 'space-between',
                width: '87%',
                alignItems: 'center',
                // border: '1px solid black',
              }}
            >
              <div
                style={{
                  fontFamily: 'Gilory-Bold',
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '93%',
                  alignItems: 'center',
                  padding: '0 0 4rem',
                  // border: '1px solid black',
                }}
              >
                <p style={{ color: '#1D2D5B', fontSize: '2.5vw' }}>
                  {isRTL?'الدوحة':'Doha'} 
                </p>
                
              </div>
            </div>
          </div>
        ))}
      </Carousel>
      <div className='clientele-marquee'>
        <Marquee>
          <img className='marquee' src={clients} alt='clients' />
          <img className='marquee marquee2' src={clients} alt='clients' />
        </Marquee>
      </div>
    </Wrapper>
  );
};

export default HeroSlider;

const Wrapper = styled.section`
  padding: 2rem 0 0;
  background-color: white;
  align-items: center;
  overflow: hidden;
  margin: 5vh 0 0;

  > div.clientele-marquee {
    background-color: white;
    display: flex;
    justify-content: center;
    overflow: hidden;
  }
`;

const Img = styled.img`
  width: 40vw;
  margin: 10vh 0 10vh;
  border-radius: 5px;
  /* box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100vw 80px rgba(0, 0, 0, 0.12); */

  @media (max-width: 1024px) {
    margin-bottom: 5vh;
  }
  @media (max-width: 680px) {
    width: 58vw;
  }
`;

const marqueeText = keyframes`
  0%  {left: 100%;}
  100% {left: -300%;}
`;

const marqueeTextMobile = keyframes`
  0%  {left: 100%;}
  100% {left: -800%;}
`;

const Marquee = styled.div`
  /* border: 1px solid black; */
  width: 100%;
  justify-content: center;
  overflow: hidden;
  position: relative !important;
  height: 100px;
  padding: 0 0 1rem;

  > img.marquee {
    position: absolute;
    animation: ${marqueeText} 70s linear 0s infinite;
    font-family: 'Cairo-Bold';
    color: #1d2d5b2b;
    width: 200%;

    @media (max-width: 680px) {
      width: 500%;
      animation: ${marqueeTextMobile} 70s linear 0s infinite;
    }
  }

  > img.marquee2 {
    animation-delay: 20s;
    left: 100%;

    @media (max-width: 1024px) {
      animation-delay: 20s;
    }
  }
`;
