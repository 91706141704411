import React from 'react';
import styled from 'styled-components';

const Video = () => {
  return (
    <VideoWrapper>
      <iframe
        src='https://www.youtube.com/embed/R7PGOP5G1yM'
        title='YouTube video player'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
      ></iframe>
    </VideoWrapper>
  );
};

export default Video;

const VideoWrapper = styled.section`
  background-color: white;
  display: flex;
  justify-content: center;
  padding: 10rem 6rem 10rem;

  @media (max-width: 1800px) {
    padding: 8rem 6rem 8rem;
  }

  @media (max-width: 1600px) {
    padding: 6rem 6rem 6rem;
  }

  @media (max-width: 1400px) {
    padding: 5rem 6rem 5rem;
  }

  @media (max-width: 1300px) {
    padding: 4rem 6rem 4rem;
  }

  @media (max-width: 1024px) {
    padding: 3rem 1rem 4rem;
  }

  @media (max-width: 680px) {
    padding: 1rem 1rem 2rem;
  }

  > iframe {
    width: 100%;
    height: 40vw;
    border: none;
    border-radius: 20px;
    /* box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
      0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
      0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
      0 100px 80px rgba(0, 0, 0, 0.12); */

    @media (max-width: 680px) {
      height: 60vw;
      border-radius: 10px;
    }
  }
`;
