import React from 'react';
import styled from 'styled-components';
import Download from '../../assets/svg/download.svg';

const Dot = ({ children, className, onClick }) => {
  return (
    <DotWrapper className={className} onClick={onClick ? onClick : () => {}}>
      {children}
    </DotWrapper>
  );
};

export default Dot;

const DotWrapper = styled.div`
  height: 2rem;
  width: 2rem;
  background: white;
  border-radius: 50%;
  display: inline-block;
  border: none;
  text-align: center;
  background-image: url(${Download});
  background-position: center, center;
  background-repeat: no-repeat;
  background-size: 50% auto;
  transition: all 0.5s ease-in-out;

  cursor: pointer;

  &:hover {
    transform: scale(1.3);
    transition: all 0.5s ease-in-out;
  }
`;
