import React from 'react';
import styled from 'styled-components';
import NormalButton from '../shared/buttons/NormalButton';
import LetsGo from '../../assets/svg/lets-go.svg';
import { useRTL } from '../../LanguageContext';
import { cookiePolicy } from './data';
import _ from 'underscore';
const Content = () => {
  const { isRTL, language } = useRTL();

  const cookie = _.find(cookiePolicy, (value, key) => key === language);
  return (
    <ContentWrapper isRTL={isRTL}>
      <div className='intro'>
        <div className='title'>{cookie.p1.title}</div>
        <div className='text'>
          <p>
          {cookie.p1.d1}
          </p>{' '}
          <p>
          {cookie.p1.d2}
          </p>
          <p>
          {cookie.p1.d3}
          </p>
        </div>
      </div>
      <div className='about-cookies'>
        <div className='title'>{cookie.p2.title}</div>
        <div className='text'>
          <p>
          {cookie.p2.d1}
          </p>
        </div>
      </div>
      <div className='type-cookies'>
        <div className='title'>{cookie.p3.title}</div>
        <div className='text'>
          <p>
          {cookie.p3.d1}{' '}
          </p>{' '}
          <p>
          {cookie.p3.d2}{' '}
          </p>{' '}
          <p>
          {cookie.p3.d3}{' '}
          </p>{' '}
         
        </div>
      </div>
      {/* <div className='aldana'>
        <div className='title'>4. Who is Aldana</div>
        <div className='text'>
          <p>Wasfa Company, part of Novalabs</p>{' '}
          <p>Registration number: 95970</p>
          <p>
            Registered address: PO Box 3436 - Madinath Khalifa North, Doha.
          </p>{' '}
          <p> Email: info@aldanabond.com </p>
        </div>
        <NormalButton>
          I want to know more
          <span>
            <img src={LetsGo} alt='lets go' width='30' height='20' />
          </span>
        </NormalButton>
      </div> */}
    </ContentWrapper>
  );
};

export default Content;

const ContentWrapper = styled.section`
  padding: 2rem 8rem 0 8rem;
  color: #1d2d5b;
  direction: ${(prop) => (prop.isRTL ? 'rtl' : 'ltr')};

  @media (max-width: 680px) {
    padding: 0 1rem 0 1rem;
  }

  > div > div.title {
    font-size: 2rem;
    font-family: 'Cairo-Bold';
    padding: 3rem 0 1rem;

    @media (max-width: 680px) {
      font-size: 1.6rem;
    }
  }

  > div > div.text {
    font-size: 1rem;
    font-family: 'Cairo-Medium';

    @media (max-width: 680px) {
      text-align: justify;
      word-wrap: break-word;
    }
  }

  > div > button {
    margin: 1rem 0 2rem;
  }
`;
