import React, { useState, useEffect, useRef } from 'react';
import { Link as QuoteLink } from 'react-scroll';
import _ from 'underscore';
import { useRTL } from '../../LanguageContext';
import styled from 'styled-components';
import { NavLink as Link } from 'react-router-dom';
import { HEADER_LINKS, LANGUAGE, QUOTE } from './data';
import NormalButton from './buttons/NormalButton';
import logo from '../../assets/img/ALDANA BOND.jpeg';

function Header() {
  //passing useState for language from another component ( LanguageContext ) using useContext
  const { isRTL, language, setIsRTL, setLanguage } = useRTL();

  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const prevRTL = usePrevious(isRTL);

  useEffect(() => {
    isRTL ? setLanguage('ar') : setLanguage('en');
  }, [isRTL, setLanguage]);

  const setRTL = () => {
    setIsRTL(!isRTL);
  };

  //for menu icon in mobile view
  const setRTLandClick = () => {
    setIsRTL(!isRTL);
    handleClick();
  };

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  return (
    <Nav isRTL={isRTL}>
      <Blur click={click} />
      {/* <LogoLink  to={'/home'} exact={true}> */}
      <a href={'/'} className='logo' onClick={event =>  window.location.href='/'}>
        <img src={logo} alt='aldana-bond-logo'></img>
      </a>
       {/* </LogoLink> */}
     

      <MenuItems>
        <div className='left'>
          {_.find(HEADER_LINKS, (value, key) => key === language)
            .slice(0, 7)
            .map((item, key) => (
              <NavLink key={key} to={item.url} exact={true}>
                <div>{item.title}</div>
              </NavLink>
            ))}
        </div>

        <div className='right'>
          <NavLink
            to='#'
            align='center'
            onClick={setRTL}
            style={{ color: '#131314' }}
          >
            {_.find(LANGUAGE, (value, key) => key === language).title}
          </NavLink>

          <QLink to='get-quote' spy={true} smooth={true} align='center'>
            {' '}
            <NormalButton>
              {_.find(QUOTE, (value, key) => key === language).title}
            </NormalButton>
          </QLink>
        </div>
      </MenuItems>

      <MenuIcon
        isRTL={isRTL}
        className='material-icons md-48'
        onClick={handleClick}
      >
        menu
      </MenuIcon>

      <SideNav click={click} isRTL={isRTL} prevRTL={prevRTL}>
        <ClearIcon
          isRTL={isRTL}
          className='material-icons'
          onClick={handleClick}
        >
          clear
        </ClearIcon>

        {_.find(HEADER_LINKS, (value, key) => key === language).map(
          (item, key) => (
            <SideLink
              language={language}
              key={key}
              to={item.url}
              exact={true}
              onClick={handleClick}
            >
              {item.title}
            </SideLink>
          )
        )}

        <SideLink to='#' onClick={setRTLandClick} language={language}>
          {_.find(LANGUAGE, (value, key) => key === language).title}
        </SideLink>
      </SideNav>
    </Nav>
  );
}

export default Header;

const Nav = styled.header`
  height: 6rem;
  display: flex;
  align-items: center;
  color: #161625;
  direction: ${(prop) => (prop.isRTL ? 'rtl' : 'ltr')};

  > a.logo {
    flex: 0.11 0 0;
    font-family: 'Cairo-Bold';
    font-size: 2rem;
    letter-spacing: 5px;
    padding: ${(prop) =>
      prop.isRTL ? '0rem 7% 0rem 0rem' : '0.7rem 0rem 0rem 7% '};

    @media (max-width: 1024px) {
      padding: 10px 40px 10px 20px;
    }

    @media (max-width: 800px) {
      padding: 20px 20px 10px 20px;
    }

    @media (max-width: 480px) {
      padding: 20px 20px 10px 20px;
    }
  
    @media (max-width: 300px) {
      padding: 20px 20px 10px 20px;
    }
    

    > img {
      width: 80px;
      height: 80px;

      @media (max-width: 480px) {
        width: 70px;
        height: 70px;
      }
    }
  }
`;

const Blur = styled.div`
  display: ${(prop) => (prop.click ? '' : 'none')};
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  opacity: 0.5;
  z-index: 2;
`;

const MenuItems = styled.div`
  flex: 0.83 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1024px) {
    display: none;
  }

  > div {
    display: flex;
    align-items: center;
  }
`;

const MenuIcon = styled.span`
  position: absolute;
  left: ${(prop) => (prop.isRTL ? '6%' : '')};
  right: ${(prop) => (prop.isRTL ? '' : '6%')};
  display: none;
  color: #1d2d5b;

  @media (max-width: 1024px) {
    display: block;
  }

  &:hover {
    cursor: pointer;
  }
`;

const ClearIcon = styled.span`
  position: absolute;
  top: 1%;
  left: ${(prop) => (prop.isRTL ? '10%' : '80%')};
  color: #1d2d5b;

  @media (max-width: 680px) {
    top: 2%;
  }

  &:hover {
    cursor: pointer;
  }
`;

const activeClassName = 'nav-item-active';


const NavLink = styled(Link).attrs({ activeClassName })`
  &.${activeClassName} {
    color: var(--dana-color);
  }
  font-family: 'Cairo-Medium';
  font-size: 1.125rem;
  color: #161625;
  padding: 0rem 0.8rem 0rem;
  float: ${(props) => (props.align === 'center' ? 'right' : 'left')};
  text-decoration: none;

  @media (max-width: 1600px) {
    font-size: 1rem;
  }

  @media (max-width: 1400px) {
    font-size: 0.9rem;
  }
`;

const QLink = styled(QuoteLink)`
  > button {
    font-family: 'Cairo-Bold';
    color: #9e0a34;
    background: linear-gradient(to right, #9e0a340a 50%, white 50%);
    background-size: 200% 100%;
    background-position: left bottom;
    transition: all 1s ease;
    border: 2px solid #9e0a340a;
    padding: 10px 20px 10px;
    border-radius: 10px;
    font-size: 1rem;

    &:hover {
      //background-position: right bottom;
      //transition: all 1s ease;
      color: #9e0a34;
      background-color:#9e0a34;
    }

    @media (max-width: 1400px) {
      font-size: 0.9rem;
    }
  }
`;

const SideNav = styled.div`
  font-family: 'Cairo-Medium';
  height: 100%;
  width: ${(prop) => (prop.click ? '250px' : '0px')};
  position: fixed;
  z-index: 2;
  top: 0;
  right: ${(prop) => (prop.isRTL ? '' : '0')};
  left: ${(prop) => (prop.isRTL ? '0' : '')};
  background-color: white;
  overflow: hidden;
  display: flex;
  flex-flow: column;
  transition: ${(prop) => (prop.prevRTL !== prop.isRTL ? '0' : '0.5s')};
  padding-top: 60px;
  /* display: none; */
`;

const SideLink = styled(Link).attrs({ activeClassName })`
  /* &.${activeClassName} {
    opacity: 0.6;
  } */

  padding: ${(prop) =>
    prop.language === 'ar' ? '8px 32px 10px 0px' : '8px 0px 10px 32px'};
  text-decoration: none;
  font-size: 1.5rem;
  color: #1d2d5b;
  white-space: nowrap;
  transition: 0.3s;

  @media (max-width: 1024px) {
    font-size: 1.5rem;
  }

  @media (max-width: 680px) {
    font-size: 0.9rem;
  }

  @media (max-width: 880px) {
    font-size: 1rem;
  }

  &:hover {
    color: #f1f1f1;
  }
`;
