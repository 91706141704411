import React from 'react';
import styled from 'styled-components';
import { BOND_COATINGS, PRODUCT_DATA } from './data';

import _ from 'underscore';
import { useRTL } from '../../LanguageContext';
const Products = () => {

  const { language,isRTL } = useRTL();
  const productData = _.find(PRODUCT_DATA, (value, key) => key === language);
  const coatingData = _.find(BOND_COATINGS, (value, key) => key === language);
  return (
    <SectionWrapper isRTL={isRTL}>
      <div className='products'> 
        <div className='header'>
          <img src={productData.img} alt={productData.title} />
          <div className='title'>{productData.title}</div>
        </div>
        <div className='cards'>
          {productData.card.map((item, key) => (
            <div className='card' key={key}>
              <div className='title'>{item.title} </div>
              <div className='text'>{item.text} </div>
              {/* <div className='link'>
                <p>{item.link}</p>{' '}
              </div> */}
            </div>
          ))}
        </div>
      </div>

      <div className='bond-coatings'>
        <div className='header'>
          <img src={coatingData.img} alt={coatingData.title} />
          <div className='title'>{coatingData.title}</div>
        </div>
        <div className='blocks'>
          {coatingData.block1.map((item, key) => (
            <div className='block' key={key}>
              <div className='title'>{item.title} </div>
              <p>{item.text}</p>
            </div>
          ))}

          <div className='block'>
            <div className='title'> {coatingData.block2.title}</div>
            {coatingData.block2.items.map((item, key) => (
              <p key={key}>
                {' '}
                <span>{item.boldText}</span> {item.text}
              </p>
            ))}
          </div>
        </div>
      </div>
    </SectionWrapper>
  );
};

export default Products;

const SectionWrapper = styled.section`
  padding: 0rem 4rem 2rem 3rem;
  direction: ${(prop) => (prop.isRTL ? 'rtl' : 'ltr')};
  > div > div.header {
    display: flex;
    align-items: center;
    font-size: 3rem;
    font-family: 'Cairo-Bold';
    padding: 4rem 0rem 0rem;

    @media (max-width: 1800px) {
      font-size: 2.8rem;
    }

    @media (max-width: 1600px) {
      font-size: 2.5rem;
    }

    @media (max-width: 1400px) {
      font-size: 2.2rem;
    }

    @media (max-width: 1300px) {
      font-size: 1.9rem;
    }

    @media (max-width: 680px) {
      font-size: 1.6rem;
    }

    > div.title {
      padding: 0rem 2rem 0rem;
      text-align: ${(prop) => (prop.isRTL ? 'right' : 'left')};
    }

    > img {
      @media (max-width: 680px) {
        width: 20%;
      }
    }
  }

  > div > div.cards {
    display: flex;
    justify-content: space-between;
    padding: 2rem 0rem 0;

    @media (max-width: 1024px) {
      flex-flow: column;
    }

    > div.card {
      /* flex: 0.9 0 0; */
      position: relative;
      display: flex;
      flex-flow: column;
      background: white;
      padding: 1rem 1.5rem 0rem;
      border: 1px solid #e9e8e8;
      border-radius: 70px;
      /* box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
        0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
        0 0px 17.9px rgba(0, 0, 0, 0.072), 0 0.8px 0px rgba(0, 0, 0, 0.086),
        0 0px 0px rgba(0, 0, 0, 0.12); */
      width: 80vh;
      height: 40vh;

      @media (max-width: 1024px) {
        width: 60vh;
        height: 20vh;
        margin: 0 0 1rem;
      }

      @media (max-width: 780px) {
        width: auto;
        height: auto;
        margin: 0 0 1rem;
      }

      > div.title {
        font-family: 'Cairo-Bold';
        font-size: 2rem;
        max-width: 35%;
        padding: 1rem 1rem 2rem;
        text-align: ${(prop) => (prop.isRTL ? 'right' : 'left')};

        @media (max-width: 1800px) {
          font-size: 1.8rem;
        }

        @media (max-width: 1600px) {
          font-size: 1.6rem;
        }

        @media (max-width: 1500px) {
          font-size: 1.4rem;
        }

        @media (max-width: 1300px) {
          font-size: 1.2rem;
        }

        @media (max-width: 780px) {
          max-width: 100%;
        }
      }

      > div.text {
        font-family: 'Cairo-Medium';
        font-size: 1.125rem;
        line-height: 2;
        padding: 0rem 1rem 2rem;

        @media (max-width: 1800px) {
          font-size: 1rem;
        }

        @media (max-width: 1600px) {
          font-size: 0.9rem;
        }

        @media (max-width: 1400px) {
          font-size: 0.9rem;
        }

        @media (max-width: 1300px) {
          font-size: 0.7rem;
        }
      }

      > div.link {
        font-family: 'Cairo-Bold';
        position: absolute;
        bottom: 5%;
        color: #9e0a34;
        font-size: 1.5rem;
        margin: 0 1rem 1rem;

        > p {
          border: none;
        }

        @media (max-width: 1800px) {
          font-size: 1.4rem;
        }

        @media (max-width: 1600px) {
          font-size: 1.3rem;
        }

        @media (max-width: 1400px) {
          font-size: 1.2rem;
        }

        @media (max-width: 1300px) {
          font-size: 1.1rem;
        }

        @media (max-width: 780px) {
          position: relative;
        }

        &:hover {
          opacity: 0.7;
          cursor: pointer;
        }
      }
    }
  }

  > div.bond-coatings > div.blocks {
    padding: 2rem 0 0;

    > div.block {
      padding: 0 0 1rem;

      > div.title {
        color: #9e0a34;
        font-family: 'Cairo-Bold';
        font-size: 1.125rem;
        text-align: ${(prop) => (prop.isRTL ? 'right' : 'left')};

        @media (max-width: 1800px) {
          font-size: 1.1rem;
        }

        @media (max-width: 1600px) {
          font-size: 1rem;
        }

        @media (max-width: 1400px) {
          font-size: 0.9rem;
        }

        @media (max-width: 1300px) {
          font-size: 0.9rem;
        }
      }

      > p {
        font-family: 'Cairo-Medium';
        font-size: 1.125rem;
        line-height: 2;
        text-align: ${(prop) => (prop.isRTL ? 'right' : 'left')};

        @media (max-width: 1800px) {
          font-size: 1rem;
        }

        @media (max-width: 1600px) {
          font-size: 0.9rem;
        }

        @media (max-width: 1400px) {
          font-size: 0.9rem;
        }

        @media (max-width: 1300px) {
          font-size: 0.9rem;
        }

        > span {
          font-family: 'Cairo-Bold';
          font-weight: bold;
        }
      }
    }
  }
`;
