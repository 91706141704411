import React, { useState, useContext } from 'react';
import { AppContext } from './Context';

export function LanguageProvider({ children }) {
  const [isRTL, setIsRTL] = useState(false);
  const [language, setLanguage] = useState('en');

  const value = { isRTL, language, setIsRTL, setLanguage };

  return (
    //dynamically wrapping children with the provider tag
    <AppContext.Provider value={value}>{children}</AppContext.Provider>
  );
}

export function useRTL() {
  return useContext(AppContext);
}
