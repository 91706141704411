import React from 'react';
import styled from 'styled-components';
import qatar_skyline from '../../assets/svg/qatar-skyline.svg';
import { FACTORY_DATA } from './data';
import { useRTL } from '../../LanguageContext';
import _ from 'underscore';
const Content = () => {
  const { language ,isRTL} = useRTL();
  const factories = _.find(FACTORY_DATA, (value, key) => key === language);
  return (
    <ContentWrapper isRTL={isRTL}>
      <div className='intro'>
        <div className='title'>{factories[0].title}</div>
        <div className='text'>
          <p>
            {factories[1].text1}
            </p>
            <p>
               <span> {factories[2].text2} </span>   
               </p>
               <p>
            <span> {factories[3].text3}</span>   
            </p> 
            <p>
            <span> {factories[4].text4}</span>
            </p>
      
        </div>
        <img src={qatar_skyline} alt='organizational chart' />
      </div>
    </ContentWrapper>
  );
};

export default Content;

const ContentWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 2rem 0 5rem;

  @media (max-width: 680px) {
    padding: 0 0 1rem;
  }

  > div {
    width: 90%;
    padding: 0 0 3rem;
    display: flex;
    flex-flow: column;
    justify-content: center;

    > img {
      @media (max-width: 1800px) {
        width: 60%;
      }

      @media (max-width: 1600px) {
        width: 56%;
      }

      @media (max-width: 1400px) {
        width: 54%;
      }

      @media (max-width: 1300px) {
        width: 52%;
      }

      @media (max-width: 680px) {
        width: 100%;
      }
    }
  }

  > div > div.title {
    font-family: 'Cairo-Bold';
    font-size: 4.5rem;
    padding: 1rem 0 1rem;
    text-align: ${(prop) => (prop.isRTL ? 'right' : 'left')};
    @media (max-width: 1800px) {
      font-size: 4rem;
    }
 
    @media (max-width: 1600px) {
      font-size: 3.4rem;
    }

    @media (max-width: 1400px) {
      font-size: 3rem;
    }

    @media (max-width: 1300px) {
      font-size: 2.4rem;
      margin: ${(prop) => (prop.isRTL ? '0 1rem 0 0 ' : '0 0 0 1rem')};
    }

    @media (max-width: 680px) {
      font-size: 1.8rem;
    }
  }

  > div > div.text {
    > p {
      text-align: ${(prop) => (prop.isRTL ? 'right' : 'left')};
      line-height: 2;
      font-family: 'Cairo-Medium';
      font-size: 1.5rem;
      color: #1d2d5b;

      @media (max-width: 1800px) {
        font-size: 1.4rem;
      }

      @media (max-width: 1600px) {
        font-size: 1.3rem;
      }

      @media (max-width: 1400px) {
        font-size: 1.2rem;
      }

      @media (max-width: 1300px) {
        font-size: 1.1rem;
        margin: ${(prop) => (prop.isRTL ? '0 1rem 0 0 ' : '0 0 0 1rem')};
      }
    }

    > p > span {
      font-family: 'Cairo-Bold';
    }
  }
`;
