import React from 'react';
import styled from 'styled-components';
import privacy_shield from '../../assets/svg/privacy-shield.svg';
import { useRTL } from '../../LanguageContext';
const Title = () => {
  const { isRTL } = useRTL();
  return (
    <SectionWrapper isRTL={isRTL}>
      <div className='text'>
        <h1>{isRTL ?  `عرض شروط الدانه القانونية أقرب` : `View Aldana's legal terms closer`}</h1>
      </div>
      <div className='image'>
        <img src={privacy_shield} alt='' />
      </div>
    </SectionWrapper>
  );
};

export default Title;

const SectionWrapper = styled.section`
  display: flex;
  padding: 2rem 0 0 8rem;

  @media (max-width: 680px) {
    padding: 1rem 0 0 0rem;
    flex-flow: column;
  }

  > div.text {
    flex: 2 0 0;
    padding: 0 7rem 0 0;

    @media (max-width: 680px) {
      padding: 0 0rem 0 0;
    }
  }

  > div.text > h1 {
    display: flex;
    margin: 0 8rem 0 0;
    font-family: 'Cairo-Bold';
    font-size: 4.5rem;
    text-align:${(prop) => (prop.isRTL ? 'right' : 'left')};

    @media (max-width: 1800px) {
      font-size: 4rem;
    }

    @media (max-width: 1600px) {
      font-size: 3.4rem;
    }

    @media (max-width: 1400px) {
      font-size: 3rem;
    }

    @media (max-width: 1300px) {
      font-size: 2.4rem;
    }

    @media (max-width: 680px) {
      font-size: 1.8rem;
      margin: 0 0 0 0;
      text-align: center;
    }
  }

  > div.text > div.subtext {
    padding: 3rem 0 0 0rem;

    @media (max-width: 680px) {
      padding: 1rem 0rem 1rem;
      text-align: center;
    }
  }

  > div.image {
    width: 100vw;
    display: flex;
    justify-content: center;
    flex: 1 0 0;
    align-items: center;
    /* margin: 0 6rem 0 1rem; */

    > img {
      @media (max-width: 1800px) {
        width: 260px;
      }

      @media (max-width: 1600px) {
        width: 230px;
      }

      @media (max-width: 1400px) {
        width: 210px;
      }

      @media (max-width: 1300px) {
        width: 190px;
      }

      @media (max-width: 680px) {
        width: 140px;
      }
    }
  }
`;
