export const FACTORY_DATA = {
    en: [
      { title: 'Our Vision' },
      { text1: 'Our first goal is for our country to be a successful and pioneering model in the world at all levels, And we will work with you to achieve vision 2030.'
      },
      { text2: 'Al Dana Aluminum Composite Panel WLL '
      },
      { text3: 'is indebted to everyone who supported us from day one of our operations since incorporation Our priority is not mere to sale of our services, it is much beyond, and we desire every single client’s absolute satisfaction of our services.'
      },
      { text4: '“OUR PHILOSOPHY IS UN MATCHED QUALITY DELIVERABLES”'
      },
    ],
    ar: [
        { title: 'رؤيتنا' },
        { text1: '2030 الرؤية الوطنية لدولة قطر الوطنية '
        },
        { text2: 'هدفنا الأول هو أن تكون بلادنا نموذجاً ناجحاً ورائداً في العالم على جميع المستويات، وسنعمل معكم لتحقيق رؤية 2030'
        },
        { text3: 'شركة الدانة لألواح الألومنيوم المركب ذ. م. م. مدينة لكل من دعمها منذ اليوم الأول لانطلاق عملها. أولويتنا ليست مجرد بيع خدماتنا، بل هي أبعد من ذلك بكثير، ونرغب في الرضا المطلق لكل عميل عن خدماتنا'
        },
        { text4: '"فلسفتنا هي منجزات الجودة التي لا مثيل لها"'
        },
    
      ],
    }