import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import DemoBanner from '../home/DemoBanner';
import HeroBar from './HeroBar';
import Content from './Content';
import Quotation from '../shared/Quotation';

const Career = () => {
  return (
    <BrowserRouter>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Careers</title>
        <link rel='canonical' href='http://mysite.com/example' />
      </Helmet>
      <HeroBar />
      <Content />
      <DemoBanner />
      <Quotation />
    </BrowserRouter>
  );
};

export default Career;
