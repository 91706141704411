import React from 'react';
import _ from 'underscore';
import { bannerFeatures } from './data';
import styled from 'styled-components';
import { useRTL } from '../../LanguageContext';
import aldana_unique_background from '../../assets/img/aldana-unique-bg.png';

const WhatMakesUsUnique = () => {
  const { isRTL, language } = useRTL();

  return (
    <SectionWrapper isrtl={isRTL}>
      <div className='title'>
        <h1>
          {_.find(bannerFeatures, (value, key) => key === language).title}
        </h1>
      </div>

      <Cards>
        {_.find(bannerFeatures, (value, key) => key === language).features.map(
          (item, key) => (
            <Card key={key}>
              <Dot>
                {' '}
                <img src={item.image} alt='icons' />{' '}
              </Dot>
              <div className='descr'>
                <div className='text'>{item.title}</div>
              </div>
            </Card>
          )
        )}
      </Cards>
    </SectionWrapper>
  );
};

export default WhatMakesUsUnique;

const SectionWrapper = styled.section`
  display: flex;
  /* border: 1px solid black; */
  align-items: center;
  padding: 1rem 0 1rem;
  direction: ${(prop) => (prop.isrtl ? 'rtl' : 'ltr')};
  //background: url(${aldana_unique_background});
  background-attachment: fixed;

  @media (max-width: 1024px) {
    flex-flow: column;
    padding: 1rem 0 3rem;
  }

  > div.title {
    justify-content: ${(prop) => (prop.isRTL ? 'center' : 'flex-end')};
    //display: flex;
    // flex: 0.7 0 0;
    /* border: 1px solid black; */
    margin: ${(prop) => (prop.isRTL ? '0rem 3rem 4 0rem ' : '0 0 0 5.8rem')};
    width: 40%;

    @media (max-width: 1024px) {
      margin: ${(prop) => (prop.isRTL ? '0rem 0rem 0 0rem ' : '0 0 0 2rem')};
      width: 90%;
      justify-content: center;
    }

    > h1 {
      font-family: 'Cairo-Bold';
      text-align: ${(prop) => (prop.isRTL ? 'right' : 'left')};
      color: black;
      font-size: 4.5rem;
      max-width: 80%;

      /* border: 1px solid black; */

      @media (max-width: 1800px) {
        font-size: 4rem;
        line-height: 3rem;
      }

      @media (max-width: 1600px) {
        font-size: 3.5rem;
      }

      @media (max-width: 1400px) {
        font-size: 3.3rem;
      }

      @media (max-width: 1300px) {
        font-size: 2.8rem;
        text-align: left;
        line-height: 3rem;
      }

      @media (max-width: 680px) {
        font-size: 2.3rem;
      }
    }
  }
`;

const Cards = styled.div`
  display: flex;
  flex: 2.3 0 0;
  flex-wrap: wrap;
  /* border: 1px solid black; */

  @media (max-width: 1024px) {
    flex-flow: column;
  }
`;

const Card = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 49%;
  padding-top: 10px;
  /* border: 1px solid black; */
  padding: 3rem 0 3rem;

  @media (max-width: 1024px) {
    padding: 0rem 0 1rem;
    //flex-flow: column;
    width: 80%;
    align-items: center;
    //margin-right: 3rem;
  }

  > div.descr {
    display: flex;
    flex-flow: column;
    justify-content: center;
    /* border: 1px solid black; */
    //max-width: 64%;
    color: black;
    font-size: 1.5rem;
    width: 70%;
    font-family: 'Cairo-Bold';

    @media (max-width: 1800px) {
      font-size: 1.4rem;
    }

    @media (max-width: 1600px) {
      font-size: 1.3rem;
    }

    @media (max-width: 1024px) {
      font-family: 'Cairo-Bold';
      font-size: 1.3rem;
      margin-left: 20px;
      line-height: 2.5rem;

      width: 100%;
    }
  }

  > div > p {
    color: black;
    text-align: left;
    font-size: 1rem;
    margin: 0;
    /* border: 1px solid black; */
    color: #1d2d5b;
    padding: 10px 0;
    font-family: 'Cairo-Medium';
    line-height: 1.5;

    @media (max-width: 1800px) {
      font-size: 0.9rem;
    }

    @media (max-width: 1400px) {
      font-size: 0.9rem;
    }

    @media (max-width: 1024px) {
      text-align: center;
      font-size: 0.9rem;
    }
  }
`;

const Dot = styled.div`
  height: 5rem;
  width: 5rem;
  background: var(--dana-color);
  border-radius: 50%;
  display: flex;
  transition: all 0.5s;

  @media (max-width: 1400px) {
    height: 4rem;
    width: 4rem;
  }

  @media (max-width: 1024px) {
    text-align: center;
    margin-left: 2rem;
    margin-bottom: 0.1rem;
    height: 45px;
    width: 63px;
  }

  &:hover {
    transform: scale(1.3);
    transition: all 0.5s;
    background-color: #1d2d5b;
  }

  > img {
    height: 3rem;
    width: 3rem;
    margin: auto;

    @media (max-width: 1400px) {
      height: 30px;
      width: 30px;
    }
  }
`;
