import React from 'react';
import _ from 'underscore';
import styled from 'styled-components';
import bg from '../../assets/img/about-us-bg.jpg';
import LetsGo from '../../assets/svg/lets-go.svg';
import NormalButton from '../shared/buttons/NormalButton';
import { AboutUsContent, buttonText } from './data';
import { useRTL } from '../../LanguageContext';
import { NavLink } from 'react-router-dom';
const About = ({ bloop }) => {
  const { isRTL, language } = useRTL();

  const content = _.find(AboutUsContent, (value, key) => key === language);

  return (
    <SectionContainer isRTL={isRTL}>
      <div>
        <LeftContainer isRTL={isRTL} style={{ display: bloop }}>
          <div className='title'>{content.title}</div>
          <p>{content.descr_one}</p>
          <p>{content.descr_two}</p>
          <NormalButton onClick={event =>  window.location.href='/about'} >
            {_.find(buttonText, (value, key) => key === language).knowMore}{' '}
            <span>
              <img src={LetsGo} alt='lets go' width='20' height='20' />
            </span>
          </NormalButton>
        </LeftContainer>
      </div>
    </SectionContainer>
  );
};

export default About;

const SectionContainer = styled.section`
  direction: ${(prop) => (prop.isRTL ? 'rtl' : 'ltr')};
`;
const Link = styled(NavLink)`
  text-decoration: none;
  color: white;
  font-family: 'Cairo-Bold';
  font-size: 1rem;
  padding: 5px 0 5px;
  opacity: 0.8;

  @media (max-width: 1800px) {
    font-size: 0.9rem;
  }

  @media (max-width: 1600px) {
    font-size: 0.9rem;
  }

  @media (max-width: 1400px) {
    font-size: 0.9rem;
  }

  @media (max-width: 1300px) {
    font-size: 0.8rem;
  }

  @media (max-width: 1024px) {
    text-align: center;
    font-size: 0.9rem;
  }
`;
const LeftContainer = styled.div`
  position: relative;
  color: #ffffff;
  background-image: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.92) 0%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    url(${bg});
  background-position: 30% 60%;
  background-size: contain;
  background-attachment: fixed;

  @media (max-width: 1080px) {
    background-size: cover;
  }

  > div.title {
    margin-top: -10px;
    font-size: 4.5rem;
    font-family: 'Cairo-Bold';

    @media (max-width: 1800px) {
      font-size: 4rem;
    }

    @media (max-width: 1600px) {
      font-size: 3.4rem;
    }

    @media (max-width: 1400px) {
      font-size: 2.8rem;
    }

    @media (max-width: 1300px) {
      font-size: 2.4rem;
    }

    @media (max-width: 1300px) {
      font-size: 2.4rem;
    }

    @media (max-width: 680px) {
      font-size: 1.8rem;
    }
  }

  > p {
    /* z-index: 1000; */
    font-family: 'Cairo-Regular';
    font-size: 1.25rem;
    max-width: 60%;
    line-height: 2;

    @media (max-width: 1800px) {
      font-size: 1.1rem;
    }

    @media (max-width: 1600px) {
      font-size: 1rem;
    }

    
    @media (max-width: 1300px) {
      font-size: 1rem;
    }

    @media (max-width: 580px) {
      max-width: 100%;
      font-size: 1rem;
    }
  }

  > button {
    margin-top: 2rem;
    /* max-width: 200px; */

    :hover {
      opacity: 0.8;
    }
  }

  > button > span {
    margin: ${(prop) => (prop.isRTL ? '0 5px 0 0' : '0 0 0 5px')};
  }

  > button > span > img {
    transform: ${(prop) => (prop.isRTL ? 'scaleX(-1)' : '')};
    vertical-align: middle;
  }

  @media (min-width: 200px) {
    padding: 3rem 2rem;

    > p {
      width: 100%;
    }
  }

  @media (min-width: 668px) {
    padding: 4rem 5rem 3rem 6rem;
  }
`;
