import _ from 'underscore';
import React, { useState } from 'react';
import styled from 'styled-components';
import { PANEL_COLORS, PANEL_COLORS_TITLE } from '../shared/data';
import NormalButton from '../shared/buttons/NormalButton';
import { useRTL } from '../../LanguageContext';
import LetsGo from '../../assets/svg/lets-go.svg';
import panels from '../../assets/img/panels-bg.jpg';
import { buttonText } from './data';

const LIMIT = 3;
const initialLimit = 6;
const PanelDisplayBanner = () => {
  const { isRTL, language } = useRTL();
  const [active, setActive] = useState('Metallic');
  const [items, setItems] = useState(PANEL_COLORS.Metallic.items);

  const [showMore, setShowMore] = useState(PANEL_COLORS.Metallic.loadMore);
  const [list, setList] = useState(items.slice(0, initialLimit));
  const [index, setIndex] = useState(initialLimit);

  const toggleTab = ({ loadMore, key, items }) => {
    setActive(key);
    setItems(items);
    setIndex(initialLimit);
    setList(items.slice(0, initialLimit));
    setShowMore(loadMore);
  };

  const loadMore = () => {
    const LENGTH = items.length;
    const newIndex = index + LIMIT;
    const newShowMore = newIndex < LENGTH - 1;
    const newList = list.concat(items.slice(index, newIndex));
    setIndex(newIndex);
    setList(newList);
    setShowMore(newShowMore);
  };

  return (
    <SectionWrapper>
      <Container isRTL={isRTL}>
        <h1>{_.find(PANEL_COLORS_TITLE, (value, key) => key === language)}</h1>

        <div className='item-bar'>
          {/* 'value' is the right side of the colon, 'key' is the left side of the colon */}
          {_.map(PANEL_COLORS, (value, key) => (
            <Button
              key={key}
              active={active}
              className={value.key === active ? '' : 'inactive'}
              onClick={() => toggleTab(value)}
            >
              {value.key}
            </Button>
          ))}
        </div>

        <div className='display-bar'>
          {_.map(list, (item, key) => (
            <Card key={key} isRTL={isRTL}>
              <img src={item.img} alt={item.title} />
              <div>
                <div>{item.title}</div>
                <div>{item.code}</div>
              </div>
            </Card>
          ))}
        </div>

        <div className='load-more-button'>
          {showMore && (
            <NormalButton onClick={loadMore}>
              {_.find(buttonText, (value, key) => key === language).loadMore}{' '}
              <span>
                <img src={LetsGo} alt='lets-go' width='20' height='20' />
              </span>
            </NormalButton>
          )}
        </div>
      </Container>
    </SectionWrapper>
  );
};

export default PanelDisplayBanner;

const SectionWrapper = styled.section`
  display: flex;
  justify-content: center;
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.9) 0%,
      rgba(0, 0, 0, 0.1) 100%
    ),
    url(${panels});
`;

const Container = styled.div`
  width: 85%;
  display: flex;
  flex-flow: column;
  direction: ${(prop) => (prop.isRTL ? 'rtl' : 'ltr')};

  > h1 {
    font-family: 'Cairo-Bold';
    font-size: 4.5rem;
    font-weight: 400;
    color: white;
    text-align: center;

    @media (max-width: 1800px) {
      font-size: 4rem;
    }

    @media (max-width: 1600px) {
      font-size: 3.4rem;
    }

    @media (max-width: 1400px) {
      font-size: 2.8rem;
    }

    @media (max-width: 1300px) {
      font-size: 2.4rem;
    }

    @media (max-width: 1024px) {
      font-size: 1.8rem;
    }
  }

  > div.download-section {
    /* border: 1px solid white; */
    color: white;
    font-family: 'Cairo-Medium';
    font-size: 1.25rem;
    margin: -1.5rem 0 2rem;

    @media (max-width: 1800px) {
      font-size: 1.1rem;
    }

    @media (max-width: 1600px) {
      font-size: 1rem;
    }

    @media (max-width: 1500px) {
      font-size: 0.9rem;
    }

    @media (max-width: 1400px) {
      font-size: 0.9rem;
    }

    @media (max-width: 1300px) {
      font-size: 0.9rem;
    }

    @media (max-width: 1024px) {
      width: 100%;
    }

    > div.container {
      display: flex;
      align-items: center;
      justify-content: center;

      > div.text-wrapper {
        transition: all 0.5s;

        > div {
          padding: 0 1rem 0;
          cursor: pointer;
        }
        &:hover {
          transition: all 0.5s;
          cursor: pointer;
          opacity: 0.8;
        }
      }
    }
  }

  > div.load-more-button {
    display: flex;
    justify-content: center;
    padding: 0 0 2rem 0;

    > button {
      display: flex;
      justify-content: center;
      margin: 0 0 1rem;

      > span > img {
        padding: 0 5px 0;
        vertical-align: middle;
        transform: ${(prop) => (prop.isRTL ? 'scaleX(-1)' : '')};
        /* border: 1px solid black; */
      }
    }
  }

  > div.item-bar {
    display: flex;
    justify-content: ${(prop) => (prop.isRTL ? 'right' : 'left')};
    margin-top: 1rem;

    @media (max-width: 1024px) {
      justify-content: space-between;
      flex-wrap: wrap;
    }

    > button {
      font-size: 1.2rem;

      @media (max-width: 1800px) {
        font-size: 1.1rem;
      }

      @media (max-width: 1600px) {
        font-size: 1rem;
      }

      @media (max-width: 1400px) {
        font-size: 0.9rem;
      }

      @media (max-width: 1300px) {
        font-size: 0.9rem;
      }
    }

    > button:focus {
      outline: none;
    }

    > button.inactive {
      background: transparent !important;
      border: none;
    }

    > button.active {
      background: var(--aldana-color) !important;

      border: none;
    }
  }

  > div.display-bar {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 50px;

    @media (max-width: 1024px) {
      justify-content: space-evenly;
    }
  }
`;

const Card = styled.div`
  background-color: white;
  border-radius: 13px;
  overflow: hidden;
  border: none;
  padding: 0 0 10px;
  margin: 20px 0rem 20px;

  > img {
    border-radius: 10px;
    width: 500px;
    margin: 0 0 10px;

    @media (max-width: 1800px) {
      width: 450px;
    }

    @media (max-width: 1600px) {
      width: 400px;
    }

    @media (max-width: 1400px) {
      width: 350px;
    }

    @media (max-width: 1300px) {
      width: 300px;
    }

    @media (max-width: 465px) {
      height: 150px;
    }
  }

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  > div > div {
    position: relative;
    padding: 0px 10px 0px;
    font-size: 1.5rem;

    @media (max-width: 1800px) {
      font-size: 1.3rem;
    }

    @media (max-width: 1600px) {
      font-size: 1.1rem;
    }

    @media (max-width: 1400px) {
      font-size: 1rem;
    }

    @media (max-width: 1300px) {
      font-size: 0.9rem;
    }

    /* @media (max-width: 1024px) {
      font-size: 1rem;
    }
    @media (max-width: 465px) {
      font-size: 1.8vh;
    } */
  }
`;

const Button = styled.button`
  font-family: 'Cairo-SemiBold';
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 2px solid var(--dana-color);
  background: var(--dana-color);
  background-size: 200% 100%;
  //transition: all 1s ease;
  padding: 18px 20px;
  color: white;
  border: none;
  border-radius: 10px;
  text-decoration: none;
  font-size: 1.1rem;

  @media (max-width: 1800px) {
    font-size: 1rem;
    padding: 16px 20px;
  }

  @media (max-width: 1600px) {
    font-size: 0.9rem;
    padding: 14px 20px;
  }

  @media (max-width: 1400px) {
    font-size: 0.9rem;
    padding: 12px 20px;
  }

  @media (max-width: 1300px) {
    font-size: 0.9rem;
    padding: 10px 20px;
  }

  &:focus {
    outline: none;
    background: var(--dana-color);
  }

  &:hover {
    background-position: right bottom;
    font-family: 'Cairo-Bold';
    //transition: all 1s ease;
  }
`;
