import Home from './modules/home/Home';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import Header from './modules/shared/Header';
import Footer from './modules/shared/Footer';
import { LanguageProvider } from './LanguageContext';
import AboutUs from './modules/aboutUs/AboutUs';
import CookiePolicy from './modules/cookiePolicy/CookiePolicy';
import PrivacyPolicy from './modules/privacyPolicy/PrivacyPolicy';
import OurFactory from './modules/ourFactory/OurFactory';
import Career from './modules/career/Career';
import OurProjects from './modules/ourProjects/OurProjects';
import WhyUs from './modules/whyUs/WhyUs';
import OurProducts from './modules/ourProducts/OurProducts';
import ScrollToTop from "./ScrollToTop";
function App() {
  return (
    <div className='App'>
      <LanguageProvider>
        <Router>
          <ScrollToTop>
          <Header />
          <Switch>
            <Route path='/' exact component={Home} />
            <Route path='/about' component={AboutUs} />
            <Route path='/cookie-policy' component={CookiePolicy} />
            <Route path='/privacy-policy' component={PrivacyPolicy} />
            <Route path='/factory' component={OurFactory} />
            <Route path='/careers' component={Career} />
            <Route path='/projects' component={OurProjects} />
            <Route path='/why-us' component={WhyUs} />
            <Route path='/products' component={OurProducts} />
          </Switch>
          <Footer />
          </ScrollToTop>
        </Router>
      </LanguageProvider>
    </div>
  );
}

export default App;
