import React from 'react';
import styled from 'styled-components';
import about_us_bg from '../../assets/img/about-us-bg-min.jpg';
import { useRTL } from '../../LanguageContext';
const HeroBar = () => {
  const { isRTL } = useRTL();
  return (
    <SectionWrapper isRTL={isRTL}>
      <div className='title'>{isRTL? 'نحن نفخر بتقديم أنفسنا كشركة مصنعة رائدة لألواح اي سي بي في دولة قطر':
      'We take utmost pride in introducing ourselves as the leading manufacturers of ACP sheets in State of Qatar'}
      </div>
    </SectionWrapper>
  );
};

export default HeroBar;

const SectionWrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 72vh;
  background: linear-gradient(135deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 90%),
    url(${about_us_bg});
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;

  @media (max-width: 1024px) {
    height: 40vh;
    background-size: contain;
    background-position: top;
  }

  @media (max-width: 680px) {
    height: 50vh;
    background: linear-gradient(135deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 70%),
    url(${about_us_bg});
    background-size: 270%;
    background-position: center top;
  }

  > div.title {
    font-family: 'Cairo-Bold';
    color: white;
    width: 90%;
   
    font-size: 5rem;
    text-align: ${(prop) => (prop.isRTL ? 'right' : 'left')};

    @media (max-width: 1800px) {
      font-size: 3.6rem;
    }

    @media (max-width: 1600px) {
      font-size: 3.3rem;
    }

    @media (max-width: 1500px) {
      font-size: 3rem;
    }

    @media (max-width: 1300px) {
      font-size: 2.8rem;
    }

    @media (max-width: 1024px) {
      text-align: ${(prop) => (prop.isRTL ? 'right' : 'left')};
      margin: ${(prop) => (prop.isRTL ? '0rem 1rem 0 0rem ' : '0 0 0 2rem')};
      font-size: 2rem;
    }

    @media (max-width: 680px) {
      width: 90%;
      font-size: 1.6rem;
    }
  }
`;
