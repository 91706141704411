import prequalification from '../../assets/img/prequalification-april-2021.png';
import process from '../../assets/img/process-tech.png';
import speech_bubble from '../../assets/svg/speech-bubble.svg';
import graph from '../../assets/svg/graph.svg';

import Firewall from '../../assets/svg/firewall.svg';
import Regulus from '../../assets/svg/regulus.svg';
import Tree from '../../assets/svg/tree.svg';
import Volunteer from '../../assets/svg/volunteer.svg';
import Eclipse from '../../assets/svg/eclipse.svg';

import Quality from '../../assets/svg/quality.svg';
import Stamp from '../../assets/svg/stamp.svg';
import PlanetEarth from '../../assets/svg/planet-earth.svg';
import Qatar from '../../assets/svg/qatar.svg';

import hexagonal from '../../assets/svg/hexagonal-structure.svg';
import infection from '../../assets/svg/infection.svg';

export const aboutUsFeatures = {
  en: {
    block1: {
      title: 'Aluminium composite panel',
      descr: `Aluminum composite panel is a composite panel consisting of two aluminum cover sheets and a plastic core. The superb properties of this material boost one's inspiration and offer architecture a whole new range of solutions whether your project is a residential or public building, a corporate head quarter, an office building, a trading or industrial complex-or if your organisation wants to create a new image-building Corporate Design-whether for petrol stations, car showrooms, banks or supermarkets.`,
    },
    block2: {
      img: prequalification,
    },
    block3: {
      img: speech_bubble,
      title: 'Aldana ACP Applications',
      items: [
        { item: 'Wall Curtain, Cladding And Facade' },
        { item: 'Roof Edges And Parapet Wall' },
        { item: 'Dado, Separation Wall And Partition' },
        { item: 'Interior Wall, Ceiling, Bathroom, Kitchen And Balcony' },
        { item: 'Advertisement Boards, Display Platforms And Signboards' },
        { item: 'Column Covers And Beam Wraps' },
        { item: 'Industrial Materials, Vehicle And Boat Materials' },
      ],
    },
    block4: {
      img: graph,
      title: 'Aldana ACP Features',
      image: process,
    },
    block5: {
      img: hexagonal,
      title: 'Specifications',
      items1: [
        { item: 'PANEL THICKNESS', subitem: '3mm, 4mm, 5mm, 6mm' },
        {
          item: 'PANEL WIDTH',
          subitem: '1220 & 1250 AND AVAILABLE UP TO 1500mm',
        },
        {
          item: 'PANEL LENGTH',
          subitem: '2440 & 3200 AND AVAILABLE UP TO 6000mm',
        },
        {
          item: 'SURFACE COATING',
          subitem: '2440 & 3200 AND AVAILABLE UP TO 6000mm',
        },
        {
          item: 'OTHER SURFACE',
          subitem: 'BOTH SIDES COIL COATED LACQUER; ONE SIDE ANODIZED',
        },
        {
          item: 'ALUMINIUM ALLOY',
          subitem: 'AA 1100, AA 3003, AA 3015, AA 5005',
        },
      ],
    },
    block6: {
      img: infection,
      title: 'Dimensional Tolerances',
      items1: [
        { item: 'PANEL THICKNESS', subitem: '+- 0.2mm' },
        { item: 'PANEL WIDTH', subitem: '+- 2mm' },
        { item: 'PANEL LENGTH', subitem: '+- 3mm' },
        { item: 'PANEL BOW', subitem: '0.5%' },
        { item: 'SKIN THICKNESS', subitem: '+- 0.02mm' },
      ],
    },
  },
  ar: {
    block1: {
      title: 'لوح الألمنيوم المركب',
      descr:
        'لكن لا بد أن أوضح لك أن كل هذه الأفكار المغلوطة حول استنكار  النشوة وتمجيد الألم نشأت بالفعل، وسأعرض لك التفاصيل لتكتشف حقيقة وأساس تلك السعادة البشرية، فلا أحد يرفض أو يكره أو يتجنب الشعور بالسعادة، ولكن بفضل هؤلاء الأشخاص الذين لا يدركون بأن السعادة لا بد أن نستشعرها بصورة أكثر عقلانية ومنطقية فيعرضهم هذا لمواجهة الظروف الأليمة، وأكرر بأنه لا يوجد من يرغب في الحب ونيل المنال ويتلذذ بالآلام، الألم هو الألم ولكن نتيجة لظروف ما قد تكمن السعاده فيما نتحمله من كد وأسي.',
    },
    block2: {
      img: prequalification,
    },
    block3: {
      img: speech_bubble,
      title: 'تطبيقات الدانه ACP',
      items: [
        { item: 'ستارة حائط وكسوة وواجهات' },
        { item: 'حواف السقف وجدار الحاجز' },
        { item: 'دادو ، جدار الفصل والتقسيم' },
        { item: 'جدار داخلي ، سقف ، حمام ، مطبخ وشرفة' },
        { item: 'اللوحات الإعلانية ومنصات العرض واللوحات الإعلانية' },
        { item: 'أغطية العمود وأغطية الشعاع' },
        { item: 'المواد الصناعية ومواد المركبات والقوارب' },
      ],
    },
    block4: {
      img: graph,
      title: 'ميزات Aldana ACP',
      image: process,
    },
    block5: {
      img: hexagonal,
      title: 'المواصفات',
      items1: [
        { item: 'سماكة اللوح', subitem: '3مم' },
        {
          item: 'عرض اللوح',
          subitem: '؛220 & 1250 ومتوفرة حتى 1500 مم',
        },
        {
          item: ' طول اللوح',
          subitem: '؛2440 & 3200 ومتوفرة حتى 6000 مم',
        },
        {
          item: 'طلاء السطح',
          subitem: '؛2440 & 3200 ومتوفرة حتى 6000 مم',
        },
        {
          item: 'سطح آخر',
          subitem: ' طلاء لكر لكلا جانبي اللفات؛ و جانب واحد مؤكسد',
        },
        {
          item: 'سبائك الألومنيوم',
          subitem: 'AA 1100, AA 3003, AA 3015, AA 5005',
        },
      ],
    },
    block6: {
      img: infection,
      title: 'التسامح',
      items1: [
        { item: ' سماكة', subitem: '+- 0.2مم' },
        { item: 'العرض ', subitem: '+- 2مم' },
        { item: 'الطول', subitem: '+- 3مم' },
        { item: 'انحناء اللوح', subitem: '0.5%' },
        { item: 'التربيع', subitem: '5مم' },
      ],
    },
  },
};

export const whyDanaFeatures = [
  {
    image: Eclipse,
    title: 'Quality Manufacturing',
    descr:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis dapibus molestie elit et venenatis. Integer sit amet purus',
  },
  {
    image: Regulus,
    title: 'Expert Team',
    descr:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis dapibus molestie elit et venenatis. Integer sit amet purus',
  },
  {
    image: Firewall,
    title: 'Fire Retardant ACPs',
    descr:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis dapibus molestie elit et venenatis. Integer sit amet purus',
  },
  {
    image: Eclipse,
    title: 'Weather resistance ACPs',
    descr:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis dapibus molestie elit et venenatis. Integer sit amet purus',
  },
  {
    image: Tree,
    title: 'Environment friendly process',
    descr:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis dapibus molestie elit et venenatis. Integer sit amet purus',
  },
  {
    image: Volunteer,
    title: 'Great customer support',
    descr:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis dapibus molestie elit et venenatis. Integer sit amet purus',
  },
];

export const bannerFeatures = {
  en: {
    title: 'Why Aldana is unique?',
    features: [
      {
        image: Qatar,
        title: 'Locally produced in Qatar',
      },
      {
        image: PlanetEarth,
        title: 'Wide distribution network',
      },
      {
        image: Stamp,
        title: 'Civil Defence approval',
      },
      {
        image: Quality,
        title: 'Quality certificate from ministry',
      },
    ],
  },
  ar: {
    title: 'لماذا تعتبر الدانة فريدة من نوعها؟',
    features: [
      {
        image: Qatar,
        title: 'منتج محليا في قطر',
        text: 'تخضع منتجاتنا من ACPs لسلسلة من الاختبارات لضمان المتانة في أي طقس وفي أي موسم. كما نقدم ضمانًا لعدة سنوات على منتجاتنا.',
      },
      {
        image: PlanetEarth,
        title: 'شبكة توزيع واسعة',
        text: 'تخضع منتجاتنا من ACPs لسلسلة من الاختبارات لضمان المتانة في أي طقس وفي أي موسم. كما نقدم ضمانًا لعدة سنوات على منتجاتنا.',
      },
      {
        image: Stamp,
        title: 'موافقة الدفاع المدني',
        text: 'تخضع منتجاتنا من ACPs لسلسلة من الاختبارات لضمان المتانة في أي طقس وفي أي موسم. كما نقدم ضمانًا لعدة سنوات على منتجاتنا.',
      },
      {
        image: Quality,
        title: 'شهادة الجودة من الوزارة',
        text: 'تخضع منتجاتنا من ACPs لسلسلة من الاختبارات لضمان المتانة في أي طقس وفي أي موسم. كما نقدم ضمانًا لعدة سنوات على منتجاتنا.',
      },
    ],
  },
};

export const content = {
  en: {
    title: `Lorem ipsum dolor sit amet, consectetuer.`,
    descr: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.`,
  },
  ar: {
    title: `ها بصورة أكثر عقلانية ومنطقية فيعرضهم هذا لمواجهة الظروف الأليمة، وأكرر بأنه لمن`,
    descr: `لكن لا بد أن أوضح لك أن كل هذه الأفكار المغلوطة حول استنكار  النشوة وتمجيد الألم نشأت بالفعل، وسأعرض لك التفاصيل لتكتشف حقيقة وأساس تلك السعادة البشرية، فلا أحد يرفض أو يكره أو يتجنب الشعور بالسعادة، ولكن بفضل هؤلاء الأشخاص الذين لا يدركون بأن السعادة لا بد`,
    descrLight: `تنكر هؤلاء الرجال المفتونون بنشوة اللحظة الهائمون في.`,
  },
};

export const AboutUsContent = {
  en: {
    title: `About Us`,
    descr_one: `AL DANA BOND welcomes you to the world of Aluminium Composite Panels (ACP) where phenomenal quality meets incomparable durability. We take utmost pride in introducing ourselves as the leading manufacturers of ACP sheets in State of Qatar. 
    Since inception, AL DANA BOND has grown by leaps and bounds and is the front runner of the market. Products are feast for your eyes and will dissipate you in your wishful dream décor spaces. 
    `,
    descr_two: `The Motto of AL DANA BOND is to attain perfection, therefore with a view to achieve the desired goal AL DANA BOND have set up a world class innovative manufacturing unit, with state-of-the art fully computerized and digitized with advanced level of super wide, continuous and high-speed production lines.
    There is no gainsaying the fact that our Vision is to deliver the AL DANA BOND ACP which is the symbol of excellence in terms of utility and appearance as to maintain cordial relations with clients and staff, thus adhering to work ethics.
    `,
  },
  ar: {
    title: `معلومات عنا`,
    descr_one: `علي الجانب الآخر نشجب ونستنكر هؤلاء الرجال المفتونون بنشوة اللحظة الهائمون في رغباتهم فلا يدركون ما يعقبها من الألم والأسي المحتم، واللوم كذلك يشمل هؤلاء الذين أخفقوا في واجباتهم نتيجة لضعف إرادتهم فيتساوي مع هؤلاء الذين يتجنبون وينأون عن تحمل الكدح والألم . من المفترض أن نفرق بين هذه الحالات بكل سهولة ومرونة. في ذاك الوقت عندما تكون قدرتنا علي الاختيار غير مقيدة بشرط وعندما لا نجد ما يمنعنا أن نفعل الأفضل فها نحن نرحب بالسرور والسعادة ونتجنب كل ما يبعث إلينا الألم. في بعض الأحيان ونظراً للالتزامات التي يفرضها علينا الواجب والعمل سنتنازل غالباً ونرفض الشعور بالسرور ونقبل ما يجلبه إلينا الأسى. الإنسان الحكيم عليه أن يمسك زمام الأمور ويختار إما أن يرفض مصادر السعادة من أجل ما هو أكثر أهمية أو يتحمل الألم من أجل ألا يتحمل ما هو أسوأ. `,
    descr_two: `علي الجانب الآخر نشجب ونستنكر هؤلاء الرجال المفتونون بنشوة اللحظة الهائمون في رغباتهم فلا يدركون ما يعقبها من الألم والأسي المحتم، واللوم كذلك يشمل هؤلاء الذين أخفقوا في واجباتهم نتيجة لضعف إرادتهم فيتساوي مع هؤلاء الذين يتجنبون وينأون عن تحمل الكدح والألم . من المفترض أن نفرق بين هذه الحالات بكل سهولة ومرونة. في ذاك الوقت عندما تكون قدرتنا علي الاختيار غير مقيدة بشرط وعندما لا نجد ما يمنعنا أن نفعل الأفضل فها نحن نرحب بالسرور والسعادة ونتجنب كل ما يبعث إلينا الألم. في بعض الأحيان ونظراً للالتزامات التي يفرضها علينا الواجب والعمل سنتنازل غالباً ونرفض الشعور بالسرور ونقبل ما يجلبه إلينا الأسى. الإنسان الحكيم عليه أن يمسك زمام الأمور ويختار إما أن يرفض مصادر السعادة من أجل ما هو أكثر أهمية أو يتحمل الألم من أجل ألا يتحمل ما هو أسوأ. </p>`,
  },
};

export const demoBanner = {
  en: {
    text1: `We're here to help!`,
    text2: `Ask us anything, or schedule a customised demo session by sending an email to `,
  },
  ar: {
    text1: `نحن هنا للمساعدة!`,
    text2: `اسألنا أي شيء ، أو حدد موعدًا لجلسة تجريبية مخصصة عن طريق إرسال بريد إلكتروني إلى `,
  },
};

export const buttonText = {
  en: {
    knowMore: 'I want to know more',
    loadMore: 'Load more',
    talkToUs: 'Talk to us!',
    viewCerti: 'View Certificate',
  },
  ar: {
    knowMore: 'المزيد',
    loadMore: 'المزيد',
    talkToUs: 'تواصل معنا!',
  },
};
