import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const CustomList = ({ text, isRTL }) => {
  return (
    <ListWrapper isRTL={isRTL}>
      <DotWrapper isRTL={isRTL} />
      {text}
    </ListWrapper>
  );
};

CustomList.propTypes = {
  text: PropTypes.string.isRequired,
  isRTL: PropTypes.bool,
};

export default CustomList;

const ListWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 1rem 2rem;
  padding-left: 4rem;
  line-height: 1.3rem;
  position: relative;
  font-family: 'Cairo-Bold';
  font-size: 1rem;
  ${({ isRTL }) =>
    isRTL
      ? `
        margin-right: 2rem;
    `
      : `margin-left: 3px;`}

  @media (min-width: 580px) {
    font-family: 'Cairo-SemiBold';
    ${({ isRTL }) =>
      isRTL
        ? `
        margin-right: 5.3rem;
    `
        : `margin-left: 3rem`}
  }
`;

const DotWrapper = styled.div`
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: var(--dana-color);
  border-radius: 50%;
  left: ${({ isRTL }) => (isRTL ? `0` : `2.5rem`)};
  right: ${({ isRTL }) => (isRTL ? `3px` : `0`)};
  top: 25px;
`;
