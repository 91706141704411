import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import DemoBanner from '../home/DemoBanner';
import HeroBar from './HeroBar';
import Content from './Content';
import Features from './Features';
import Products from './Products';
import Quotation from '../shared/Quotation';

const OurProducts = () => {
  return (
    <BrowserRouter>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Our Factory</title>
        <link rel='canonical' href='http://mysite.com/example' />
      </Helmet>
      <HeroBar />
      <Content />
      <Features />
      <Products />
      <DemoBanner />
      <Quotation />
    </BrowserRouter>
  );
};

export default OurProducts;
