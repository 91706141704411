import React from 'react';
import styled from 'styled-components';
//import org_chart from '../../assets/img/organizational-chart.png';
import { useRTL } from '../../LanguageContext';
import _ from 'underscore';
import { WhoAreWe } from './data';
const Content = () => {
  const { language,isRTL } = useRTL();

  const whoAreWeData = _.find(WhoAreWe, (value, key) => key === language);
  return (
    <ContentWrapper isRTL={isRTL} >
      <div className='intro'>
        <div className='title'>{whoAreWeData.title}</div>
        <div className='text'>
          <p>
          {whoAreWeData.block1.descr}
          </p>{' '}
          <p>
          {whoAreWeData.block2.descr}
          </p>
          <p>
          {whoAreWeData.block3.descr}

          </p>
        </div>
      </div>

      {/* <div className='intro'>
      <div className='title'>{whoAreWeData.title}</div>
        <div className='text'>
          <p>
          {whoAreWeData.block4.descr}
          </p>{' '}
        </div>
      </div> */}

      {/* <div className='intro'>
        <div className='title'>Oraganization chart</div>
        <div className='text'>
          <span>
            <img src={org_chart} alt='organizational chart' />
          </span>
        </div>
      </div> */}
    </ContentWrapper>
  );
};

export default Content;

const ContentWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 0 5rem;
  direction: ${(prop) => (prop.isRTL ? 'rtl' : 'ltr')};

  > div {
    width: 90%;
    padding: 0 0 3rem;
  }

  > div > div.title {
    font-family: 'Cairo-Bold';
    font-size: 3rem;
    padding: 1rem 0 1rem;

    @media (max-width: 1800px) {
      font-size: 2.8rem;
    }

    @media (max-width: 1600px) {
      font-size: 2.6rem;
    }

    @media (max-width: 1400px) {
      font-size: 2.4rem;
    }

    @media (max-width: 1300px) {
      font-size: 2.2rem;
      margin: ${(prop) => (prop.isRTL ? '0rem 1rem 0 0rem ' : '0 0 0 1rem')};
    }

    @media (max-width: 680px) {
      font-size: 1.8rem;
    }
  }

  > div > div.text {
    font-family: 'Cairo-Medium';
    font-size: 1.25rem;
    text-align: justify;
    line-height: 2;

    @media (max-width: 1800px) {
      font-size: 1.15rem;
    }

    @media (max-width: 1600px) {
      font-size: 1rem;
    }

    @media (max-width: 1400px) {
      font-size: 0.9rem;
    }

    @media (max-width: 1300px) {
      font-size: 0.9rem;
      margin: ${(prop) => (prop.isRTL ? '0rem 1rem 0 0rem ' : '0 0 0 1rem')};
    }

    > span {
      display: flex;
      justify-content: center;
      align-items: center;

      > img {
        width: 100%;
      }
    }
  }
`;
