export const cookiePolicy = {
    en: {
        p1:
        {
        title:"1. Introduction",
        d1:`Aldana (“we” or “us” or “our”) may use cookies, web beacons,
        tracking pixels, and other tracking technologies when you visit our
        website [tazweedapp.com], including any other media form, media
        channel, mobile website, or mobile application related or connected
        thereto (collectively, the “Site”) to help customize the Site and
        improve your experience`,

        d2:` We reserve the right to make changes to this Cookie Policy at any
        time and for any reason. We will alert you about any changes by
        updating the “Last Updated” date of this Cookie Policy. Any changes
        or modifications will be effective immediately upon posting the
        updated Cookie Policy on the Site, and you waive the right to
        receive specific notice of each such change or modification`,

        d3:` You are encouraged to periodically review this Cookie Policy to stay
        informed of updates. You will be deemed to have been made aware of,
        will be subject to, and will be deemed to have accepted the changes
        in any revised Cookie Policy by your continued use of the Site after
        the date such revised Cookie Policy is posted.`

    
    },

        p2:
        {
          title:"2. What are cookies?",
          d1:`
           A “cookie” is a string of information which assigns you a unique
            identifier that we store on your computer. Your browser then
            provides that unique identifier to use each time you submit a query
            to the Site. We use cookies on the Site to, among other things, keep
            track of services you have used, record registration information,
            record your user preferences, keep you logged into the Site,
            facilitate purchase procedures, and track the pages you visit.
            Cookies help us understand how the Site is being used and improve
            your user experience.`
        },
        p3:
        {
          title:"3. Type of Cookies we use?",
          d1:`
          Our cookies are “first-party cookies”, and can be either permanent
            or temporary. These are necessary cookies, without which the Site
            won’t work properly or be able to provide certain features and
            functionalities. Some of these may be manually disabled in your
            browser, but may affect the functionality of the Site. They are
            listed below.`,

            d2:`
            
           
            · Google Analytics: _ga, _gid. Used to distinguish users. Expire in
            24 months. Visit https://marketingplatform.google.com/about/ for an
            overview of privacy at Google.
       
  
            · Crisp chatbot: Used to distinguish users. Expire in 6 months and
            is renewed if and when the user comes back to our website and loads
            the chatbox. Visit https://crisp.chat/en/privacy/ for an overview of
            privacy at Crisp.
        
            Please note that third parties (including, for example, advertising
            networks and providers of external services like web traffic
            analysis services) may also use cookies, over which we have no
            control. These cookies are likely to be analytical/performance
            cookies or targeting cookies.

        
            You may block cookies by activating the setting on your browser that
            allows you to refuse the setting of all or some cookies. Please find
            the instructions below to learn more:
          
              `,
              d3:`
              Internet Explorer:
              1. Click on Tools > Internet Options in the main toolbar.\n
              2. Select the Privacy tab to view your settings. \n
              3. Move the slider to the level of privacy you prefer. \n
              
                Google Chrome: Click the spanner icon in the top right-hand corner
                of the browser and select Settings from the drop-down menu.  \n
        
              1. Click on Show advanced settings at the bottom of the page.  \n
              2. Select Content Settings under the Privacy heading.  \n
              3. Manage your preferred cookies settings from this page.  \n
              Safari (Mac Only):  \n
       
                1. Click on Safari in the toolbar, then select Preferences from the
                drop-down menu.  \n
           
           
                2. Click Privacy to view cookie preferences (note: Safari blocks  
                third-party cookies by default).  \n
    
      
                The term "Do Not Track" refers to a HTTP header offered by certain
                web browsers to request that websites refrain from tracking the
                user. We take no action in response to Do Not Track requests.  \n

          
                For further information regarding cookies, please visit
                www.allaboutcookies.org.
              `

        },
       

        },
        ar:{
            p1:
            {
            title:"المقدمة",
            d1:`قد تقوم Novalabs (و ما يعود عليها من الضمائر مثل "نحن" أو "- نا" أو "لنا") باستخدام ملفات تعريف الارتباط وإشارات الويب وتتبع وحدات البكسل وتقنيات التتبع الأخرى و ذلك عند زيارة موقعنا على الويب [novalabs-qa.com]، بما في ذلك أي نوع من الوسائط الأخرى أو قناة إعلامية أو موقع ويب للجوّال أو تطبيق جوّال مرتبط أو متصل به (يُشار إليه إجمالاً باسم "الموقع") و ذلك للمساعدة في تخصيص الموقع وتحسين تجربة المستخدم الخاصة بك.`,
    
            d2:`نحتفظ بالحق في إجراء تغييرات على سياسة ملفات تعريف الارتباط هذه في أي وقت ولأي سبب. سننبهك بأي تغييرات تطرأ على سياسة ملفات تعريف الارتباط هذه بتاريخ "آخر تحديث". ستكون أي تغييرات أو تعديلات سارية فور نشر سياسة ملفات تعريف الارتباط المحدثة على الموقع، وأنت تتنازل عن الحق في تلقي إشعار محدد بكل تغيير أو تعديل من هذا القبيل.
            `,
    
            d3:` نشجعك على مراجعة سياسة ملفات تعريف الارتباط هذه بشكل دوري للبقاء على اطلاع على التحديثات. سيتم اعتبار أنك على علم بها، و خاضعاً لها، و موافق على التغييرات في أي سياسة ملفات تعريف ارتباط منقحة من خلال استمرارك في استخدام الموقع بعد تاريخ نشر سياسة ملفات تعريف الارتباط المنقحة هذه.
            `
    
        
        },
    
            p2:
            {
              title:" استخدام ملفات تعريف الارتباط",
              d1:`"ملف تعريف الارتباط" نقوم بتخصيصك بسلسلة من المعلومات التعريفية المميزة و نخزنها على جهاز الكمبيوتر الخاص بك.  حيث يوظف متصفحك بعد ذلك هذه المعلومات التعريفية المميزة لاستخدامها في كل مرة ترسل فيها استعلاماً إلى الموقع. نستخدم ملفات تعريف الارتباط على الموقع من بين أشياء أخرى لتتبع الخدمات التي استخدمتها، ولحفظ معلومات التسجيل و تفضيلات المستخدم الخاصة بك، والحفاظ على تسجيل دخولك إلى الموقع، وتسهيل إجراءات الشراء، وتتبع الصفحات التي تزورها. تساعدنا ملفات تعريف الارتباط في فهم كيفية استخدام الموقع وتحسين تجربة المستخدم الخاصة بك.
              `
            },
            p3:
            {
              title:"نوع ملفات تعريف الارتباط التي نستخدمها",
              d1:`
              ملفات تعريف الارتباط الخاصة بنا هي "ملفات تعريف ارتباط الطرف الأول"، ويمكن أن تكون إما دائمة أو مؤقتة. و هي ملفات تعريف ارتباط ضرورية و بدونها لن يعمل الموقع بشكل صحيح أو يكون قادراً على توفير ميزات ووظائف معينة. قد يتم تعطيل بعض هذه العناصر يدوياً في متصفحك، ولكنها قد تؤثر على وظائف الموقع. و هي مدرجة أدناه.
              `,
    
                d2:`
                
               
                تحليلات جوجل: _ ga، _gid.  و تستخدم لتمييز المستخدمين.  تنتهي صلاحيتها في غضون 24 شهراً. تفضل بزيارة https://marketingplatform.google.com/about/   للحصول على نظرة عامة حول الخصوصية في جوجل. 
                يستخدم Crisp chatbot لتمييز المستخدمين. تنتهي صلاحيته خلال 6 أشهر ويتم تجديدها عندما يعود المستخدم إلى موقعنا ويقوم بتحميل chatbox. تفضل بزيارة https://crisp.chat/en/privacy/ للحصول على نظرة عامة حول الخصوصية في Crisp. 
                يرجى ملاحظة أن هناك أطراف ثالثة (بما في ذلك و على سبيل المثال، شبكات الإعلان ومقدمي الخدمات الخارجية مثل خدمات تحليل حركة مرور الويب) قد تستخدم أيضاً ملفات تعريف الارتباط، و هي خارجة عن سيطرتنا.  من المحتمل أن تكون ملفات تعريف الارتباط هذه عبارة عن ملفات تعريف ارتباط تحليلية أو خاصة بالأداء أو هادفة.
                يمكنك حظر ملفات تعريف الارتباط عن طريق تفعيل الإعداد الموجود في المتصفح والذي يسمح لك برفض إعداد جميع أو بعض ملفات تعريف الارتباط. الرجاء الاطلاع على التعليمات أدناه لمعرفة المزيد:
                
              
                  `,
                  d3:` 
                  متصفح الانترنت:
                  انقر فوق أدوات>  خيارات الإنترنت في شريط الأدوات الرئيسي.
                   حدد علامة تبويب الخصوصية لعرض الإعدادات الخاصة بك.
                  حرك شريط التمرير إلى مستوى الخصوصية المفضل.
                  Google Chrome :
                  انقر على أيقونة (Spanner) في الزاوية العلوية اليمنى من المتصفح وحدد الإعدادات من القائمة المنسدلة.
                   انقر فوق إظهار الإعدادات المتقدمة في أسفل الصفحة.
                   حدد إعدادات المحتوى تحت عنوان الخصوصية.
                   قم من خلال هذه الصفحة بتحديد التفضيلات لملفات تعريف الارتباط.
                  Safari  (فقط MAC):
                    انقر فوق Safari في شريط الأدوات، ثم حدد التفضيلات من القائمة المنسدلة.
                   انقر فوق الخصوصية لعرض تفضيلات ملفات تعريف الارتباط (ملاحظة: يحظر Safari ملفات تعريف الارتباط للجهات الخارجية بشكل افتراضي).
                  يشير مصطلح "عدم التتبع" إلى عنوان  HTTP الذي توفره متصفحات ويب معينة لمطالبة مواقع الويب بالامتناع عن تتبع المستخدم. نحن لا نتخذ أي إجراء رداً على طلبات عدم التتبع.
                  لمزيد من المعلومات حول ملفات تعريف الارتباط، يرجى زيارة www.allaboutcookies.org  
                  
                  
                  `
    
            },
           

        }}
       
    

        

        
        