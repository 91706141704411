import React from 'react';
import _ from 'underscore';
import styled from 'styled-components';
import CalendlyButton from '../shared/buttons/CalendlyButton';
import calender from '../../assets/svg/baby-shower-date.svg';
import LetsGo from '../../assets/svg/lets-go.svg';
import { useRTL } from '../../LanguageContext';
import { demoBanner } from './data';

const DemoBanner = () => {
  const { isRTL, language } = useRTL();

  const content = _.find(demoBanner, (value, key) => key === language);

  return (
    <SectionWrapper isRTL={isRTL}>
      <LeftContainer isRTL={isRTL}>
        <div>
          <img src={calender} alt='calender' />
        </div>
        <div>
          <p>{content.text1}</p>
          <p>{content.text2} 
          <LinkButton onClick={() => window.location = `mailto:info@aldanabond.com`}>info@aldanabond.com</LinkButton>
          
          </p>
        
        </div>
      
        
      </LeftContainer>

      
    </SectionWrapper>
  );
};

export default DemoBanner;

const SectionWrapper = styled.section`
  display: flex;
  flex-flow: column;
  align-items: center;
  direction: ${(prop) => (prop.isRTL ? 'rtl' : 'ltr')};
  background-color: #f3f3f3;
  padding: 2rem 0 2rem;

  > button > span > img {
    width: 1.3rem;
    vertical-align: middle;
    padding: ${(prop) => (prop.isRTL ? '0px 5px 0px 0px' : '0px 0px 0px 5px')};
    transform: ${(prop) => (prop.isRTL ? 'scaleX(-1)' : '')};
  }
`;

const LeftContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 2rem 0 2rem;
  width: 87%;

  @media (max-width: 580px) {
    padding: 0;
  }

  > div {
    padding: 20px 3.5rem 0 0;
    text-align: center;

    @media (max-width: 1024px) {
      padding: 0 1rem 1rem;
    }
  }

  > div > p  {
    /* border: 1px solid black; */
    font-family: 'Cairo-Bold';
    font-size: 3rem;
    line-height: 1.8;
    margin: 0 auto;
    padding: 0 10px;
    color: #1d2d5b;
    text-align: ${(prop) => (prop.isRTL ? 'right' : 'left')};

    @media (max-width: 1800px) {
      font-size: 2.7rem;
    }

    @media (max-width: 1600px) {
      font-size: 2.4rem;
    }

    @media (max-width: 1400px) {
      font-size: 2.1rem;
    }

    @media (max-width: 1300px) {
      font-size: 1.8rem;
    }

    @media (max-width: 1024px) {
      font-size: 1.5rem;
      padding: 0;
    }

    @media (max-width: 680px) {
      font-size: 1.3rem;
      padding: 0;
    }

    @media (max-width: 440px) {
      font-size: 1rem;
      padding: 0;
    }
  }
 

  > div > img {
    width: 100%;

    @media (max-width: 1800px) {
      width: 90%;
    }

    @media (max-width: 1600px) {
      width: 80%;
    }

    @media (max-width: 1400px) {
      width: 70%;
    }

    @media (max-width: 1300px) {
      width: 60%;
    }

    @media (max-width: 1024px) {
      width: 100%;
    }
  }
`;


const LinkButton = styled.button`
  font-family: 'Cairo-Bold';
  color: #1d2d5b;
  font-size: 3rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  justify-content: flex-start;

  @media (max-width: 1800px) {
      font-size: 2.7rem;
    }

    @media (max-width: 1600px) {
      font-size: 2.4rem;
    }

    @media (max-width: 1400px) {
      font-size: 2.1rem;
    }

    @media (max-width: 1300px) {
      font-size: 1.8rem;
    }

    @media (max-width: 1024px) {
      font-size: 1.5rem;
      padding: 0;
    }

    @media (max-width: 680px) {
      font-size: 1.3rem;
      padding: 0;
    }

    @media (max-width: 440px) {
      font-size: 1rem;
      padding: 0;
    }
  &:hover{ text-decoration:underline; }


`;
