import ISO from '../../assets/img/iso-logo.png';
import plant_fire from '../../assets/img/plant-fire-logo.png';
import pollution from '../../assets/img/pollution-logo.png';
import woqod from '../../assets/img/woqod-logo.png';
import civil_defense from '../../assets/img/civil-defense-logo.png';
import iso_doc from '../../assets/docs/ISO/iso3.jpg';
import fire_plant_doc from '../../assets/docs/plant-fire.jpg';
import pollution_doc from '../../assets/docs/pollution.jpg';
import civil_doc from '../../assets/docs/civil-defense/civil defence.jpg';
import woqod_doc from '../../assets/docs/woqod/woqod.jpg';

export const Certifications_data ={
 en: [
    {
      no: 'card1',
      title: 'ISO Certification',
      img: ISO,
      doc: iso_doc,
      name: 'isoDocs',
    },
    {
      no: 'card2',
      title: 'Fire Plant Certificate',
      img: plant_fire,
      doc: fire_plant_doc,
      name: 'fireplantDoc',
    },
    {
      no: 'card3',
      title: 'Polution Certificate',
      img: pollution,
      doc: pollution_doc,
      name: 'pollutionDoc',
    },
  ],

  ar:[
    {
      no: 'card1',
      title: 'تصريح الأيزو',
      img: ISO,
      doc: iso_doc,
      name: 'isoDocs',
    },
    {
      no: 'card2',
      title: 'تصاريح نظام الوقاية و مكافحة الحريق',
      img: plant_fire,
      doc: fire_plant_doc,
      name: 'fireplantDoc',
    },
    {
      no: 'card3',
      title: 'تصريح وزارة البيئة',
      img: pollution,
      doc: pollution_doc,
      name: 'pollutionDoc',
    },

  ]
} 

export const Approvals_data = {
  en: [
  {
    no: 'card4',
    title: 'Civil Defence Approval',
    img: civil_defense,
    doc: civil_doc,
    name: 'defenseDocs',
  },
  {
    no: 'card5',
    title: 'Woqod Approval',
    img: woqod,
    doc: woqod_doc,
    name: 'woqodDocs',
  },
],
ar: [
  {
    no: 'card4',
    title: 'تصريح الردفاع المدني',
    img: civil_defense,
    doc: civil_doc,
    name: 'defenseDocs',
  },
  {
    no: 'card5',
    title: 'تصريح وقود',
    img: woqod,
    doc: woqod_doc,
    name: 'woqodDocs',
  },
]};

export const why_us = {
  en: {
    title: 'Why us?',
    text: 'AL DANA BOND ACP is the premier choice designed for ones who don’t compromise on quality and aesthetics. So, we urge you to extend your imagination and paint that mental picture with our exclusive AL DANA BOND ACP sheets. We have pan GCC and Africa distribution network and timely delivery isour habit',

  },
  ar:{
    title: 'لماذا نحن؟',
    text: 'إن الدانة بوند ACP هو الخيار الأول المُصَمَّم لأولئك الذين لا يتنازلون عن الجودة والجمال. لذلك، نحثكم على توسيع مخيلتكم ورسم تلك الصورة الذهنية من خلال ألواح الدانة بوند ACP الحصرية الخاصة بنا. لدينا شبكة توزيع في دول مجلس التعاون الخليجي وأفريقيا، والتسليم في الوقت المحدد هو عادتنا',

  },
};
