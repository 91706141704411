import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import DemoBanner from '../home/DemoBanner';
import HeroBar from './HeroBar';
import Title from './Title';
import Content from './Content';
import Quotation from '../shared/Quotation';

const AboutUs = () => {
  return (
    <BrowserRouter>
      <Helmet>
        <meta charSet='utf-8' />
        <title>About Us</title>
        <link rel='canonical' href='' />
      </Helmet>
      <HeroBar />
      <Title />
      <Content />
      <DemoBanner />
      <Quotation />
    </BrowserRouter>
  );
};

export default AboutUs;
