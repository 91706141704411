import React from 'react';
import styled from 'styled-components';
import founder_img from '../../assets/img/founder_img.png';
import quotes from '../../assets/svg/quotes.svg';

const Title = () => {
  return (<></>
    // <SectionWrapper>
    //   <div className='contents'>
    //     <div className='title'>
    //       <img src={quotes} alt='quotes' />
    //       <h1>Sheik Fahad's message</h1>
    //     </div>
    //     <div className='image-container'>
    //       <div className='image' />
    //       <h1>Sheik Abdul Aziz Bin Mohammed Saud Thani Althani</h1>
    //       <p>Founder and Chairman</p>
    //     </div>
    //   </div>
    //   <div className='descr'>
    //     <p>
    //       Al-Dana Aluminum Composite Panel WLL is indebted to every one who
    //       supported us from day one of our operations since incorporation Our
    //       priority is not mere to sale of our services, it is much beyond, and
    //       we desire every single client’s absolute satisfaction of our services
    //       <span> “OUR PHILOSOPHY IS UN-MATCHED QUALITY DELIVERABLES”</span> Our
    //       first goal is for our country to be a successful and pioneering model
    //       in the world at all levels, And we will work with you to achieve
    //       vision 2030
    //     </p>
    //   </div>
    // </SectionWrapper>
  );
};

export default Title;

const SectionWrapper = styled.section`
  background-color: #f7f7f7;
  display: flex;
  align-items: center;

  @media (max-width: 680px) {
  flex-flow: column;
  }

  > div.contents {
    display: flex;
    flex: 1 0 0;
    flex-flow: column;
    align-items: center;

    > div.title {
      display: flex;
      color: var(--dana-color);

      @media (max-width: 680px) {
          justify-content: space-around;
          align-items: center;    
        }

      > img {
        width: 10%;
      }

      > h1 {
        font-family: 'Cairo-Bold';
        font-size: 3rem;
        padding: 0 30px 0;

        @media (max-width: 1800px) {
          font-size: 2.6rem;
        }

        @media (max-width: 1600px) {
          font-size: 2.2rem;
        }

        @media (max-width: 1400px) {
          font-size: 2rem;
        }

        @media (max-width: 1300px) {
          font-size: 1.7rem;
        }

        
      }
    }
  }

  > div.contents > div.image-container {
    flex: 1 0 0;
    display: flex;
    flex-flow: column;
    align-items: center;

    @media (max-width: 680px) {
      text-align: center;
    }
  }

  > div.descr {
    flex: 2 0 0;
    font-family: 'Cairo-Medium';
    font-size: 1.5rem;
    color: #1d2d5b;

    @media (max-width: 1800px) {
      font-size: 1.4rem;
    }

    @media (max-width: 1600px) {
      font-size: 1.3rem;
    }

    @media (max-width: 1400px) {
      font-size: 1.2rem;
    }

    @media (max-width: 1300px) {
      font-size: 1.1rem;
    }

    > p {
      line-height: 2;

      @media (max-width: 680px) {
      padding: 0 1rem 0;
text-align: justify;
    }

      > span {
        font-family: 'Cairo-Bold';
      }
    }
  }

  div.contents > div.image-container {

    > div.image {
      height: 400px;
      width: 400px;
      border-radius: 50%;
      background: url(${founder_img});
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      @media (max-width: 1800px) {
        height: 350px;
      width: 350px;    
      }  

    @media (max-width: 1600px) {
        height: 300px;
      width: 300px;    
      } 

    @media (max-width: 1400px) {
        height: 270px;
      width: 270px;    
      } 

@media (max-width: 1300px) {
        height: 250px;
      width: 250px;    
      } 
    }

    > h1 {
      font-family: 'Cairo-Bold';
      font-size: 1.5rem;

    @media (max-width: 1800px) {
      font-size: 1.4rem;
    }

    @media (max-width: 1600px) {
      font-size: 1.25rem;
    }

    @media (max-width: 1400px) {
      font-size: 1.1rem;
    }

    @media (max-width: 1300px) {
      font-size: 1rem; 
    }

    > p {
      font-family: 'Cairo-Medium';
    }
  }
`;
