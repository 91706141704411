import React from 'react';
import _ from 'underscore';
import styled from 'styled-components';
import { useRTL } from '../../../LanguageContext';
import LetsGo from '../../../assets/svg/lets-go.svg';
import { openPopupWidget } from 'react-calendly';
import { buttonText } from '../../home/data';

const CalendlyButton = ({ url, prefill, pageSettings, utm }) => {
  const { isRTL, language } = useRTL();

  const onClick = () => openPopupWidget({ url, prefill, pageSettings, utm });

  return (
    <ButtonWrapper onClick={onClick} isRTL={isRTL}>
      {_.find(buttonText, (value, key) => key === language).talkToUs}
      <span>
        <img src={LetsGo} alt='arrow' />
      </span>
    </ButtonWrapper>
  );
};

export default CalendlyButton;

const ButtonWrapper = styled.button`
  font-family: 'Cairo-SemiBold';
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: linear-gradient(to right, var(--dana-color) 50%, white 50%);
  background-size: 200% 100%;
  background-position: left bottom;
  transition: all 1s ease;
  color: #ffffff;
  padding: 18px 25px;
  color: white;
  border: var(--dana-color);
  border-radius: 10px;
  text-decoration: none;
  font-size: 1.1rem;

  @media (max-width: 1800px) {
    font-size: 1rem;
    padding: 16px 20px;
  }

  @media (max-width: 1600px) {
    font-size: 0.9rem;
    padding: 14px 20px;
  }

  @media (max-width: 1400px) {
    font-size: 0.9rem;
    padding: 12px 20px;
  }

  @media (max-width: 1300px) {
    font-size: 0.9rem;
    padding: 10px 20px;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    background-position: right bottom;
    transition: all 1s ease;
    color: #1d2d5b;
  }
`;
