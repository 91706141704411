import React from 'react';
import styled from 'styled-components';
import { useRTL } from '../../../LanguageContext';

const NormalButton = ({ children, className, onClick, ...rest }) => {
  const { isRTL } = useRTL();
  return (
    <ButtonWrapper
      className={className}
      isRTL={isRTL}
      onClick={onClick ? onClick : () => {}}
      {...rest}
    >
      {children}
    </ButtonWrapper>
  );
};

export default NormalButton;

const ButtonWrapper = styled.button`
  font-family: 'Cairo-SemiBold';
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 2px solid var(--dana-color);
  background: linear-gradient(to right, var(--dana-color) 50%, white 50%);
  background-size: 200% 100%;
  background-position: left bottom;
  transition: all 1s ease;
  color: #ffffff;
  padding: 18px 20px;
  color: white;
  border: none;
  border-radius: 10px;
  text-decoration: none;
  font-size: 1.1rem;

  @media (max-width: 1800px) {
    font-size: 1rem;
    padding: 16px 20px;
  }

  @media (max-width: 1600px) {
    font-size: 0.9rem;
    padding: 14px 20px;
  }

  @media (max-width: 1400px) {
    font-size: 0.9rem;
    padding: 12px 20px;
  }

  @media (max-width: 1300px) {
    font-size: 0.9rem;
    padding: 10px 20px;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    background-position: right bottom;
    transition: all 1s ease;
    color: #1d2d5b;
  }
`;
