import React from 'react';
import styled from 'styled-components';
import our_factory from '../../assets/img/our-factory.png';
import { useRTL } from '../../LanguageContext';
const HeroBar = () => {
  const { isRTL } = useRTL();
  return (
    <SectionWrapper isRTL={isRTL}>
      <div className='title'>{ isRTL? 'ألواح الألمنيوم المركبة':'Aluminium Composite Panel'
 }
      </div>
    </SectionWrapper>
  );
};

export default HeroBar;

const SectionWrapper = styled.section`
  display: flex;
  justify-content: ${(prop) => (prop.isRTL ? 'right' : 'left')};
  align-items: center;
  position: relative;
  height: 72vh;
  background: url(${our_factory});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  

  @media (max-width: 1024px) {
    height: 40vh;
  }

  @media (max-width: 680px) {
    height: 50vh;
    background: url(${our_factory});
    background-size: 270%;
  }

  > div.title {
    font-family: 'Cairo-Bold';
    color: white;
    font-size: 5rem;
    text-align: ${(prop) => (prop.isRTL ? 'right' : 'left')};
    margin: ${(prop) => (prop.isRTL ? '0rem 5rem 0 0rem ' : '0 0 0 5.5rem')};
    

    @media (max-width: 1800px) {
      font-size: 4rem;
    }

    @media (max-width: 1600px) {
      font-size: 3.5rem;
    }

    @media (max-width: 1500px) {
      font-size: 3rem;
    }

    @media (max-width: 1300px) {
      font-size: 2.8rem;
    }

    @media (max-width: 1024px) {
      //text-align: center;
      margin: ${(prop) => (prop.isRTL ? '0rem 2rem 0 0rem ' : '0 0 0 2rem')};
    }

    @media (max-width: 680px) {
      font-size: 1.8rem;
      width: 90%;
    }

    > p {
      font-size: 1.125rem;
      font-family: 'Cairo-Regular';
      text-align: center;

      @media (max-width: 1800px) {
        font-size: 1rem;
      }

      @media (max-width: 1600px) {
        font-size: 0.9rem;
      }

      @media (max-width: 1500px) {
        font-size: 0.9rem;
      }

      @media (max-width: 1300px) {
        font-size: 0.9rem;
      }
    }
  }
`;
