import React from 'react';
import styled from 'styled-components';
import { useRTL } from '../../LanguageContext';
import { privacyPolicy } from './data';
import _ from 'underscore';
const Content = () => {
  const { isRTL, language } = useRTL();

  const policies = _.find(privacyPolicy, (value, key) => key === language);
  return (
    <ContentWrapper isRTL={isRTL}>
      <div className='intro'>
        <div className='title'>{policies.p1.title}</div>
        <div className='text'>
          <p>
          {policies.p1.description}
          </p>{' '}
        </div>
      </div>

      <div className='laws'>
        <div className='title'>
        {policies.p2.title}
        </div>
        <div className='text'>
          <p>
          {policies.p2.description}
          </p>
        </div>
      </div>

      <div className='aldana-who'>
        <div className='title'>{policies.p3.title}</div>
        <div className='text'>
          <p>
          {policies.p3.description}
          </p>{' '}
        </div>
      </div>

      <div className='info-collected'>
        <div className='title'>{policies.p4.title}</div>
        <div className='text'>
          <p>
          {policies.p4.description}
          </p>
        </div>
      </div>

      <div className='info-given'>
        <div className='title'>{policies.p5.title}</div>
        <div className='text'>
        {policies.p5.description}
        </div>
      </div>

      <div className='info-collected-on-you'>
        <div className='title'>{policies.p6.title}</div>
        <div className='text'>
        {policies.p6.description}
        </div>
      </div>

      <div className='info-recieved-sources'>
        <div className='title'>
        {policies.p7.title}
        </div>
        <div className='text'>
        {policies.p7.description}
        </div>
      </div>

      <div className='why-info-collected'>
        <div className='title'>  {policies.p8.title}</div>
        <div className='text'>
        {policies.p8.description}
        </div>
      </div>

      <div className='legal-info-processing'>
        <div className='title'>
        {policies.p9.title}
        </div>
        <div className='text'>
        {policies.p9.description}
        </div>
      </div>

      <div className='retaining-info'>
        <div className='title'>
        {policies.p10.title}
        </div>
        <div className='text'>
        {policies.p10.description}
        </div>
      </div>

      <div className='data-right'>
        <div className='title'>
        {policies.p11.title}
        </div>
        <div className='text'>
        {policies.p11.description}
        </div>
      </div>

      <div className='data-storage'>
        <div className='title'>{policies.p12.title}</div>
        <div className='text'>
        {policies.p12.description}
        </div>
      </div>

      <div className='data-transfer'>
        <div className='title'>{policies.p13.title}</div>
        <div className='text'>
        {policies.p13.description}
        </div>
      </div>

      <div className='contact-info'>
        <div className='title'>
        {policies.p14.title}
        </div>
        <div className='text'>
        {policies.p14.description}
        </div>
        <div className='text'>
        {'ing No 383, Zone 32 '}
          
        </div>
        <div className='text'>
        {'Al Markhiya  '}
          
        </div>
        <div className='text'>
        {'P.O Box: 11854, Doha Qatar  '}
        </div>
        <div className='text'>
        {'  Tel: +974 30009512 info@aldanabond.com '}
        </div>
      
         
      </div>

    </ContentWrapper>
  );
};

export default Content;

const ContentWrapper = styled.section`
  padding: 2rem 8rem 0 8rem;
  color: #1d2d5b;
  direction: ${(prop) => (prop.isRTL ? 'rtl' : 'ltr')};

  @media (max-width: 680px) {
    padding: 0 1rem 0 1rem;
  }

  > div > div.title {
    font-size: 2rem;
    font-family: 'Cairo-Bold';
    padding: 3rem 0 1rem;
    text-align:${(prop) => (prop.isRTL ? 'right' : 'left')};

    @media (max-width: 680px) {
      font-size: 1.6rem;
    }
  }

  > div > div.text {
    font-size: 1rem;
    font-family: 'Cairo-Medium';
    text-align:${(prop) => (prop.isRTL ? 'right' : 'left')};
    margin:0.3rem;

    @media (max-width: 680px) {
      text-align: justify;
      word-wrap: break-word;
    }
  }
`;
