import React, { useEffect, useState, useRef } from 'react';
import _ from 'underscore';
import styled from 'styled-components';
import { SLIDER_DATA } from './data';
import { useRTL } from '../../LanguageContext';
//import Dot from './Dot';
//import { FOOTER_DOWNLOAD } from '../shared/data';
//import DownloadFile from '../shared/DownloadFile';

const Slider = () => {
  const { isRTL, language } = useRTL();
  const [current, setCurrent] = useState(0);

  const currentRef = useRef(current);
  currentRef.current = current;

  const length = 3;

  // let downloadContent = _.find(
  //   FOOTER_DOWNLOAD,
  //   (value, key) => key === language
  // )[0];

  useEffect(() => {
    setTimeout(nextSlide, 10000);
  });

  // const nextSlide = () => {
  //   setCurrent(current !== length - 1 ? current + 1 : 0);
  // };

  const nextSlide = () => {
    setCurrent(currentRef.current !== length - 1 ? currentRef.current + 1 : 0);
  };

  // const prevSlide = () => {
  //   setCurrent(current !== 0 ? current - 1 : length - 1);
  // };

  return (
    <SectionWrapper>
      <Upper>
        {_.find(SLIDER_DATA, (value, key) => key === language).map((item) => {
          return (
            <ImgDiv current={current} id={item.id} key={item.id} isRTL={isRTL}>
              {current === item.id && (
                <img
                  current={current}
                  key={item.id}
                  src={item.image}
                  alt='img'
                />
              )}

              <div className='img-overlay' />

              <div className='text'>{item.text}</div>

              <div className='subtext'>{item.subtext}</div>


              <SliderDots isRTL={isRTL}>
                {SLIDER_DATA.en.map((items) => (
                  <SliderDot
                    key={items.id}
                    current={current}
                    id={items.id}
                    onClick={() => setCurrent(items.id)}
                  ></SliderDot>
                ))}
              </SliderDots>
            </ImgDiv>
          );
        })}
      </Upper>
    </SectionWrapper>
  );
};

export default Slider;

const SectionWrapper = styled.section`
  width: 100%;
  border-radius: 0.5rem;
  background-color: black;
  direction: ${(prop) => (prop.isRTL ? 'rtl' : 'ltr')};
`;

const Upper = styled.section`
  display: flex;
  overflow: hidden;
  /* height: 500px; */
`;

const ImgDiv = styled.div`
  position: relative;
  overflow: hidden;
 // opacity: ${(prop) => (prop.current === prop.id ? '1' : '0')};
  transition-duration: ${(prop) =>
    prop.current === prop.id ? '2s' : '2s ease in out'};
  direction: ${(prop) => (prop.isRTL ? 'rtl' : 'ltr')};
  height: 72vh;
  

  @media (max-width: 1024px) {
    height: 56vh;
  }

  @media (max-width: 850px) {
    height: 51vh;
  }

  > img {
    object-fit: cover;
    height: auto ;
    width:100%;
   
    @media (max-width: 600px) {
      height: 50vh;
      width:100%;
    }

    @media (max-width: 850px) {
      height: 51vh;
    }
   
    

  
  }

  > div.img-overlay {
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: ${(prop) =>
      prop.isRTL
        ? 'linear-gradient( 225deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 90%)'
        : 'linear-gradient( 135deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 90%)'};
  }

  > div.text {
    font-family: 'Cairo-Bold';
    color: white;
    font-size: 5rem;
    position: absolute;
    left: ${(prop) => (prop.isRTL ? '' : '5%')};
    right: ${(prop) => (prop.isRTL ? '5%' : '')};
    width: 92%;
    top: 32%;
  

    @media (max-width: 1800px) {
      font-size: 4rem;
    }

    @media (max-width: 1600px) {
      font-size: 3.4rem;
  
    }

    @media (max-width: 1500px) {
      font-size: 3rem;
 
    }

    @media (max-width: 1400px) {
      font-size: 2.9rem;
      top: 14%;
   
    }

    @media (max-width: 1300px) {
      /* font-size: 2.8rem; */
      width: 60%;
      font-size: 3rem;
    }

    @media (max-width: 680px) {
      width: 90%;
      font-size: 2.5rem;
      line-height: 3rem;
      left: ${(prop) => (prop.isRTL ? '' : '8%')};
    }
  }

  > div.subtext {
    color: white;
    font-size: 1.9rem;
    font-family: 'Cairo-Medium';
    position: absolute;
    left: ${(prop) => (prop.isRTL ? '' : '5%')};
    right: ${(prop) => (prop.isRTL ? '5%' : '')};
    width: 60%;
    top: 58%;

    @media (max-width: 1800px) {
      font-size: 1.6rem;
    }

    @media (max-width: 1600px) {
      font-size: 1.4rem;
      top: 55%;
    }


    @media (max-width: 1300px) {
      line-height: 1.8rem;
      font-size: 1.4rem;
      width: 50%;
    }

    @media (max-width: 680px) {
      width: 90%;
      line-height: 1.5rem;
      font-size: 1.1rem;
      left: ${(prop) => (prop.isRTL ? '' : '8%')};
    }
  }

  > div.download-section {
    color: white;
    font-family: 'Cairo-Medium';
    font-size: 1.25rem;
    position: absolute;
    left: ${(prop) => (prop.isRTL ? '' : '10%')};
    right: ${(prop) => (prop.isRTL ? '10%' : '')};
    width: 30%;
    bottom: 15%;

    @media (max-width: 1800px) {
      font-size: 1.1rem;
    }

    @media (max-width: 1600px) {
      font-size: 1rem;
    }

    @media (max-width: 1500px) {
      font-size: 0.9rem;
    }

    @media (max-width: 1400px) {
      font-size: 0.9rem;
    }

    @media (max-width: 1300px) {
      font-size: 0.9rem;
    }

    @media (max-width: 1024px) {
      width: 100%;
    }

    > div.container {
      display: flex;

      > div.text-wrapper {
        transition: all 0.5s;

        > div {
          padding: 0 1rem 10px;
          cursor: pointer;
        }
        &:hover {
          cursor: pointer;
          opacity: 0.8;
          transition: all 0.5s;
        }
      }
    }
  }
`;

const SliderDots = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  direction: ${(prop) => (prop.isRTL ? 'rtl' : 'ltr')};
  /* margin-top: -20px; */
  bottom: 2%;
  left: 50%;
`;

const SliderDot = styled.div`
  cursor: pointer;
  height: 8px;
  width: 8px;
  margin: 0 3px;
  background-color: ${(prop) => (prop.id === prop.current ? 'white' : '#DCDCDC')};
  border-radius: 50%;
  display: inline-block;
  transition: 0.10s ease-in-out;
  z-index: 1;
`;
