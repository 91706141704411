import flash_silver from '../../assets/img/panel-colors/flash-silver.jpg';
import champagne_gold from '../../assets/img/panel-colors/champagne-gold.jpg';
import silver_grey from '../../assets/img/panel-colors/silver-grey.jpg';
import coffee_champagne from '../../assets/img/panel-colors/coffee-champagne.jpg';
import ingot_gold from '../../assets/img/panel-colors/ingot-gold.jpg';
import palm from '../../assets/img/panel-colors/palm.jpg';
import jade_silver from '../../assets/img/panel-colors/jade-silver.jpg';
import grey_silver from '../../assets/img/panel-colors/grey-silver.jpg';
import rat_silver_grey from '../../assets/img/panel-colors/rat-silver-grey.jpg';
import galaxy_black from '../../assets/img/panel-colors/galaxy-black.jpg';
import blue_silver from '../../assets/img/panel-colors/blue-silver.png';
import rose_silver from '../../assets/img/panel-colors/rose-silver.jpg';

import pure_white from '../../assets/img/panel-colors/pure-white.jpg';
import off_white from '../../assets/img/panel-colors/off-white.jpg';
import ivory from '../../assets/img/panel-colors/ivory.jpg';
import deep_grey from '../../assets/img/panel-colors/deep-grey.jpg';
import light_grey from '../../assets/img/panel-colors/light-grey.jpg';
import grey from '../../assets/img/panel-colors/grey.jpg';
import orange from '../../assets/img/panel-colors/orange.jpg';
import red from '../../assets/img/panel-colors/red.jpg';
import deep_rose from '../../assets/img/panel-colors/deep-rose.jpg';
import deep_coffee from '../../assets/img/panel-colors/deep-coffee.jpg';
import apple_green from '../../assets/img/panel-colors/apple-green.jpg';
import navy_blue from '../../assets/img/panel-colors/navy-blue.jpg';
import black from '../../assets/img/panel-colors/black.jpg';
import yellow from '../../assets/img/panel-colors/yellow.jpg';

import glossy_black from '../../assets/img/panel-colors/glossy-black.jpg';
import glossy_blue from '../../assets/img/panel-colors/glossy-blue.jpg';
import glossy_red from '../../assets/img/panel-colors/glossy-red.jpg';
import glossy_yellow from '../../assets/img/panel-colors/glossy-yellow.jpg';
import glossy_orange from '../../assets/img/panel-colors/glossy-orange.jpg';
import glossy_white from '../../assets/img/panel-colors/glossy-white.jpg';

import sparkling_red from '../../assets/img/panel-colors/sparking-red.jpg';
import sparkling_orange from '../../assets/img/panel-colors/sparkling-orange.jpg';
import sparkling_black from '../../assets/img/panel-colors/sparkling-black.jpg';

import brushed_silver from '../../assets/img/panel-colors/brushed-silver.jpg';
import brushed_gold from '../../assets/img/panel-colors/brushed-gold.jpg';
import brushed_tungsten from '../../assets/img/panel-colors/brushed-tungsten.jpg';

import red_walnut from '../../assets/img/panel-colors/red-walnut.jpg';
import black_walnut from '../../assets/img/panel-colors/black-walnut.jpg';
import maple from '../../assets/img/panel-colors/maple.jpg';

import silver_mirror from '../../assets/img/panel-colors/silver-mirror.jpg';
import gold_mirror from '../../assets/img/panel-colors/gold-mirror.jpg';
import tawny_mirror from '../../assets/img/panel-colors/tawny-mirror.jpg';

import aegior from '../../assets/img/client-logos/aegior.jpg';
import AGR from '../../assets/img/client-logos/AGR.jpg';
import andalus from '../../assets/img/client-logos/al-andalus.jpg';
import sawari from '../../assets/img/client-logos/al-sawari-bright-light.jpg';
import tech from '../../assets/img/client-logos/al-tech.jpg';
import ashgal from '../../assets/img/client-logos/ashghal-logo.png';
import astad from '../../assets/img/client-logos/astad.png';
import azdah from '../../assets/img/client-logos/azdah.png';
import defender from '../../assets/img/client-logos/defender.png';
import deserline from '../../assets/img/client-logos/deserline.png';
import dorra from '../../assets/img/client-logos/dorra-group.jpg';
import everest from '../../assets/img/client-logos/everest-aluminium.jpg';
import galfar from '../../assets/img/client-logos/galfar.png';
import glass from '../../assets/img/client-logos/glass-tech.jpg';
import harinsa from '../../assets/img/client-logos/harinsa-qatar.png';
import HBK from '../../assets/img/client-logos/HBK.png';
import pride from '../../assets/img/client-logos/pride-project.png';
import profession from '../../assets/img/client-logos/profession-aluminium.jpg';
import qbel from '../../assets/img/client-logos/q-bel.png';
import redco from '../../assets/img/client-logos/redco.jpg';
import sky from '../../assets/img/client-logos/sky-wall.png';
import sollas from '../../assets/img/client-logos/Sollas-Aluminium.jpg';
import woqod from '../../assets/img/client-logos/woqod.png';

import FB from '@material-ui/icons/Facebook';
import Instagram from '@material-ui/icons/Instagram';
import LinkedIn from '@material-ui/icons/LinkedIn';
import Twitter from '@material-ui/icons/Twitter';
import Youtube from '@material-ui/icons/YouTube';

import slider_data0 from '../../assets/img/slider-image0.jpg';
import slider_data1 from '../../assets/img/slider-image1.jpg';
import slider_data2 from '../../assets/img/slider-image2.jpg';

// import Building from '../../assets/img/slider-building.jpg';
// import City from '../../assets/img/slider-city.jpg';
// import Skyline from '../../assets/img/slider-skyline.jpg';
// import View from '../../assets/img/slider-view.jpg';

import project_1 from '../../assets/img/project-photos/1-p.jpg';
import project_2 from '../../assets/img/project-photos/2-p.jpg';
import project_3 from '../../assets/img/project-photos/3-p.jpg';
import project_4 from '../../assets/img/project-photos/4-p.jpg';
import project_5 from '../../assets/img/project-photos/5-p.jpg';
import project_6 from '../../assets/img/project-photos/6-p.jpg';

import AlDana_Color_Chart from '../../assets/docs/AlDana-Color-Chart.pdf';
import Company_Profile from '../../assets/docs/Company-profile.pdf';
import Prequalification from '../../assets/docs/prequalification.pdf';

import iso_docs from '../../assets/docs/ISO-zip.zip';
import pollution_doc from '../../assets/docs/pollution.jpg';
import plant_fire_doc from '../../assets/docs/plant-fire.jpg';
import defense_docs from '../../assets/docs/civil-defence-zip.zip';
import woqod_docs from '../../assets/docs/woqod-zip.zip';

import call from '../../assets/svg/call.svg';
import email from '../../assets/svg/email.svg';
import location from '../../assets/svg/location.svg';

export const PANEL_COLORS = {
  Metallic: {
    loadMore: true,
    key: 'Metallic',
    items: [
      {
        code: 'ADB-101',
        title: 'Flash Silver',
        img: flash_silver,
      },
      {
        code: 'ADB-102',
        title: 'Champagne Gold',
        img: champagne_gold,
      },
      {
        code: 'ADB-103',
        title: 'Silver Grey',
        img: silver_grey,
      },
      {
        code: 'ADB-104',
        title: 'Coffee Champagne',
        img: coffee_champagne,
      },
      {
        code: 'ADB-105',
        title: 'Ingot Gold',
        img: ingot_gold,
      },
      {
        code: 'ADB-106',
        title: 'Palm',
        img: palm,
      },
      {
        code: 'ADB-107',
        title: 'Jade Silver',
        img: jade_silver,
      },
      {
        code: 'ADB-108',
        title: 'Grey Silver',
        img: grey_silver,
      },
      {
        code: 'ADB-109',
        title: 'Rat Silver Grey',
        img: rat_silver_grey,
      },
      {
        code: 'ADB-110',
        title: 'Galaxy Black',
        img: galaxy_black,
      },
      {
        code: 'ADB-111',
        title: 'Blue Silver',
        img: blue_silver,
      },
      {
        code: 'ADB-112',
        title: 'Rose Silver',
        img: rose_silver,
      },
    ],
  },
  Matte: {
    loadMore: true,
    key: 'Matte',
    items: [
      {
        code: 'ADB-201',
        title: 'Pure White',
        img: pure_white,
      },
      {
        code: 'ADB-202',
        title: 'Off White',
        img: off_white,
      },
      {
        code: 'ADB-203',
        title: 'Ivory',
        img: ivory,
      },
      {
        code: 'ADB-204',
        title: 'Deep Grey',
        img: deep_grey,
      },
      {
        code: 'ADB-205',
        title: 'Light Grey',
        img: light_grey,
      },
      {
        code: 'ADB-206',
        title: 'Grey',
        img: grey,
      },
      {
        code: 'ADB-207',
        title: 'Orange',
        img: orange,
      },
      {
        code: 'ADB-208',
        title: 'Red',
        img: red,
      },
      {
        code: 'ADB-209',
        title: 'Deep Rose',
        img: deep_rose,
      },
      {
        code: 'ADB-210',
        title: 'Deep Coffee',
        img: deep_coffee,
      },
      {
        code: 'ADB-211',
        title: 'Apple Green',
        img: apple_green,
      },
      {
        code: 'ADB-212',
        title: 'Navy Blue',
        img: navy_blue,
      },
      {
        code: 'ADB-213',
        title: 'Black',
        img: black,
      },
      {
        code: 'ADB-214',
        title: 'Yellow',
        img: yellow,
      },
    ],
  },
  Glossy: {
    loadMore: false,
    key: 'Glossy',
    items: [
      {
        code: 'ADB-301',
        title: 'Glossy Black',
        img: glossy_black,
      },
      {
        code: 'ADB-302',
        title: 'Glossy Blue',
        img: glossy_blue,
      },
      {
        code: 'ADB-303',
        title: 'Glossy Red',
        img: glossy_red,
      },
      {
        code: 'ADB-304',
        title: 'Glossy Yellow',
        img: glossy_yellow,
      },
      {
        code: 'ADB-305',
        title: 'Glossy Orange',
        img: glossy_orange,
      },
      {
        code: 'ADB-306',
        title: 'Glossy White',
        img: glossy_white,
      },
    ],
  },
  Sparkling: {
    loadMore: false,
    key: 'Sparkling',
    items: [
      {
        code: 'ADB-401',
        title: 'Sprakling Red',
        img: sparkling_red,
      },
      {
        code: 'ADB-402',
        title: 'Sparkling Orange',
        img: sparkling_orange,
      },
      {
        code: 'ADB-403',
        title: 'Sparkling Black',
        img: sparkling_black,
      },
    ],
  },
  Brushed: {
    loadMore: false,
    key: 'Brushed',
    items: [
      {
        code: 'ADB-501',
        title: 'Brushed Silver',
        img: brushed_silver,
      },
      {
        code: 'ADB-502',
        title: 'Brushed Gold',
        img: brushed_gold,
      },
      {
        code: 'ADB-503',
        title: 'Brushed Tungsten',
        img: brushed_tungsten,
      },
    ],
  },
  Wooden: {
    loadMore: false,
    key: 'Wooden',
    items: [
      {
        code: 'ADB-601',
        title: 'Red Walnut',
        img: red_walnut,
      },
      {
        code: 'ADB-602',
        title: 'Black Walnut',
        img: black_walnut,
      },
      {
        code: 'ADB-603',
        title: 'Maple',
        img: maple,
      },
    ],
  },
  Mirror: {
    loadMore: false,
    key: 'Mirror',
    items: [
      {
        code: 'ADB-701',
        title: 'Silver Mirror',
        img: silver_mirror,
      },
      {
        code: 'ADB-702',
        title: 'Gold Mirror',
        img: gold_mirror,
      },
      {
        code: 'ADB-703',
        title: 'Tawny Mirror',
        img: tawny_mirror,
      },
    ],
  },
};

export const PANEL_COLORS_TITLE = {
  en: 'Wide range of colors to choose from',
  ar: ' مجموعة واسعة من الألوان للاختيار من بينها ',
};

export const SLIDER_DATA = {
  en: [
    {
      id: 0,
      image: slider_data0,
      text: 'Wide distribution network',
      subtext:
        'Our ACPs undergo a series of tests to ensure durability in any weather and season. We also provide multi-year warranty on our products.',
    },
    {
      id: 1,
      image: slider_data1,
      text: ' Quality certificate from ministry',
      subtext:
        'Our ACPs undergo a series of tests to ensure durability in any weather and season. We also provide multi-year warranty on our products.',
    },
    {
      id: 2,
      image: slider_data2,
      text: 'Wide range of colors to choose from',
      subtext: 'Metallic matte glossy sparkling brushed wooden mirror',
    },
  ],
  ar: [
    {
      id: 0,
      image: slider_data0,
      text: 'شبكة توزيع واسعة',
      subtext:
        'تخضع منتجاتنا من ألواح الألمنيوم المركب لسلسلة من الاختبارات لضمان التحمل في أي طقس وفي أي فصل. كما نقدم ضماناً لعدة سنوات على منتجاتنا',
    },
    {
      id: 1,
      image: slider_data1,
      text: 'شهادة الجودة من الوزارة',
      subtext:
        '  تخضع منتجاتنا من ألواح لسلسلة من الاختبارات لضمان التحمل في أي طقس وفي أي موسم. كما نقدم ضماناً لعدة سنوات على منتجاتنا',
    },
    {
      id: 2,
      image: slider_data2,
      text: ' مجموعة واسعة من الألوان للاختيار من بينها ',
      subtext:
        'مجموعة واسعة للاختيار من الألوان المعدنية غير اللامعة  و اللامعة المتلألئة و المرايا و الخشبية',
    },
  ],
};

export const CLIENT_URL = [
  {
    src: aegior,
    alt: 'google',
  },
  {
    src: AGR,
    alt: 'facebook',
  },
  {
    src: andalus,
    alt: 'slack',
  },
  {
    src: sawari,
    alt: 'viber',
  },
  {
    src: tech,
    alt: 'behance',
  },
  {
    src: ashgal,
    alt: 'behance',
  },
  {
    src: astad,
    alt: 'behance',
  },
  {
    src: azdah,
    alt: 'behance',
  },
  {
    src: defender,
    alt: 'behance',
  },
  {
    src: deserline,
    alt: 'behance',
  },
  {
    src: dorra,
    alt: 'behance',
  },
  {
    src: everest,
    alt: 'behance',
  },
  {
    src: galfar,
    alt: 'behance',
  },
  {
    src: glass,
    alt: 'behance',
  },
  {
    src: harinsa,
    alt: 'behance',
  },
  {
    src: HBK,
    alt: 'behance',
  },
  {
    src: pride,
    alt: 'behance',
  },
  {
    src: profession,
    alt: 'behance',
  },
  {
    src: qbel,
    alt: 'behance',
  },
  {
    src: redco,
    alt: 'behance',
  },
  {
    src: sky,
    alt: 'behance',
  },
  {
    src: sollas,
    alt: 'behance',
  },
  {
    src: woqod,
    alt: 'behance',
  },
];

export const FOOTER_TITLES = {
  en: {
    company: 'Company',
    other: 'Other',
    download: 'Download Section',
    contact: 'Contact',
  },
  ar: {
    company: 'شركة',
    other: 'آخر',
    download: 'قسم التحميل',
    contact: 'تواصل معنا',
  },
};

export const FOOTER_LINKS_COMPANY = {
  en: {
    items: [
      { title: 'About', src: '/about' },
      { title: 'Why us', src: '/why-us' },
      { title: 'Our projects', src: '/projects' },
      { title: 'Our products', src: '/products' },
      { title: 'Careers', src: '/careers' },
    ],
  },
  ar: {
    items: [
      { title: 'حول', src: '/about' },
      { title: 'لماذا نحن', src: '/why-us' },
      { title: 'مشاريعنا', src: '/projects' },
      { title: 'منتجاتنا', src: '/products' },
      { title: 'وظائف', src: '/careers' },
    ],
  },
};

export const FOOTER_LINKS_OTHER = {
  en: {
    items: [
      { title: 'Careers', src: '/careers' },
      { title: 'Cookie Policy', src: '/cookie-policy' },
      { title: 'Privacy Policy', src: '/privacy-policy' },
    ],
  },
  ar: {
    items: [
      { title: 'وظائف', src: '/careers' },
      { title: 'سياسة ملفات تعريف الارتباط', src: '/cookie-policy' },
      { title: 'امن المعلومات', src: '/privacy-policy' },
    ],
  },
};

export const FOOTER_ICONS = [
  { src: <FB style={{ color: '#0A192B', width: 20 }} />, alt: 'facebook' },
  {
    src: <LinkedIn style={{ color: '#0A192B', width: 20 }} />,
    alt: 'linkedin',
  },
  { src: <Instagram style={{ color: '#0A192B', width: 20 }} />, alt: 'home' },
  { src: <Twitter style={{ color: '#0A192B', width: 20 }} />, alt: 'twitter' },
  { src: <Youtube style={{ color: '#0A192B', width: 20 }} />, alt: 'youtube' },
];

export const FOOTER_DOWNLOAD = {
  en: [
    {
      title: 'Download Company Profile',
      name: 'company_profile',
      subtitle: 'PDF, 1.5 M.B',
    },
    {
      title: 'Download Company Prequalification',
      name: 'prequalification',
      subtitle: 'PDF, 1.5 M.B',
    },
    {
      title: 'Download Color Chart',
      name: 'color_chart',
      subtitle: 'PDF, 1.5 M.B',
    },
  ],
  ar: [
    {
      title: 'تحميل الملف التعريفي للشركة',
      name: 'company_profile',
      subtitle: 'PDF, 1.5 M.B',
    },
    {
      title: 'Download Company Prequalification',
      name: 'prequalification',
      subtitle: 'PDF, 1.5 M.B',
    },
    {
      title: 'تنزيل مخطط الألوان',
      name: 'color_chart',
      subtitle: 'PDF, 1.5 M.B',
    },
  ],
};

export const HEADER_LINKS = {
  en: [
    { title: 'Home', url: '/' },
    { title: 'About', url: '/about' },
    { title: 'Why us', url: '/why-us' },
    { title: 'Our Factory', url: '/factory' },
    { title: 'Our Products', url: '/products' },
    { title: 'Our Projects', url: '/projects' },
    { title: 'Careers', url: '/careers' },
  ],
  ar: [
    { title: 'الرئيسية', url: '/' },
    { title: 'عنا', url: '/about' },
    { title: 'لماذا نحن', url: '/why-us' },
    { title: 'مصنعنا', url: '/factory' },
    { title: 'منتجاتنا', url: '/products' },
    { title: 'مشاريعنا', url: '/projects' },
    { title: 'وظائف', url: '/careers' },
  ],
};

export const QUOTE = {
  en: { title: 'Get quote', url: '/' },
  ar: { title: 'اطلب تسعير', url: '/' },
};

export const LANGUAGE = {
  en: { title: 'العربية' },
  ar: { title: 'English' },
};

export const CAROUSEL_IMGS = [
  { image: project_1, alt: 'project pictures' },
  { image: project_2, alt: 'project pictures' },
  { image: project_3, alt: 'project pictures' },
  { image: project_4, alt: 'project pictures' },
  { image: project_5, alt: 'project pictures' },
  { image: project_6, alt: 'project pictures' },
];

export const DOCUMENTS = {
  color_chart: { title: 'color_chart', src: AlDana_Color_Chart },
  company_profile: { title: 'company_profile', src: Company_Profile },
  prequalification: { title: 'prequalification', src: Prequalification },

  isoDocs: { title: 'isoDocs', src: iso_docs },
  fireplantDoc: { title: 'fireplantDoc', src: plant_fire_doc },
  pollutionDoc: { title: 'pollutionDoc', src: pollution_doc },
  woqodDocs: { title: 'woqodDocs', src: woqod_docs },
  defenseDocs: { title: 'defenseDocs', src: defense_docs },
};

export const QUOTATION_ICONS = [
  { img: call, alt: 'call', text: '+974 300 09512' },
  { img: location, alt: 'email', text: 'New industial area, Doha' },
  { img: email, alt: 'location', text: 'info@aldanabond.com' },
];

export const FORM = {
  en: [
    { name: 'Your name' },
    { name: 'Company name' },
    { name: 'Email address' },
    { name: 'Phone no.' },
    { name: 'Detailed enquiry' },
  ],
  ar: [
    { name: 'اسمك' },
    { name: 'اسم الشركة' },
    { name: 'البريد الإلكتروني' },
    { name: 'رقم الهاتف' },
    { name: 'وصف الطلب' },
  ],
};
