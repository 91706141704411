import React from 'react';
import styled from 'styled-components';
import cookie from '../../assets/svg/cookie-policy.svg';
import { useRTL } from '../../LanguageContext';

const Title = () => {
  const { isRTL } = useRTL();

  return (
    <SectionWrapper isRTL={isRTL}>
      <div className='text'>
        <h1>{isRTL ? 'نستخدم بعض ملفات تعريف الارتباط لنمنحك أفضل تجربة ممكنة':'We use some cookies to give you the best experience possible'}</h1>
        
      </div>
      <div className='image'>
        <img src={cookie} alt='' />
      </div>
    </SectionWrapper>
  );
};

export default Title;

const SectionWrapper = styled.section`
  direction: ${(prop) => (prop.isRTL ? 'rtl' : 'ltr')};
  display: flex;
  padding: 2rem 0 0 8rem;

  @media (max-width: 680px) {
    padding: 1rem 0rem 0 0rem;
    flex-flow: column;
  }

  > div.text > h1 {
    margin: 0 auto;
    flex: 2 0 0;
    font-family: 'Cairo-Bold';
    font-size: 4.5rem;
    text-align: left;

    @media (max-width: 1800px) {
      font-size: 4rem;
    }

    @media (max-width: 1600px) {
      font-size: 3.4rem;
    }

    @media (max-width: 1400px) {
      font-size: 3rem;
    }

    @media (max-width: 1300px) {
      font-size: 2.4rem;
    }

    @media (max-width: 680px) {
      font-size: 1.8rem;
      text-align: center;
    }
  }

  > div.text {
    padding: 0 7rem 0 0;

    @media (max-width: 680px) {
      padding: 0 0.5rem 0;
    }
  }

  > div.text > div.subtext {
    padding: 3rem 0 0 0;

    @media (max-width: 680px) {
      padding: 1rem 0.5rem 1rem;
      text-align: center;
    }
  }

  > div.image {
    width: 100vw;
    display: flex;
    flex: 1 0 0;
    align-items: center;
    margin: 0 6rem 0 1rem;

    @media (max-width: 680px) {
      margin: 0 0rem 0 0rem;
      justify-content: center;
    }

    > img {
      @media (max-width: 1800px) {
        width: 300px;
      }

      @media (max-width: 1600px) {
        width: 270px;
      }

      @media (max-width: 1400px) {
        width: 250px;
      }

      @media (max-width: 1300px) {
        width: 230px;
      }

      @media (max-width: 680px) {
        width: 150px;
      }
    }
  }
`;
