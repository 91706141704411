import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import DemoBanner from '../home/DemoBanner';
import HeroSlider from './HeroSlider';
import HeroBar from './HeroBar';
import Quotation from '../shared/Quotation';

const OurProjects = () => {
  return (
    <BrowserRouter>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Our Projects</title>
        <link rel='canonical' href='http://mysite.com/example' />
      </Helmet>
      <HeroBar />
      <HeroSlider />
      <DemoBanner />
      <Quotation />
    </BrowserRouter>
  );
};

export default OurProjects;
