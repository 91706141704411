import _ from 'underscore';
import React, { useState, useRef, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import NormalButton from './buttons/NormalButton';
import Dot from './Dot';
import LetsGo from '../../assets/svg/lets-go.svg';
import get_quote_banner from '../../assets/img/get-quote-banner.PNG';
import emailjs from '@emailjs/browser';
import emailKeyData from '../../emailkey';
import { ToastContainer, toast } from 'react-toastify';
import call from '../../assets/svg/call.svg';
import email from '../../assets/svg/email.svg';
import location from '../../assets/svg/location.svg';
import { useRTL } from '../../LanguageContext';
import 'react-toastify/dist/ReactToastify.css';
toast.configure();

const initialValues = (language) => ({
  name: {
    value: '',
    isValid: false,
    type: 'text',
    placeholder: language !== 'en' ? '* الاسم' : 'Your name *',
    isRequired: true,
  },
  company: {
    value: '',
    isValid: false,
    type: 'text',
    placeholder: language !== 'en' ? '* اسم الشركة' : 'Company name *',
    isRequired: true,
  },
  email: {
    value: '',
    isValid: false,
    type: 'email',
    placeholder: language !== 'en' ? '* البريد الإلكتروني' : 'Email address *',
    isRequired: true,
  },
  mobile: {
    value: '',
    isValid: false,
    type: 'text',
    placeholder: language !== 'en' ? '* رقم الهاتف' : 'Phone no. *',
    isRequired: true,
  },
  desc: {
    value: '',
    isValid: false,
    type: 'text',
    placeholder: language !== 'en' ? '* وصف الطلب' : 'Detailed enquiry *',
    isRequired: true,
  },
});

const Quotation = () => {
  const { isRTL, language } = useRTL();
  const formRef = useRef();
  const [loading, setLoading] = useState(false);
  const [submittedSuccess, setSubmittedSuccess] = useState();
  const [formValues, setFormValues] = useState(initialValues(language));

  useEffect(() => {
    setFormValues(initialValues(language));
  }, [language]);
  const handleInput = ({ target }) => {
    const { name, value } = target;
    setFormValues({ ...formValues, [name]: { ...formValues[name], value } });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    emailjs
      .sendForm(
        'service_6zblff7',
        emailKeyData.TEMPLATE_ID,
        formRef.current,
        emailKeyData.USER_ID
      )
      .then(
        (result) => {
          setLoading(false);
          setSubmittedSuccess(true);
          toast.success('Your enquiry has been submitted successfully', {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          setFormValues(initialValues(language));
        },
        (error) => {
          setLoading(false);
          setSubmittedSuccess(false);
          toast.error('Oops something went wrong, please try again later.!', {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        }
      );
  };

  return (
    <>
      <ToastContainer position='bottom-right'> </ToastContainer>
      <SectionWrapper isRTL={isRTL}>
        <UpperContainer>
          {/* <img
          className='marquee marquee2'
          src={get_quote_banner}
          alt='get quote banner'
        />
        <img
          className='marquee'
          src={get_quote_banner}
          alt='get quote banner'
        /> */}
          <div className='card'>
            <p>{isRTL ? 'اطلب تسعير' : 'Get a Quote '}</p>
          </div>
        </UpperContainer>
        <div id='get-quote' className='content-wrapper'>
          <div className='icons-container'>
            <div className='card'>
              <Dot>
                <img src={call} alt={'call'} />
              </Dot>
              <p>
                <a href='tel:+974 30009512'>+974 30009512</a>
              </p>
            </div>

            <div className='card'>
              <Dot
                onClick={() =>
                  window.open(
                    'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14446.120891016028!2d51.396231!3d25.1515685!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x9346ebbf744e66d2!2sAL%20DANA%20ALUMINUM%20PRODUCTS%2CQATAR%20(ALDANA%20BOND)!5e0!3m2!1sen!2sar!4v1618396836094!5m2!1sen!2sar'
                  )
                }
              >
                <img src={location} alt={'location'} />
              </Dot>
              <p>
                {isRTL
                  ? 'المنطقة الصناعية الجديدة ، الدوحة '
                  : 'New Industrial Area, Doha'}
              </p>
            </div>

            <div className='card'>
              <Dot
                onClick={() => (window.location = `mailto:info@aldanabond.com`)}
              >
                <img src={email} alt={'email'} />
              </Dot>
              <p>
                <a href={`mailto:info@aldanabond.com`}>info@aldanabond.com</a>
              </p>
            </div>
          </div>

          <div className='form-container'>
            <form ref={formRef} onSubmit={onSubmit}>
              {_.map(formValues, (attrs, key) => (
                <input
                  required={true}
                  key={key}
                  name={key}
                  {...attrs}
                  value={attrs.value}
                  onBlur={handleInput}
                  onChange={handleInput}
                />
              ))}
              {!submittedSuccess && (
                <NormalButton type='submit' disabled={loading}>
                  {!loading ? (isRTL ? 'ارسال' : 'Submit') : 'Loading...'}
                  <span style={{ padding: '0 5px 0' }}>
                    <img src={LetsGo} alt='lets go' width='20' height='20' />
                  </span>
                </NormalButton>
              )}
            </form>
          </div>
        </div>
      </SectionWrapper>
    </>
  );
};

export default Quotation;

const SectionWrapper = styled.section`
  /* border: 1px solid black; */
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  white-space: nowrap;

  > img {
    width: 60%;
    padding: 3rem;
  }

  > div {
    width: 50%;

    @media (max-width: 1024px) {
      width: 80%;
    }

    @media (max-width: 1024px) {
      width: 90%;
    }
  }

  > div > div {
    padding: 2rem 0 1rem;

    @media (max-width: 680px) {
      padding: 0rem 0 1rem;
    }
  }

  > div > div.icons-container {
    display: flex;
    justify-content: center;

    @media (max-width: 680px) {
      flex-flow: column;
    }

    > div.card {
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      /* border: 1px solid black; */
      padding: 0 2rem 0;

      @media (max-width: 1800px) {
        padding: 0 1.8rem 0;
      }

      @media (max-width: 1600px) {
        padding: 0 1.6rem 0;
      }

      @media (max-width: 1400px) {
        padding: 0 1.4rem 0;
      }

      @media (max-width: 1300px) {
        padding: 0 1.2rem 0;
      }

      @media (max-width: 1024px) {
        padding: 0 0.5rem 0;
      }

      @media (max-width: 680px) {
        padding: 1rem 0.5rem 1rem;
      }

      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #9e0a34;
        width: 3rem;
        height: 3rem;
        /* box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
          0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
          0 22.3px 17.9px rgba(0, 0, 0, 0.072),
          0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 100vw 80px rgba(0, 0, 0, 0.12); */

        @media (max-width: 1600px) {
          width: 2.8rem;
          height: 2.8rem;
        }

        @media (max-width: 1400px) {
          width: 2.5rem;
          height: 2.5rem;
        }

        @media (max-width: 680px) {
          width: 2rem;
          height: 2rem;
        }

        &:hover {
          background: #1d2d5b;
        }

        > img {
          width: 70%;
          color: #ffffff;
        }
      }

      > p {
        font-family: 'Cairo-Bold';
        font-size: 1.5rem;

        @media (max-width: 1800px) {
          font-size: 1.4rem;
        }

        @media (max-width: 1600px) {
          font-size: 1.2rem;
        }

        @media (max-width: 1400px) {
          font-size: 1.1rem;
        }

        @media (max-width: 1300px) {
          font-size: 1rem;
        }

        @media (max-width: 680px) {
          font-size: 1rem;
        }
      }
      > p > a {
        color: black;
        font-family: 'Cairo-Bold';
        font-size: 1.5rem;
        //margin-top: 1.2rem;
        text-decoration: none;
        //height: 4rem;

        @media (max-width: 1800px) {
          font-size: 1.4rem;
        }

        @media (max-width: 1600px) {
          font-size: 1.2rem;
        }

        @media (max-width: 1400px) {
          font-size: 1.1rem;
        }

        @media (max-width: 1300px) {
          font-size: 1rem;
        }

        @media (max-width: 680px) {
          font-size: 1rem;
        }
      }
    }
  }

  > div > div.form-container {
    padding: 1rem 0 5rem;

    > form {
      border-radius: 20px;
      padding: 0rem 1rem 1rem;
      border: 1px solid #ededed;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      /* box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
        0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
        0 22.3px 17.9px rgba(0, 0, 0, 0.072),
        0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 100vw 80px rgba(0, 0, 0, 0.12); */

      > input[type='text'],
      input[type='email'] {
        width: 95%;
        padding: 1rem 0 1rem;
        margin: 8px 0;
        display: inline-block;
        border: none;
        border-bottom: 1px solid #ededed;
        box-sizing: border-box;
        transition: all 1s;
        font-family: 'Cairo-Medium';
        font-size: 1.5rem;
        color: #010101;
        text-align: ${(prop) => (prop.isRTL ? 'right' : 'left')};

        @media (max-width: 1800px) {
          font-size: 1.4rem;
        }

        @media (max-width: 1600px) {
          font-size: 1.2rem;
          padding: 12px 0 12px;
        }

        @media (max-width: 1400px) {
          font-size: 1.1rem;
          padding: 8px 0 8px;
        }

        @media (max-width: 1300px) {
          font-size: 1rem;
        }

        @media (max-width: 680px) {
          font-size: 1rem;
        }

        &::placeholder {
          opacity: 0.4;
        }

        &:focus {
          outline: none;
          border-bottom: 1px solid #9e0a34;
          transition: all 1s;
          opacity: 1;
        }
      }
    }
  }
`;

const marqueeText = keyframes`
  0%  {left: 100%;}
  100% {left: -120%;}
`;

const marqueeTextMobile = keyframes`
  0%  {left: 100%;}
  100% {left: -350%;}
`;

const UpperContainer = styled.section`
  /* border: 1px solid black; */
  width: 100%;
  position: relative !important;
  height: 100px;
  padding: 6rem 0rem 0rem;

  @media (max-width: 1600px) {
    padding: 6rem 0rem 0rem;
  }

  @media (max-width: 1400px) {
    padding: 4rem 0rem 0rem;
  }

  @media (max-width: 680px) {
    height: 50px;
    padding: 3rem 0rem 0rem;
  }

  > div > p {
    /* border: 1px solid black; */
    font-family: 'Cairo-Bold';
    font-size: 5rem;
    line-height: 3rem;
    margin: 0 auto;
    padding: 0 10px;
    color: #1d2d5b;
    text-align: center;

    @media (max-width: 1800px) {
      font-size: 2.7rem;
    }

    @media (max-width: 1600px) {
      font-size: 2.4rem;
    }

    @media (max-width: 1400px) {
      font-size: 2.1rem;
    }

    @media (max-width: 1300px) {
      font-size: 2.1rem;
    }

    @media (max-width: 1024px) {
      font-size: 2rem;
      padding: 0;
    }

    @media (max-width: 680px) {
      font-size: 2rem;
      padding: 0;
    }

    @media (max-width: 440px) {
      font-size: 2rem;
      padding: 0;
    }
  }

  > img.marquee {
    /* border: 1px solid black; */
    width: 100vw;
    position: absolute;
    bottom: 20;
    animation: ${marqueeText} 40s linear 0s infinite;

    @media (max-width: 680px) {
      animation: ${marqueeTextMobile} 40s linear 0s infinite;
      width: 200%;
    }
  }

  > img.marquee2 {
    animation-delay: 30s;
    left: 100%;
  }
`;
