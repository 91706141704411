import React from 'react';
import styled from 'styled-components';
import { useRTL } from '../../LanguageContext';

const Content = () => {
  const { isRTL } = useRTL();

  return (
    <ContentWrapper isRTL={isRTL}>
      <div className='intro'>
        <div className='title'>{isRTL ? 'وظائف الدانة':'AL DANA BOND Careers'}</div>
        <div className='text'>
          <p>
            {isRTL? 
             'ترحب بك الدانة في عالم ألواح الألمنيوم المركبة (ACP) حيث تلتقي الجودة الهائلة بمتانة لا تضاهى. نحن نفخر بتقديم أنفسنا كشركة مصنعة رائدة لألواح ACP في دولة قطر. انضم إلى فريق AL DANA BOND وأعد تعريف حياتك المهنية':
            ' AL DANA BOND welcomes you to the world of Aluminium Composite Panels (ACP) where phenomenal quality meets incomparable durability. We take utmost pride in introducing ourselves as the leading manufacturers of ACP sheets in State of Qatar. Join AL DANA BOND team and redefine your career'
           }
         
            <a href='mailto:hr@aldanabonds-qa.com'>
              {' '}
              <span>hr@aldanabonds-qa.com</span>
            </a>
          </p>{' '}
        </div>
      </div>
    </ContentWrapper>
  );
};

export default Content;

const ContentWrapper = styled.div`
  direction: ${(prop) => (prop.isRTL ? 'rtl' : 'ltr')};
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 2rem 0 5rem;

  @media (max-width: 680px) {
    padding: 0 0 1rem;
  }

  > div {
    width: 90%;
    padding: 0 0 3rem;
    display: flex;
    flex-flow: column;
    justify-content: center;
    //align-items: center;
  }

  > div > div.title {
    font-family: 'Cairo-Bold';
    font-size: 4.5rem;
    padding: 1rem 0 1rem;

    @media (max-width: 1800px) {
      font-size: 4rem;
    }

    @media (max-width: 1600px) {
      font-size: 3.4rem;
    }

    @media (max-width: 1400px) {
      font-size: 3rem;
    }

    @media (max-width: 1300px) {
      font-size: 2.4rem;
      margin: ${(prop) => (prop.isRTL ? '0rem 1rem 0 0rem ' : '0 0 0 1rem')};
    }

    @media (max-width: 680px) {
      font-size: 1.8rem;
    }
  } 

  > div > div.text {
    > p {
      text-align: ${(prop) => (prop.isRTL ? 'right' : 'left')};
      line-height: 2;
      font-family: 'Cairo-Medium';
      font-size: 1.5rem;
      color: #1d2d5b;

      @media (max-width: 1800px) {
        font-size: 1.4rem;
      }

      @media (max-width: 1600px) {
        font-size: 1.3rem;
      }

      @media (max-width: 1400px) {
        font-size: 1.2rem;
      }

      @media (max-width: 1300px) {
        font-size: 1.1rem;
        margin: ${(prop) => (prop.isRTL ? '0rem 1rem 0 0rem ' : '0 0 0 1rem')};
      }

      @media (max-width: 680px) {
        text-align: justify;
      }
    }
  }
`;
